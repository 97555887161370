function Input(props) {
    /**
     * Defining attributes
     */
    const attributes = {
        direction: props?.direction !== undefined ? props.direction : 'vertical',
        label: props?.label !== undefined ? props.label : 'Input Name',
        type: props?.type !== undefined ? props.type : 'text',
        name: props?.name !== undefined ? props.name : null,
        placeholder: props?.placeholder !== undefined ? props.placeholder : '',
        className: props?.className !== undefined ? props.className : '',
        defaultValue: props?.defaultValue !== undefined ? props.defaultValue : '',
        readOnly: props?.readOnly !== undefined ? props.readOnly : false,
        disabled: props?.disabled !== undefined ? props.disabled : false,
        change: props?.change !== undefined ? props.change : null
    }

    /** 
     * Render view
     */
    return (
        <div>
            {attributes.direction?.toLowerCase() === 'vertical' && (
                <div className="mb-2">
                    <label className="form-label">{attributes.label}</label>
                    <input
                        type={attributes.type}
                        className={`form-control ${attributes.className} ${attributes.readOnly ? "bg-light" : ""
                            }`}
                        placeholder={attributes.placeholder}
                        defaultValue={attributes.defaultValue}
                        readOnly={attributes.readOnly}
                        disabled={attributes.disabled}
                        onChange={(e) => {
                            if (attributes.change !== null) attributes.change(attributes.name, e.target.value);
                        }}
                    />
                    <i className="text-danger" id={`${attributes.name}-error-message`}></i>
                </div>
            )}
        </div>
    );
}

export default Input;
