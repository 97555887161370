import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import LocalStorage from "../../libraries/LocalStorage";

/**
 * Load menu from object file
 */
import {
    dashboardMenu,
    masterMenu,
    transactionMenu,
    payrollMenu,
    workflowMenu,
    reportMenu,
    complaintMenu,
    nlpMenu
} from "./../data/Menu";

/**
 * Get data user logged from localstorage
 */
const localStorage = new LocalStorage();
const roleName = localStorage.getItem('role_name')?.toLowerCase();
const isPicBranch = localStorage.getItem('pic_branch') ? localStorage.getItem('pic_branch') : false;

export default class Sidebar extends Component {

    render() {
        return (
            <div className="sidebar">
                <div className="sidebar-header">
                    <Link className="sidebar-logo">HRIS</Link>
                </div>
                <PerfectScrollbar className="sidebar-body" ref={ref => this._scrollBarRef = ref}>
                    <SidebarMenu onUpdateSize={() => this._scrollBarRef.updateScroll()} />
                </PerfectScrollbar>
            </div>
        )
    }
}

class SidebarMenu extends Component {

    populateMenu = (m) => {
        const menu = m.map((m, key) => {
            const _link = m?.link?.toLowerCase();

            /**
             * Validate menu master
             * Remove when user not in [super_admin]
             */
            if (
                roleName !== 'super_admin'
                && _link === '/companies'
            ) return null;

            /**
             * Validate workflow menu by role
             * Remove approvals menu if user is employee and not in [pic_branch, company_admin]
             */
            if (
                roleName === 'employee'
                && !isPicBranch
                && _link === '/approvals'
            ) return null;

            /**
             * Generate menu DOMs
             */
            let sm;
            if (m.submenu) {
                sm = m.submenu.map((sm, key) => {
                    return (
                        <NavLink to={sm.link} className="nav-sub-link" key={key}>{sm.label}</NavLink>
                    )
                })
            }

            /**
             * Validate active-sidebar
             */
            let __sidebarActive = '';
            let __currentUrl = window.location.pathname?.toLowerCase();
            if (__currentUrl.substring(0, m.link?.length) === m.link?.toLowerCase()) __sidebarActive = 'active';

            /**
             * Validate issue for payrolls-list:generate
             */
            if (__currentUrl === '/payrolls/add' && m.link?.toLowerCase() === '/payrolls') __sidebarActive = ''; // reset

            return (
                <li key={key} className="nav-item">
                    {(!sm) ? (
                        <NavLink to={m.link} className={`nav-link`} style={{ color: __sidebarActive == 'active' ? '#506fd9' : '#41505f' }}><i className={m.icon}></i> <span>{m.label}</span></NavLink>
                    ) : (
                        <div onClick={this.toggleSubMenu} className="nav-link has-sub"><i className={m.icon}></i> <span>{m.label}</span></div>
                    )}
                    {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
                </li>
            )
        });

        return (
            <ul className="nav nav-sidebar">
                {menu}
            </ul>
        );
    }

    toggleMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-group');
        parent.classList.toggle('show');
    }

    toggleSubMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-item');
        let node = parent.parentNode.firstChild;

        while (node) {
            if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
                node.classList.remove('show');
            node = node.nextElementSibling || node.nextSibling;
        }

        parent.classList.toggle('show');
    }

    render() {
        return (
            <React.Fragment>
                <div className="nav-group show">
                    <div className="nav-label" onClick={this.toggleMenu}>Dashboard</div>
                    {this.populateMenu(dashboardMenu)}
                </div>
                {roleName !== 'employee' && <div className="nav-group show">
                    <div className="nav-label" onClick={this.toggleMenu}>Master</div>
                    {this.populateMenu(masterMenu)}
                </div>}
                <div className="nav-group show">
                    <div className="nav-label" onClick={this.toggleMenu}>Transaction</div>
                    {this.populateMenu(transactionMenu)}
                </div>
                {roleName !== 'super_admin' && <div className="nav-group show">
                    <div className="nav-label" onClick={this.toggleMenu}>Workflow</div>
                    {this.populateMenu(workflowMenu)}
                </div>}
                {roleName !== 'employee' && <div className="nav-group show">
                    <div className="nav-label" onClick={this.toggleMenu}>Payroll</div>
                    {this.populateMenu(payrollMenu)}
                </div>}
                {roleName !== 'employee' && <div className="nav-group show">
                    <div className="nav-label" onClick={this.toggleMenu}>Report</div>
                    {this.populateMenu(reportMenu)}
                </div>}
                {roleName !== 'employee' && <div className="nav-group show">
                    <div className="nav-label" onClick={this.toggleMenu}>Complaints</div>
                    {this.populateMenu(complaintMenu)}
                </div>}
                {roleName !== 'employee' && false === true && <div className="nav-group show">
                    <div className="nav-label" onClick={this.toggleMenu}>Report AI</div>
                    {this.populateMenu(nlpMenu)}
                </div>}
            </React.Fragment>
        )
    }
}
