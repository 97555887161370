import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";

function Layout() {
    const d = new Date();
    const year = d.getFullYear();

    const location = useLocation();
    const wokrflowPaths = ['/submissions', '/approvals'];
    let isWorkflowPage = wokrflowPaths.includes(location.pathname?.toLowerCase()) ? true : false;

    /**
     * init styles for workflow
     */
    let workflowStyles = {}
    if (isWorkflowPage) workflowStyles = {
        marginLeft: 0
    }

    return (
        <div>
            <Sidebar />
            <Header />

            <div
                className={isWorkflowPage ? "main main-email" : "main main-app p-3 p-lg-4"}
                style={{
                    ...workflowStyles,
                    backgroundColor: '#fff'
                }}
            >
                <main>
                    <Outlet />
                </main>

                {!isWorkflowPage && <div className="main-footer mt-5">
                    <span>&copy; {year}. HRIS Application</span>
                </div>}
            </div>
        </div>
    )
}

export default Layout;