import { Card, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Rest from "../../../../libraries/Rest";
import Alert from "../../../../libraries/Alert";
import * as Components from "../../../../components/Components";

function CompanySettingForm() {
    const { companyId } = useParams();
    const navigate = useNavigate();
    const rest = new Rest();

    /**
     * Init states
     */
    const endpoint = `/companies/settings/${companyId}`;
    const rootPath = `/companies/settings/${companyId}`;

    let [payload, setPayload] = useState({
        TOTAL_WORKDAY_IN_WEEK: 5, // default
        LEAVE_ATTEMPT: null
    });
    let [dataSettings, setDataSettings] = useState([]);
    let [pphTypes, setPphTypes] = useState([]);

    /**
     * First init function
     */
    const getPphTypes = async () => {
        const response = await rest.get(`/statics/pph_type`);
        if (response?.data?.data !== undefined) {
            const data = response.data.data;

            let options = [];
            for (let i = 0; i < data.length; i++) {
                options.push({
                    value: data[i].value,
                    label: data[i].label_en
                });
            }
            setPphTypes(options);
        }
    }

    const getSettingByCompany = async () => {
        const response = await rest.get(`${endpoint}`);
        if (response?.data?.data !== undefined) {
            const data = response.data.data;
            setDataSettings(data);

            /**
             * Append default value for defined payload
             */
            let payloadUpdate = {};

            for (let i = 0; i < data.length; i++) {
                let value = data[i].value_string;

                /**
                 * Validate when json value is not empty
                 */
                if (data[i].value_json) {
                    const json = JSON.parse(data[i].value_json);
                    if (data[i].key?.toUpperCase() === 'COMPANY_COORDINATE') {
                        value = {
                            long: json?.long !== undefined ? json.long : null,
                            lat: json?.lat !== undefined ? json.lat : null,
                        }
                    }
                }
                if (value !== null) payloadUpdate[data[i].key] = value;
            }
            setPayload({ ...payload, ...payloadUpdate });
        } else {
            Alert.showMessage('Error', 'error', 'Data is not found');
            navigate('/companies');
        }
    }

    useEffect(() => {
        async function __init() {
            Alert.showLoading();
            await getSettingByCompany();
            await getPphTypes();
            Alert.close();
        }
        __init();
    }, []);


    /**
     * Action handlers
     */
    const updatePayload = (field, value) => {
        if (['lat', 'long'].includes(field)) {
            value = {
                ...payload.COMPANY_COORDINATE,
                [field]: value
            }
            field = 'COMPANY_COORDINATE';
        }

        setPayload({ ...payload, [field]: value });
    }

    const submit = async () => {
        /**
         * Translate input to required format
         */
        let dataToUpload = [];
        for (let i = 0; i < Object.keys(payload).length; i++) {
            const key = Object.keys(payload)[i];
            const value = payload[key];

            const x = dataSettings.find(x => x?.key?.toUpperCase() === key?.toUpperCase());
            if (x !== undefined) {
                dataToUpload.push({
                    id: x.id,
                    value_string: typeof value === 'object' ? null : value?.toString(),
                    value_json: typeof value === 'object' ? JSON.stringify(value) : null
                });
            }
        }

        /**
         * Validate required input
         */
        if (dataToUpload?.TOTAL_WORKDAY_IN_WEEK === null || dataToUpload?.TOTAL_WORKDAY_IN_WEEK === '') return Alert.showMessage('Warning', 'warning', 'TOTAL WORKDAY IN WEEK cannot be empty');
        if (dataToUpload?.LEAVE_ATTEMPT === null || dataToUpload?.LEAVE_ATTEMPT === '') return Alert.showMessage('Warning', 'warning', 'LEAVE ATTEMPT cannot be empty');

        /**
         * Send to service
         */
        Alert.showConfirm({
            url: endpoint,
            method: 'PUT',
            data: dataToUpload,
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    /**
     * Rendering DOM
     */
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <ol className="breadcrumb fs-sm mb-1">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/companies">List companies</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Form setting
                        </li>
                    </ol>
                </div>
            </div>

            <Row className="g-3">
                <Col xl="12">
                    <Card className="card">
                        <Card.Header className="bg-primary">
                            <Card.Title
                                as="label"
                                className="fs-sm fw-medium mb-1 text-white"
                            >
                                Form setting
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="bg-white">
                            <Link to={'/companies'} className="btn btn-sm btn-warning text-white">
                                <i className="ri-arrow-left-line"></i> Back to company list
                            </Link>
                            &nbsp;
                            <button className="btn btn-sm btn-primary" onClick={submit}>
                                <i className="ri-check-line"></i> Save
                            </button>
                            <br /><br />
                            <Row className="g-3">
                                <Col sm="4">
                                    <Components.Select
                                        name="TOTAL_WORKDAY_IN_WEEK"
                                        label="Total Workday in Week"
                                        defaultValue={payload.TOTAL_WORKDAY_IN_WEEK}
                                        change={updatePayload}
                                        options={[
                                            {
                                                value: '5',
                                                label: '5 Days'
                                            }, {
                                                value: '6',
                                                label: '6 Days'
                                            }, {
                                                value: '7',
                                                label: '7 Days'
                                            }
                                        ]}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Input
                                        type="number"
                                        name="LEAVE_ATTEMPT"
                                        label="Leave Attempt"
                                        placeholder="Total leave attempt on your company"
                                        defaultValue={payload.LEAVE_ATTEMPT}
                                        change={updatePayload}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default CompanySettingForm;