import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import numeral from "numeral";

import Rest from "./../../libraries/Rest";
import Alert from "./../../libraries/Alert";

function EmployeeDashboard() {
    const rest = new Rest();

    /**
     * Init state
     */
    const [employee, setEmployee] = useState({});
    const [attendance, setAttendance] = useState({
        company_name: null,
        branch_name: null,
        date_in: null,
        time_in: null,
        time_diff: null,
        status: null,
    });
    const [summary, setSummary] = useState({
        total_attendances: 0,
        total_leaves: 0,
        total_overtimes: 0
    });

    /**
     * First init
     */
    const _getDataDashboard = async () => {
        const response = await rest.get(`/dashboards/employee?date=2024-07-24`);
        if (response?.data?.data !== undefined) {
            const data = response.data.data;

            /**
             * Populating employee detail
             */
            if (data?.employee !== undefined) setEmployee(data.employee);

            /**
             * Populating today's attendance
             */
            if (data?.today_attendance !== undefined) setAttendance(data.today_attendance);

            /**
             * Populating summary data
             */
            if (data?.summary_data !== undefined) {
                setSummary({
                    total_attendances: data.summary_data?.total_attendances,
                    total_leaves: data.summary_data?.total_leaves,
                    total_overtimes: data.summary_data?.total_overtimes
                });
            }
        }
    }

    useEffect(() => {
        async function __init() {
            Alert.showLoading();
            await _getDataDashboard();
            Alert.close();
        }
        __init();
    }, []);

    /**
     * Render DOM
     */
    return (
        <Row className="g-3">
            <Col xl="12">
                <div className="media-profile mb-5">
                    <div className="media-img mb-3 mb-sm-0">
                        <img src="https://img.freepik.com/free-psd/3d-illustration-bald-person-with-glasses_23-2149436184.jpg?t=st=1721695578~exp=1721699178~hmac=d005c68e327e6792426b65a4115d33a9bcafe51b449e8b01f505284269229969&w=1060" className="img-fluid" alt="default image icon" />
                    </div>
                    <div className="media-body">
                        <h5 className="media-name">
                            {employee?.nik} - {employee?.first_name} {employee?.middle_name ?? ''} {employee?.last_name ?? ''}
                        </h5>
                        <p className="d-flex gap-2 mb-4">
                            {!attendance?.date_in && 'You didnt presence yet!'}
                            {attendance?.date_in && <span><i className="ri-map-pin-line"></i> Presence in {attendance?.branch_name} ({attendance?.company_name}) <br /><i className="ri-time-line"></i> <b>{attendance?.date_in} {attendance?.time_in}</b> - {attendance?.time_diff}Min(s) {attendance?.status}</span>}
                        </p>
                        <Row className='row-cols-sm-auto g-4 g-md-5 g-xl-4 g-xxl-5'>
                            <Col>
                                <div className="profile-item">
                                    <i className="ri-account-pin-box-line"></i>
                                    <div className="profile-item-body">
                                        <p>{numeral(summary.total_attendances).format('0,0')}</p>
                                        <span>Total attendances</span>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="profile-item">
                                    <i className="ri-spy-line"></i>
                                    <div className="profile-item-body">
                                        <p>{numeral(summary.total_leaves).format('0,0')}</p>
                                        <span>Total leaves</span>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="profile-item">
                                    <i className="ri-user-follow-line"></i>
                                    <div className="profile-item-body">
                                        <p>{numeral(summary.total_overtimes).format('0,0')}</p>
                                        <span>Total overtimes</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    );
}
export default EmployeeDashboard;