function Select(props) {
    /**
     * Defining attributes
     */
    const attributes = {
        direction: props?.direction !== undefined ? props.direction : 'vertical',
        label: props?.label !== undefined ? props.label : 'Select',
        name: props?.name !== undefined ? props.name : null,
        className: props?.className !== undefined ? props.className : '',
        options: props?.options !== undefined ? props.options : [],
        defaultValue: props?.defaultValue !== undefined ? props.defaultValue : '',
        change: props?.change !== undefined ? props.change : null,
        disabled: props?.disabled !== undefined ? props.disabled : false,
        noInitOption: props?.noInitOption !== undefined ? props.noInitOption : false
    }

    /**
     * For options, add first child as an empty options to prevent human error
     */
    const defaultLabel = '-- CHOOSE ONE --';
    const x = attributes.options.find(x => x?.label?.toLowerCase() === defaultLabel?.toLowerCase());
    if (x === undefined && !attributes.noInitOption) attributes.options.unshift({ value: '', label: defaultLabel });

    /**
     * Render view
     */
    return (
        <div>
            {(attributes.direction?.toLowerCase() === 'vertical') && (
                <div className="mb-2">
                    <label className="form-label">{attributes.label}</label>
                    <select
                        className="form-select"
                        value={attributes.defaultValue ? attributes.defaultValue : ''}
                        disabled={attributes.disabled}
                        onChange={(e) => {
                            if (attributes.change !== null) attributes.change(attributes.name, e.target.value);
                        }}
                    >
                        {attributes.options.map((v, i) => {
                            let label = v?.label;
                            if (label !== undefined && label !== '' && label !== null) {
                                label = label.replace(/_/g, ' ');
                            }
                            return (
                                <option
                                    key={i}
                                    value={v?.value}
                                >
                                    {label}
                                </option>
                            )
                        })}
                    </select>
                    <i className="text-danger" id={`${attributes.name}-error-message`}></i>
                </div>
            )}
        </div>
    )

}

export default Select;