import Rest from "./../../libraries/Rest";
import { useState } from "react";
import Alert from "./../../libraries/Alert";
import LocalStorage from "../../libraries/LocalStorage";

function Login() {
    const rest = new Rest();
    const storage = new LocalStorage();

    const [payload, setPayload] = useState({
        key_input: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);

    /**
    * Action handlers
    */
    const doLogin = async () => {
        setLoading(true);
        const data = {
            key_input: payload.key_input,
            password: payload.password,
        }

        /**
         * Check data to backend
         */
        const response = await rest.post('/auth/login?source=web', data);
        setLoading(false);
        if (!response.success) {
            Alert.showMessage('Login failure', 'error', response.message);
        } else {
            const data = response?.data?.data;
            const dataForSession = {
                access_token: data.access_token,
                company_name: data.company_name,
                email: data.email,
                role_name: data.role_name,
                short_name: data.short_name,
                pic_branch: data.pic_branch,
            }

            /**
             * Regist to local storage
             */
            storage.setItem(dataForSession);

            /**
             * Check redirect, if user first_init is true, then force user to change their password
             */
            if (
                data.first_init === true
                && !['super_admin', 'company_admin'].includes(dataForSession.role_name?.toLowerCase())
            ) {
                window.location.href = '/me/change_password';
            } else {
                window.location.href = '/'; // all dashboard have same path
            }
        }
    }

    /**
     * Render view
     */
    return (
        <div className="page-sign">
            <div className="card card-sign">
                <div className="card-header">
                    <a href="/" className="header-logo mb-4">HRIS Application</a>
                    <h3 className="card-title">Sign In</h3>
                    <p className="card-text">Welcome back! Please signin to continue.</p>
                </div>
                <div className="card-body">
                    <div className="mb-4">
                        <label className="form-label">
                            Email/Phone/ID Employee
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setPayload({ ...payload, key_input: e.target.value })}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="form-label">
                            Password
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            onChange={(e) => setPayload({ ...payload, password: e.target.value })}
                        />
                    </div>
                    <button
                        className="btn btn-primary btn-sign"
                        onClick={doLogin}
                        disabled={loading}
                    >
                        {loading ? 'Loading..' : 'Sign in'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Login;