import { Card, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Rest from "../../../../libraries/Rest";
import Alert from "../../../../libraries/Alert";
import * as Components from "../../../../components/Components";
import Validator from "../../../../libraries/Validator";

function CompanySalaryComponentForm() {
    const { companyId, id } = useParams();
    const navigate = useNavigate();
    const rest = new Rest();

    const isCreate = id?.toLowerCase() === 'add' ? true : false;

    /**
     * Init validator of payload
     */
    const validator = new Validator({
        name: { required: true }
    });

    /**
     * Init states
     */
    const rootPath = `/companies/salary_components/${companyId}`;
    const endpoint = `/companies/salary_components/${companyId}`;

    let [payload, setPayload] = useState({
        unit: null,
        type: 'T4', // static
        name: null,
        description: null,
        use_tax: null,
    });
    let [isDefault, setIsDefault] = useState(false);
    let [units, setUnits] = useState([]);

    /**
     * First init function
     */
    const getUnits = async () => {
        const response = await rest.get(`/statics/unit_salary_component`);
        if (response?.data?.data !== undefined) {
            const data = response.data.data;

            let options = [];
            for (let i = 0; i < data.length; i++) {
                options.push({
                    value: data[i].value,
                    label: data[i].label_en
                });
            }
            setUnits(options);
        }
    }

    const getDataById = async () => {
        const response = await rest.get(`${endpoint}/${id}`);
        if (response?.data?.data !== undefined) {
            const data = response.data.data;
            setPayload({
                ...payload,
                unit: data?.unit,
                name: data?.name,
                description: data?.description,
                use_tax: data?.use_tax ? 'true' : 'false',
            });
            setIsDefault(data?.is_default);
        } else {
            Alert.showMessage('Error', 'error', 'Data is not found');
            navigate(rootPath);
        }
    }

    useEffect(() => {
        async function __init() {
            Alert.showLoading();
            await getUnits();
            if (!isCreate) await getDataById();
            Alert.close();
        }
        __init();
    }, []);


    /**
     * Action handlers
     */
    const updatePayload = (field, value) => {
        setPayload({ ...payload, [field]: value });
    }

    const submit = async () => {
        /**
         * Validate input
         */
        const errMessages = validator.validate(payload);
        if (errMessages.length > 0) {
            let errMessageStrings = ``;
            for (let i = 0; i < errMessages.length; i++) {
                errMessageStrings += `${errMessages[i]}<br />`;
            }
            return Alert.showMessage(`Invalid input`, `warning`, errMessageStrings);
        }

        /**
         * Submit action
         */
        payload.use_tax = payload.use_tax === 'true' ? true : false;

        Alert.showConfirm({
            url: endpoint + (!isCreate ? `/${id}` : ``),
            method: isCreate ? 'POST' : 'PUT',
            data: payload,
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    /**
     * Rendering DOM
     */
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <ol className="breadcrumb fs-sm mb-1">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/companies">List companies</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={rootPath}>List salary components</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Form {isCreate ? 'create' : 'edit'}
                        </li>
                    </ol>
                </div>
            </div>

            <Row className="g-3">
                <Col xl="12">
                    <Card className="card">
                        <Card.Header className="bg-primary">
                            <Card.Title
                                as="label"
                                className="fs-sm fw-medium mb-1 text-white"
                            >
                                Form {isCreate ? 'create' : 'edit'} salary component
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="bg-white">
                            <Link to={rootPath} className="btn btn-sm btn-warning text-white">
                                <i className="ri-arrow-left-line"></i> Back
                            </Link>
                            &nbsp;
                            <button className="btn btn-sm btn-primary" onClick={submit}>
                                <i className="ri-check-line"></i> Save
                            </button>
                            <br /><br />
                            <Row className="g-3">
                                {!isDefault && <Col sm="4">
                                    <Components.Select
                                        name="unit"
                                        label="Unit"
                                        defaultValue={payload.unit}
                                        change={updatePayload}
                                        options={units}
                                    />
                                </Col>}
                                <Col sm={isDefault ? "6" : "4"}>
                                    <Components.Input
                                        type="text"
                                        name="name"
                                        label="Name"
                                        placeholder="Name of component"
                                        defaultValue={payload.name}
                                        disabled={isDefault}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm={isDefault ? "6" : "4"}>
                                    <Components.Select
                                        name="use_tax"
                                        label="Reduce Taxes"
                                        defaultValue={payload.use_tax}
                                        change={updatePayload}
                                        options={[
                                            {
                                                value: 'false',
                                                label: 'No'
                                            }, {
                                                value: 'true',
                                                label: 'Yes'
                                            }
                                        ]}
                                    />
                                </Col>
                                <Col sm="12">
                                    <Components.Input
                                        type="text"
                                        name="description"
                                        label="Description"
                                        placeholder="Description"
                                        defaultValue={payload.description}
                                        change={updatePayload}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default CompanySalaryComponentForm;