import { useEffect, useState } from "react";
import Rest from "../../libraries/Rest";
import Alert from "../../libraries/Alert";


function InboxList() {
    const rest = new Rest();

    const [notifs, setNotifs] = useState([]);
    const [filters, setFilters] = useState({
        unread: false,
        limit: 10,
        noMoreData: false
    });

    /**
     * Service handlers
     */
    const getNotifs = async () => {
        Alert.showLoading();
        const response = await rest.get(`/inboxes?page=1&limit=${filters.limit}${filters.unread ? '&isRead=false' : ''}`);
        Alert.close();

        if (response?.data?.data?.data !== undefined) {
            let _notifs = [];
            for (let i = 0; i < response.data.data.data.length; i++) {
                const v = response.data.data.data[i];
                const type = v?.title?.toLowerCase().includes('cuti') ? 'Leaves' : 'Overtime';

                let user = `-`;
                if (v?.users_inboxes_handled_byTousers) {
                    user = v?.users_inboxes_handled_byTousers?.employees[0]?.first_name;
                    user += ' ' + v?.users_inboxes_handled_byTousers?.employees[0]?.middle_name ?? '';
                    user += ' ' + v?.users_inboxes_handled_byTousers?.employees[0]?.last_name ?? '';
                    if (v?.users_inboxes_handled_byTousers?.employees[0]?.nik !== undefined) {
                        user += ` (${v?.users_inboxes_handled_byTousers?.employees[0]?.nik})`;
                    }
                }

                _notifs.push({
                    id: v?.id,
                    type,
                    title: v?.title,
                    message: v?.message,
                    created_at: v?.created_at,
                    user
                });
            }
            setNotifs(_notifs);

            /**
             * Check for no-more-data
             */
            if (_notifs.length < filters.limit) setFilters({ ...filters, noMoreData: true });
        }
    }

    const loadMore = async () => {
        setFilters({ ...filters, limit: filters.limit + 10 }); // multiply limit by 10
        await getNotifs();
    }

    useEffect(() => {
        async function __init() {
            await getNotifs();
        }
        __init();
    }, []);

    /**
     * Rendering DOM
     */
    return (
        <div>
            <div className="row">
                <div className="col-xl" style={{ textAlign: 'center' }}>
                    <h2 className="main-title fs-4 mb-1">Notifications!</h2>
                    <p className="text-secondary mb-4">
                        List for all notifications you get from the beginning
                    </p>
                </div>
            </div>
            <div className="row g-3 g-lg-4" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                minHeight: 'auto',
                padding: '0 auto'
            }}>
                <div className="col-sm-6 mt-5 mt-md-3 mt-lg-4">
                    {notifs.map((v, i) => {
                        return (
                            <div className="card card-task" key={i}>
                                <div className="card-body p-3 pb-1">
                                    <span className={"badge bg-" + (v.type?.toLowerCase() === 'leave' ? 'info' : 'success')}>{v.type}</span>
                                    <div className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
                                        <span className="card-date">{v.created_at}</span>
                                        <h6 className="card-title">{v.title}</h6>
                                    </div>
                                    <p>{v.message}</p>
                                    <hr />
                                    <p className="fs-xs">
                                        <i className="ri-user-line"></i> Handled by: <strong>{v.user}</strong>
                                    </p>
                                </div>
                            </div>
                        )
                    })}

                    {/* Button load more */}
                    <br />
                    {!filters.noMoreData && <button
                        type="button"
                        className="btn btn-primary btn-block"
                        style={{ width: '100%' }}
                        onClick={loadMore}
                    >
                        Load More
                    </button>}
                </div>

            </div>
        </div >
    )

}

export default InboxList;