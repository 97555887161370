import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Chart from 'react-apexcharts';
import numeral from "numeral";

import Rest from "./../../libraries/Rest";
import Alert from "./../../libraries/Alert"

function CompanyDashboard() {
    const rest = new Rest()

    /**
     * Init states
     */
    const [boxes, setBoxes] = useState({
        total_branches: 0,
        total_employees: 0,
        total_attendances_this_month: 0,
        total_leaves_this_month: 0,
        total_attendances_today: 0,
        total_leaves_today: 0,
    });

    const [graph, setGraph] = useState({
        options: {
            chart: {
                id: "basic-bar",
            },
            xaxis: {
                categories: [],
            },
        },
        series: [
            {
                name: "series-1",
                data: [],
            },
        ],
    });

    const [leaves, setLeaves] = useState([]);

    /**
     * First init
     */
    const _generateBoxesDashboard = async () => {
        const _dashboardProperties = [
            {
                endpoint: '/dashboards/admin/total_branches',
                field: 'total_branches'
            }, {
                endpoint: '/dashboards/admin/total_employees',
                field: 'total_employees'
            }, {
                endpoint: '/dashboards/admin/total_attendances?type=monthly',
                field: 'total_attendances_this_month'
            }, {
                endpoint: '/dashboards/admin/total_leaves?type=monthly',
                field: 'total_leaves_this_month'
            }, {
                endpoint: '/dashboards/admin/total_attendances?type=daily',
                field: 'total_attendances_today'
            }, {
                endpoint: '/dashboards/admin/total_leaves?type=daily',
                field: 'total_leaves_today'
            }
        ];

        let _boxes = {};
        for (let i = 0; i < _dashboardProperties.length; i++) {
            const response = await rest.get(_dashboardProperties[i].endpoint);

            let total = 0;
            if (response?.data?.data?.total !== undefined) total = response.data.data.total;
            _boxes[_dashboardProperties[i].field] = total;
        }
        setBoxes(_boxes);
    }

    const _generateGraphChart = async () => {
        const response = await rest.get(`/dashboards/admin/graph_attendances`);
        if (response?.data?.data !== undefined) {
            let _categories = [];
            let _values = [];
            for (let i = 0; i < response.data.data.length; i++) {
                _categories.push(response.data.data[i]?.category);
                _values.push(response.data.data[i]?.value);
            }

            /**
             * Update state:graph
             */
            setGraph((prevItem) => {
                const updatedItem = {
                    ...prevItem,
                    options: {
                        ...prevItem.options,
                        xaxis: {
                            ...prevItem.options.xaxis,
                            categories: _categories
                        }
                    },
                    series: [
                        {
                            ...prevItem.series[0],
                            data: _values
                        }
                    ]
                }
                return updatedItem;
            });
        }
    }

    const _getLimitLeaves = async () => {
        const response = await rest.get(`/leaves?page=1&limit=7&orderBy=created_at:desc`);
        if (response?.data?.data?.data) {
            let _leaves = [];
            for (let i = 0; i < response.data.data.data.length; i++) {
                const v = response.data.data.data[i];

                let employee = '';
                if (v?.employees !== undefined) {
                    employee = v.employees?.first_name;
                    if (v.employees?.middle_name) employee += ` ${v.employees?.middle_name}`;
                    if (v.employees?.last_name) employee += ` ${v.employees?.last_name}`;
                }

                let company = '';
                if (v?.companies) {
                    company = v.companies?.name;
                    if (v?.company_branches?.name !== undefined) {
                        company += ` - ${v.company_branches.name}`
                    }
                }

                _leaves.push({
                    id: v?.id,
                    employee,
                    company,
                    no_leave: v?.no_leave,
                    status: v?.status_text,
                    total_days: v?.total_days,
                    start_date: v?.start_date
                });
            }
            setLeaves(_leaves);
        }
    }

    useEffect(() => {
        async function __init() {
            Alert.showLoading();
            await _generateBoxesDashboard();
            await _generateGraphChart();
            await _getLimitLeaves();
            Alert.close();
        }
        __init();
    }, []);

    /**
     * Render DOM
     */
    return (
        <Row className="g-3">
            <Col sm="12" md="3">
                <Card className="card-one">
                    <Card.Body className="p-3">
                        <div className="d-block fs-40 lh-1 text-primary mb-1">
                            <i className="ri-road-map-line"></i>
                        </div>
                        <h1 className="card-value mb-0 ls--1 fs-32">{numeral(boxes.total_branches).format('0,0')}</h1>
                        <label className="d-block mb-1 fw-medium text-dark">
                            Total Branches
                        </label>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm="12" md="3">
                <Card className="card-one">
                    <Card.Body className="p-3">
                        <div className="d-block fs-40 lh-1 text-primary mb-1">
                            <i className="ri-user-2-line"></i>
                        </div>
                        <h1 className="card-value mb-0 ls--1 fs-32">{numeral(boxes.total_employees).format('0,0')}</h1>
                        <label className="d-block mb-1 fw-medium text-dark">
                            Total Employees
                        </label>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm="12" md="3">
                <Card className="card-one">
                    <Card.Body className="p-3">
                        <div className="d-block fs-40 lh-1 text-primary mb-1">
                            <i className="ri-account-pin-circle-line"></i>
                        </div>
                        <h1 className="card-value mb-0 ls--1 fs-32">{numeral(boxes.total_attendances_this_month).format('0,0')}</h1>
                        <label className="d-block mb-1 fw-medium text-dark">
                            This month's attendances
                        </label>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm="12" md="3">
                <Card className="card-one">
                    <Card.Body className="p-3">
                        <div className="d-block fs-40 lh-1 text-primary mb-1">
                            <i className="ri-user-shared-line"></i>
                        </div>
                        <h1 className="card-value mb-0 ls--1 fs-32">{numeral(boxes.total_leaves_this_month).format('0,0')}</h1>
                        <label className="d-block mb-1 fw-medium text-dark">
                            This month's leaves
                        </label>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm="12" md="3">
                <Card className="card-one">
                    <Card.Body className="p-3">
                        <div className="d-block fs-40 lh-1 text-primary mb-1">
                            <i className="ri-user-follow-line"></i>
                        </div>
                        <h1 className="card-value mb-0 ls--1 fs-32">{numeral(boxes.total_attendances_today).format('0,0')}</h1>
                        <label className="d-block mb-1 fw-medium text-dark">
                            Today's attendances
                        </label>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm="12" md="3">
                <Card className="card-one">
                    <Card.Body className="p-3">
                        <div className="d-block fs-40 lh-1 text-primary mb-1">
                            <i className="ri-user-unfollow-line"></i>
                        </div>
                        <h1 className="card-value mb-0 ls--1 fs-32">{numeral(boxes.total_employees - boxes.total_attendances_today).format('0,0')}</h1>
                        <label className="d-block mb-1 fw-medium text-dark">
                            Not present today
                        </label>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm="12" md="3">
                <Card className="card-one">
                    <Card.Body className="p-3">
                        <div className="d-block fs-40 lh-1 text-primary mb-1">
                            <i className="ri-spy-line"></i>
                        </div>
                        <h1 className="card-value mb-0 ls--1 fs-32">{numeral(boxes.total_leaves_today).format('0,0')}</h1>
                        <label className="d-block mb-1 fw-medium text-dark">
                            Leave for today
                        </label>
                    </Card.Body>
                </Card>
            </Col>

            <Col sm="12" md="7" xl="8">
                <Card className="card-one">
                    <Card.Header>
                        <Card.Title>Attendance in the last week</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Chart
                            className='apex-chart-nine'
                            options={graph.options}
                            series={graph.series}
                            type="bar"
                        />
                    </Card.Body>
                </Card>
            </Col>

            <Col sm='12' md='5' xl='4'>
                <Card className="card-one">
                    <Card.Header>
                        <Card.Title>Request of leaves</Card.Title>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <ul className="people-group">

                            {leaves.map((v, i) => {
                                return (
                                    <li className="people-item" key={i}>
                                        <div className="avatar">
                                            <span className="avatar-initial bg-teal fs-20">
                                                <i className="ri-user-2-fill"></i>
                                            </span>
                                        </div>
                                        <div className="people-body">
                                            <h6><a href="#">{v?.employee}</a></h6>
                                            <span>{v?.company}</span>
                                            <small>{v?.no_leave}</small>
                                        </div>
                                        <div className="text-end">
                                            <div className="fs-sm">
                                                {v.status}
                                            </div>
                                            <span className="d-block fs-xs text-success">
                                                {v?.start_date} - {v.total_days} day(s)
                                            </span>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </Card.Body>
                    <div className="card-footer d-flex justify-content-center">
                        <Link to="/leaves" className="fs-sm">View all data</Link>
                    </div>
                </Card>
            </Col>
        </Row>
    );
}

export default CompanyDashboard;
