import React, { useState } from "react"
import { useNavigate, Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import LocalStorage from "../../libraries/LocalStorage";

import usericon from "../../assets/img/usericon.png";
import Rest from "../../libraries/Rest";

export default function Header() {
  const navigate = useNavigate();
  const storage = new LocalStorage();

  /**
   * Get notifications from server
   */
  const [notifs, setNotifs] = useState([]);
  const rest = new Rest();

  useState(() => {
    async function __init() {
      const response = await rest.get(`/inboxes?page=1&limit=5&isRead=false`);
      if (response?.data?.data?.data !== undefined) {
        let _notifs = [];
        for (let i = 0; i < response.data.data.data.length; i++) {
          const v = response.data.data.data[i];
          _notifs.push({
            "id": v.id,
            "avatar": (<img src={usericon} alt="" />),
            "text": (<React.Fragment><strong>{v?.title}</strong><br />{v?.message}</React.Fragment>),
            "date": v?.created_at,
          });
        }

        /**
         * Send to state
         */
        setNotifs(_notifs);
      }
    }
    __init();
  }, []);

  /**
   * Get data from session
   */
  const sessionData = storage.getItem();

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  }

  function NotificationList() {
    /**
     * Render view
     */
    const notiList = notifs.map((item, key) => {
      return (
        <li className="list-group-item" key={key}>
          <div className="avatar">{item.avatar}</div>
          <div className="list-group-body">
            <p>{item.text}</p>
            <span>{item.date}</span>
          </div>
        </li>
      )
    });

    return (
      <ul className="list-group">
        {notiList}
      </ul>
    );
  }

  /**
   * Action handlers
   */
  function doLogout() {
    storage.removeItem();
    /**
     * Redirect to login
     */
    navigate('/login');
  }

  /**
   * Render view
   */
  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4"><i className="ri-menu-2-fill"></i></Link>
      <div className="me-auto"></div>

      <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <small>{notifs.length}</small><i className="ri-notification-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f me--10-f">
          <div className="dropdown-menu-header">
            <h6 className="dropdown-menu-title">Notifications</h6>
          </div>
          {NotificationList()}
          <div className="dropdown-menu-footer"><Link to="/inbox">Show all Notifications</Link></div>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <img src={usericon} alt="" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3"><img src={usericon} alt="" /></div>
            <h5 className="mb-1 text-dark fw-semibold">{sessionData?.short_name}</h5>
            <p className="fs-sm text-secondary">{sessionData?.email}</p>

            <nav className="nav">
              <Link to="/me"><i className="ri-profile-line"></i> View Profile</Link>
              <Link to="/me/change_password"><i className="ri-edit-2-line"></i> Change Password</Link>
            </nav>
            <hr />
            <nav className="nav">
              <Link onClick={doLogout}><i className="ri-logout-box-r-line"></i> Log Out</Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}