import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Chart from 'react-apexcharts';
import numeral from "numeral";

import Rest from "./../../libraries/Rest";
import Alert from "./../../libraries/Alert"

function AdminDashboard() {
    const rest = new Rest()

    /**
     * Init states
     */
    const [boxes, setBoxes] = useState({
        total_companies: 0,
        total_branches: 0,
        total_employees: 0,
        total_attendances: 0,
    });

    const [graph, setGraph] = useState({
        options: {
            chart: {
                id: "basic-bar",
            },
            xaxis: {
                categories: [],
            },
        },
        series: [
            {
                name: "series-1",
                data: [],
            },
        ],
    });

    const [attendances, setAttendances] = useState([]);

    /**
     * First init
     */
    const _generateBoxesDashboard = async () => {
        const _dashboardProperties = [
            {
                endpoint: '/dashboards/admin/total_companies',
                field: 'total_companies'
            }, {
                endpoint: '/dashboards/admin/total_branches',
                field: 'total_branches'
            }, {
                endpoint: '/dashboards/admin/total_employees',
                field: 'total_employees'
            }, {
                endpoint: '/dashboards/admin/total_attendances',
                field: 'total_attendances'
            }
        ];

        let _boxes = {};
        for (let i = 0; i < _dashboardProperties.length; i++) {
            const response = await rest.get(_dashboardProperties[i].endpoint);

            let total = 0;
            if (response?.data?.data?.total !== undefined) total = response.data.data.total;
            _boxes[_dashboardProperties[i].field] = total;
        }
        setBoxes(_boxes);
    }

    const _generateGraphChart = async () => {
        const response = await rest.get(`/dashboards/admin/graph_attendances`);
        if (response?.data?.data !== undefined) {
            let _categories = [];
            let _values = [];
            for (let i = 0; i < response.data.data.length; i++) {
                _categories.push(response.data.data[i]?.category);
                _values.push(response.data.data[i]?.value);
            }

            /**
             * Update state:graph
             */
            setGraph((prevItem) => {
                const updatedItem = {
                    ...prevItem,
                    options: {
                        ...prevItem.options,
                        xaxis: {
                            ...prevItem.options.xaxis,
                            categories: _categories
                        }
                    },
                    series: [
                        {
                            ...prevItem.series[0],
                            data: _values
                        }
                    ]
                }
                return updatedItem;
            });
        }
    }

    const _getAttendanceByBranch = async () => {
        const response = await rest.get(`/dashboards/admin/total_attendances_by_branch?value=today`);
        if (response?.data?.data !== undefined) {
            setAttendances(response.data.data);
        }
    }

    useEffect(() => {
        async function __init() {
            Alert.showLoading();
            await _generateBoxesDashboard();
            await _generateGraphChart();
            await _getAttendanceByBranch();
            Alert.close();
        }
        __init();
    }, []);

    /**
     * Render DOM
     */
    return (
        <Row className="g-3">
            <Col sm="12" md="3">
                <Card className="card-one">
                    <Card.Body className="p-3">
                        <div className="d-block fs-40 lh-1 text-primary mb-1">
                            <i className="ri-community-line"></i>
                        </div>
                        <h1 className="card-value mb-0 ls--1 fs-32">{numeral(boxes.total_companies).format('0,0')}</h1>
                        <label className="d-block mb-1 fw-medium text-dark">
                            Total Companies
                        </label>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm="12" md="3">
                <Card className="card-one">
                    <Card.Body className="p-3">
                        <div className="d-block fs-40 lh-1 text-primary mb-1">
                            <i className="ri-road-map-line"></i>
                        </div>
                        <h1 className="card-value mb-0 ls--1 fs-32">{numeral(boxes.total_branches).format('0,0')}</h1>
                        <label className="d-block mb-1 fw-medium text-dark">
                            Total Branches
                        </label>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm="12" md="3">
                <Card className="card-one">
                    <Card.Body className="p-3">
                        <div className="d-block fs-40 lh-1 text-primary mb-1">
                            <i className="ri-user-2-line"></i>
                        </div>
                        <h1 className="card-value mb-0 ls--1 fs-32">{numeral(boxes.total_employees).format('0,0')}</h1>
                        <label className="d-block mb-1 fw-medium text-dark">
                            Total Employees
                        </label>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm="12" md="3">
                <Card className="card-one">
                    <Card.Body className="p-3">
                        <div className="d-block fs-40 lh-1 text-primary mb-1">
                            <i className="ri-account-pin-circle-line"></i>
                        </div>
                        <h1 className="card-value mb-0 ls--1 fs-32">{numeral(boxes.total_attendances).format('0,0')}</h1>
                        <label className="d-block mb-1 fw-medium text-dark">
                            Total Attendances
                        </label>
                    </Card.Body>
                </Card>
            </Col>

            <Col sm="12" md="7" xl="8">
                <Card className="card-one">
                    <Card.Header>
                        <Card.Title>Attendance in the last week</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Chart
                            className='apex-chart-nine'
                            options={graph.options}
                            series={graph.series}
                            type="bar"
                        />
                    </Card.Body>
                </Card>
            </Col>

            <Col sm='12' md='5' xl='4'>
                <Card className="card-one">
                    <Card.Header>
                        <Card.Title>Top 10 attendances today</Card.Title>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <ul className="people-group">
                            {attendances.map((v, i) => {
                                return (
                                    <li className="people-item" key={i}>
                                        <div className="avatar">
                                            <span className="avatar-initial bg-teal fs-20">
                                                <i className="ri-road-map-line"></i>
                                            </span>
                                        </div>
                                        <div className="people-body">
                                            <h6><a href="#">{v?.branch_name} #{i + 1}</a></h6>
                                            <span>{v?.company_name}</span>
                                        </div>
                                        <div className="text-end">
                                            <div className="fs-sm">
                                                <strong>{numeral(v?.total).format('0,0')}</strong>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </Card.Body>
                    <div className="card-footer d-flex justify-content-center">
                        <Link to="/leaves" className="fs-sm">View all data</Link>
                    </div>
                </Card>
            </Col>
        </Row>
    );
}

export default AdminDashboard;
