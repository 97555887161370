import { Card, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Rest from "../../../libraries/Rest";
import Alert from "../../../libraries/Alert";
import * as Components from "../../../components/Components";
import Validator from "../../../libraries/Validator";

function CompanyForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const rest = new Rest();

    const isCreate = id?.toLowerCase() === 'add' ? true : false;

    /**
     * Init validator of payload
     */
    const validator = new Validator({
        name: { required: true },
        description: { required: true },
        email: { required: true },
        address: { required: true },
        is_active: { required: true },
    });

    /**
     * Init states
     */
    const rootPath = `/companies`;
    const endpoint = `/companies`;

    let [payload, setPayload] = useState({
        name: null,
        description: null,
        email: null,
        phone: null,
        address: null,
        is_active: false,
        remark: null
    });

    /**
     * First init function
     */
    const getCompanyById = async () => {
        Alert.showLoading();
        const response = await rest.get(`${endpoint}/${id}`);
        Alert.close();
        if (response?.data?.data !== undefined) {
            const data = response.data.data;
            setPayload({
                name: data?.name,
                description: data?.description,
                email: data?.email,
                phone: data?.phone,
                address: data?.address,
                is_active: data?.is_active ? 'true' : 'false',
                remark: data?.remark,
            });
        } else {
            Alert.showMessage('Error', 'error', 'Data is not found');
            navigate(rootPath);
        }
    }

    useEffect(() => {
        if (!isCreate) getCompanyById();
    }, []);


    /**
     * Action handlers
     */
    const updatePayload = (field, value) => {
        setPayload({ ...payload, [field]: value });
    }

    const submit = async () => {
        /**
         * Validate input
         */
        const errMessages = validator.validate(payload);
        if (errMessages.length > 0) {
            let errMessageStrings = ``;
            for (let i = 0; i < errMessages.length; i++) {
                errMessageStrings += `${errMessages[i]}<br />`;
            }
            return Alert.showMessage(`Invalid input`, `warning`, errMessageStrings);
        }

        /**
         * Submit action
         */

        payload.is_active = payload.is_active === 'true' ? true : false; // validate few data before send

        Alert.showConfirm({
            url: endpoint + (!isCreate ? `/${id}` : ``),
            method: isCreate ? 'POST' : 'PUT',
            data: payload,
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    /**
     * Rendering DOM
     */
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <ol className="breadcrumb fs-sm mb-1">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={rootPath}>List companies</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Form create
                        </li>
                    </ol>
                </div>
            </div>

            <Row className="g-3">
                <Col xl="12">
                    <Card className="card">
                        <Card.Header className="bg-primary">
                            <Card.Title
                                as="label"
                                className="fs-sm fw-medium mb-1 text-white"
                            >
                                Form create company
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="bg-white">
                            <Link to={rootPath} className="btn btn-sm btn-warning text-white">
                                <i className="ri-arrow-left-line"></i> Back
                            </Link>
                            &nbsp;
                            <button className="btn btn-sm btn-primary" onClick={submit}>
                                <i className="ri-check-line"></i> Save
                            </button>
                            <br /><br />
                            <Row className="g-3">
                                <Col sm="4">
                                    <Components.Input
                                        type="text"
                                        name="name"
                                        label="Company Name"
                                        placeholder="Company Name"
                                        defaultValue={payload.name}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Input
                                        type="text"
                                        name="email"
                                        label="Email"
                                        placeholder="Email"
                                        defaultValue={payload.email}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Input
                                        type="text"
                                        name="phone"
                                        label="Phone"
                                        placeholder="Phone"
                                        defaultValue={payload.phone}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="12">
                                    <Components.Input
                                        type="text"
                                        name="description"
                                        label="Description"
                                        placeholder="Description"
                                        defaultValue={payload.description}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="8">
                                    <Components.Input
                                        type="text"
                                        name="address"
                                        label="Company Address"
                                        placeholder="Company Address"
                                        defaultValue={payload.address}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Select
                                        name="is_active"
                                        label="Status"
                                        defaultValue={payload.is_active}
                                        options={[
                                            {
                                                value: 'false',
                                                label: 'Not Active'
                                            }, {
                                                value: 'true',
                                                label: 'Active'
                                            }
                                        ]}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="12">
                                    <Components.Input
                                        type="text"
                                        name="remark"
                                        label="Remark"
                                        placeholder="Remark"
                                        defaultValue={payload.remark}
                                        change={updatePayload}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default CompanyForm;