import { Card, Row, Col, Modal } from "react-bootstrap";
import { Grid, _ } from "gridjs-react";
import { useEffect, useState } from "react";
import Rest from "./../../libraries/Rest";
import LocalStorage from "./../../libraries/LocalStorage";

import * as Components from "./../../components/Components";

function ReportEmployees() {
    const rest = new Rest();
    const localStorage = new LocalStorage();
    const roleName = localStorage.getItem('role_name');
    const allowedRole = 'super_admin'; // static role validation (code: VL01)
    var endpoint = `/reports/employees?withAttachments=true`;

    const [companies, setCompanies] = useState([]);
    const [payload, setPayload] = useState({
        companyId: null,
        startDate: null,
        endDate: null
    });
    const [parameters, setParameters] = useState('');

    /**
     * State for open modal
     */
    const [attachments, setAttachments] = useState([]);
    const [showAttachment, setShowAttachment] = useState(false);
    const openAttachment = (type) => setShowAttachment(type);

    /**
     * First init
     */
    const getCompanies = async () => {
        let res = await rest.get(`/companies?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let options = [];
            for (let i = 0; i < res.length; i++) {
                options.push({
                    value: res[i]['id'] !== undefined ? res[i]['id'] : null,
                    label: res[i]['name'] !== undefined ? res[i]['name'] : null,
                });
            }
            setCompanies(options);
        }
    }

    const updatePayload = async (field, value) => {
        setPayload({ ...payload, [field]: value });

        /**
         * Set parameters
         */
        let _parameters = parameters;
        if (_parameters.includes(field)) {
            const _parr = _parameters.split('&');
            _parameters = ''; // reset;
            const _skip = value && value !== '' ? false : true;

            for (let i = 0; i < _parr.length; i++) {
                let _varr = _parr[i].split('=');
                if (_varr.length >= 1 && _varr[0] === field) {
                    if (!_skip) {
                        _varr[1] = value;
                    } else {
                        continue; // remove this field
                    }
                }

                /**
                 * Join the parameters
                 */
                if (
                    _varr[0] && _varr[0] !== undefined
                    &&
                    _varr[1] && _varr[1] !== undefined
                ) _parameters += `&${_varr[0]}=${_varr[1]}`;
            }
        } else {
            _parameters += `&${field}=${value}`;
        }
        setParameters(_parameters);
    }

    useEffect(() => {
        if (roleName?.toLowerCase() === allowedRole) getCompanies(); // static role validation (code: VL01)
    }, []);

    const openAttachments = (_attachments = []) => {
        setAttachments(_attachments);
        openAttachment(true);
    }

    return (
        <div>
            <Card className="bg-white">
                <Card.Header className="bg-primary">
                    <Card.Title as="label" className="fs-sm fw-medium mb-1 text-white">
                        Report of Employees
                    </Card.Title>
                </Card.Header>
                <Card.Body className="bg-white">
                    <Row className="mt-1">
                        {/* Modal attachments */}
                        <Modal show={showAttachment} onHide={() => openAttachment(false)} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Detail Attachments</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    {attachments.map((v, i) => {
                                        return (
                                            <Col sm="6" key={i}>
                                                <img
                                                    src={v?.file_url}
                                                    className="img-thumbnail"
                                                />
                                                <p style={{ fontWeight: "bold", marginTop: 20 }}>
                                                    {v?.file_name}
                                                </p>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Modal.Body>
                        </Modal>

                        {roleName?.toLowerCase() === allowedRole &&
                            <Col sm="3">
                                <Components.Select
                                    label="Company"
                                    name="companyId"
                                    defaultValue={payload.companyId}
                                    change={updatePayload}
                                    options={companies}
                                />
                            </Col>
                        }
                        <Col sm="2">
                            <Components.Input
                                type="date"
                                label="Start Date"
                                name="startDate"
                                defaultValue={payload.startDate}
                                change={updatePayload}
                            />
                        </Col>
                        <Col sm="2">
                            <Components.Input
                                type="date"
                                label="End Date"
                                name="endDate"
                                defaultValue={payload.endDate}
                                change={updatePayload}
                            />
                        </Col>
                    </Row>
                    <Grid
                        className={{ table: 'table table-bordered mb-0' }}
                        resizable={true}
                        language={{
                            search: {
                                placeholder: 'Find something..'
                            }
                        }}
                        columns={[
                            'ID Employee',
                            'Name',
                            'Active',
                            'Company',
                            'Department - Position',
                            'Type',
                            'Total Attendances',
                            'Total Leaves',
                            'Total Lates',
                            'Total Overtimes',
                        ]}
                        server={{
                            url: `${rest.MAIN_HOST}${endpoint}${parameters}`,
                            headers: {
                                'authorization': `Bearer ${rest.ACCESS_TOKEN}`
                            },
                            then: data => data?.data?.data?.map(r => {
                                let _company = '-';
                                if (r?.users?.user_companies?.length > 0) {
                                    _company = `${r?.users?.user_companies[0]?.companies?.name}`;
                                }

                                let _position = '-';
                                let _employeeType = '-';
                                if (r?.employee_job_histories?.length > 0) {
                                    _position = `${r?.employee_job_histories[0]?.departments?.name}`;
                                    _position += ` - ${r?.employee_job_histories[0]?.positions?.name}`;

                                    _employeeType = `${r?.employee_job_histories[0]?.employee_types?.name}`;
                                }

                                return [
                                    r?.nik,
                                    `${r?.first_name} ${r?.middle_name ?? ''} ${r?.last_name ?? ''}`,
                                    r?.is_active ? 'Active' : 'Not Active',
                                    `${_company}`,
                                    `${_position}`,
                                    `${_employeeType}`,
                                    `${r?.total_attendances}`,
                                    `${r?.total_leaves}`,
                                    `${r?.total_lates} Mins(s)`,
                                    `${r?.total_overtimes} Mins(s)`,
                                ]
                            }),
                            total: data => data?.data?.total?.data,
                            handle: (res) => {
                                if (res.status === 401) return rest.interceptor();
                                return res.json();
                            }
                        }}
                        search={{
                            server: {
                                url: (prev, keyword) => `${prev}?keywords=${keyword}`
                            }
                        }}
                        pagination={{
                            limit: 10,
                            server: {
                                url: (prev, page, limit) => {
                                    const operator = prev.includes('?') ? '&' : '?';
                                    if (parseInt(page) <= 0) page = 1;
                                    return `${prev}${operator}limit=${limit}&page=${page}`
                                }
                            }
                        }}
                    />
                </Card.Body>
            </Card>
        </div>
    )

}

export default ReportEmployees;