import { Card, Col, Row, Modal, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LocalStorage from "../../libraries/LocalStorage";
import numeral from "numeral";

import Rest from "../../libraries/Rest";
import Alert from "../../libraries/Alert";
import * as Components from "../../components/Components";
import Validator from "../../libraries/Validator";

function PayrollForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const rest = new Rest();
    const localStorage = new LocalStorage();
    const roleName = localStorage.getItem('role_name'); // static role validation (code: VAL01)

    const isCreate = id?.toLowerCase() === 'add' ? true : false;
    const isSuperAdmin = roleName?.toLowerCase() === 'super_admin' ? true : false;
    // const isCompanyAdmin = roleName?.toLowerCase() === 'company_admin' ? true : false;

    /**
     * Init validator of payload
     */
    const validator = new Validator({
        start_date: { required: true },
        end_date: { required: true }
    });

    /**
     * Init states
     */
    const rootPath = `/payrolls`;
    const endpoint = `/payrolls`;

    const [companies, setCompanies] = useState([]);
    const [payload, setPayload] = useState({
        company_id: null,
        start_date: null,
        end_date: null,
        remark: null,
        status: null,
        type: null,
    });
    const [employees, setEmployees] = useState([]);
    const [_payrollType, _setPayrollType] = useState(null);

    /**
     * State for modal items [_tmp]
     * Items always updated by employee selected
     * 
     * @var array _tempItems
    */
    const [_tempItems, _setTempItems] = useState([]);
    const [_tempActiveEmployee, _setTempActiveEmployee] = useState(null);
    const [showModalItem, setModalItem] = useState(false);
    const openModalItem = (type) => {
        /**
         * Rest _tempItems and _tempActiveEmployee whenever type equal false
         * Assumed user closed the modal to cancel it!
         */
        if (!type) {
            _setTempItems([]);
            _setTempActiveEmployee(null);
        }

        /**
         * Action for the modal
         */
        setModalItem(type);
    }

    /**
     * This data for payroll status
     */
    const Statuses = [
        {
            value: 'S1',
            label: 'Draft'
        }, {
            value: 'S2',
            label: 'Confirmed/Posted'
        }
    ];

    /**
     * This data for payroll type
     */
    const PayrollTypes = [
        {
            value: 'T1',
            label: 'Montly'
        }, {
            value: 'T2',
            label: 'Weekly'
        }, {
            value: 'T3',
            label: 'Daily'
        }
    ];

    /**
     * This data for payroll:detail types
     */
    const Types = [
        {
            value: 'T2',
            label: 'Allowance'
        }, {
            value: 'T3',
            label: 'Deduction'
        }, {
            value: 'T4',
            label: 'Tax'
        }
    ];

    async function getCompanies() {
        const response = await rest.get(`/companies?page=1&limit=100`);

        if (response?.data?.data?.data) {
            const data = response.data.data.data;

            let _options = [];
            for (let i = 0; i < data.length; i++) {
                _options.push({
                    value: data[i]?.id,
                    label: data[i]?.name
                });
            }
            setCompanies(_options);
        }
    }

    async function getEmployees(companyId = null) {
        Alert.showLoading();
        const response = await rest.get(`/employees?page=1&limit=10000&companyId=${companyId}`);
        Alert.close();

        if (response?.data?.data?.data) {
            const data = response.data.data.data;

            let _rows = [];
            for (let i = 0; i < data.length; i++) {
                let salary_type = 'ST1'; // default
                if (data[i]?.employee_salary_attributes.length > 0) {
                    salary_type = data[i]?.employee_salary_attributes[0]?.salary_type?.toUpperCase();
                }

                _rows.push({
                    employee_id: data[i]?.id,
                    nik: data[i]?.nik,
                    name: `${data[i]?.first_name} ${data[i]?.middle_name ?? ''} ${data[i]?.last_name ?? ''}`,
                    salary_type_text: data[i]?.salary_type_text,
                    salary_type: salary_type,
                    total_allowances: 0,
                    total_deductions: 0,
                    total_taxes: 0,
                    details: []
                });
            }
            setEmployees(_rows);
        }
    }

    const getDataById = async () => {
        const response = await rest.get(`/payrolls/${id}`);
        if (response?.data?.data !== undefined) {
            const data = response.data.data;
            /**
             * Set default value for payload
             */
            setPayload({
                ...payload,
                company_id: data?.companies?.id,
                start_date: data?.start_date,
                end_date: data?.end_date,
                remark: data?.remark,
                status: data?.status,
                type: data?.type,
            });

            /**
             * Set default employees
             */
            let _employees = [];
            for (let i = 0; i < data?.employees?.length; i++) {
                /**
                 * Generate detail items
                 */
                let _details = [];
                let total_allowances = 0;
                let total_deductions = 0;
                let total_taxes = 0;

                for (let j = 0; j < data.employees[i]?.salary_details?.length; j++) {
                    const jvalue = data.employees[i].salary_details[j];
                    if (jvalue?.is_additional === true) {
                        _details.push({
                            id: jvalue?.id,
                            name: jvalue?.name,
                            type: jvalue?.type,
                            value: jvalue?.value,
                            description: jvalue?.description,
                            remark: jvalue?.remark,
                            tax_percent: jvalue?.tax_percent,
                            tax_amount: jvalue?.tax_amount,
                            is_paid: data.employees[i]?.is_paid,
                        });

                        /**
                         * Summarize item
                         */
                        if (jvalue?.type?.toUpperCase() === 'T2') total_allowances += jvalue?.value;
                        if (jvalue?.type?.toUpperCase() === 'T3') total_deductions += jvalue?.value;
                        if (jvalue?.type?.toUpperCase() === 'T4') total_taxes += jvalue?.value;
                    }
                }

                /**
                 * Generate employee data
                 */
                const _emp = {
                    employee_id: data?.employees[i]?.employees?.id,
                    nik: data?.employees[i]?.employees?.nik,
                    name: `${data?.employees[i]?.employees?.first_name} ${data?.employees[i]?.employees?.middle_name ?? ''} ${data?.employees[i]?.employees?.last_name ?? ''}`,
                    total_allowances,
                    total_deductions,
                    total_taxes,
                    details: _details
                }
                _employees.push(_emp);
            }
            setEmployees(_employees);
        }
    }

    useEffect(() => {
        async function __init() {
            Alert.showLoading();

            if (isSuperAdmin) {
                await getCompanies();
            } else {
                await getEmployees();
            }
            if (!isCreate) await getDataById();
            Alert.close();
        }
        __init();
    }, []);

    /**
     * Action handlers
     */
    const updatePayload = async (field, value) => {
        if (field === 'company_id') {
            /**
             * Load employees
             */
            await getEmployees(value);
        } else if (field === 'type') {
            _setPayrollType(value?.toUpperCase() === 'T1' ? 'ST1' : 'ST2');
            console.log(_payrollType);
        }

        /**
         * Update state payload
         */
        setPayload({ ...payload, [field]: value });
    }

    const openDetail = async (row) => {
        let data = employees[row];
        if (data !== undefined) {
            _setTempActiveEmployee({
                row,
                name: data?.name,
                employee_id: data?.employee_id
            });
            _setTempItems(data.details);
            openModalItem(true);
        }
    }

    const addNewItem = () => {
        _setTempItems([..._tempItems, {
            id: null,
            name: '',
            type: 'T2',
            value: 0,
            description: '',
            remark: '',
            tax_percent: 0,
            tax_amount: 0,
            is_paid: false
        }]);
    }

    const removeItem = (row) => {
        _setTempItems((prevItem) => prevItem.filter((_, i) => i !== row));
    }

    const updatePayloadItem = (row, field, value) => {
        _setTempItems((prevItem) => {
            let updatedItems = prevItem;
            if (updatedItems[row][field] !== undefined) updatedItems[row][field] = field === 'value' ? parseFloat(value) : value;
            return updatedItems;
        });
    }

    const changeStatus = async (status) => {
        Alert.showConfirm({
            url: `/payrolls/change_status/${id}/${status}`,
            method: 'GET'
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    const saveItems = async (updateDatabase = false) => {
        /**
         * This function is to save changes of detail items each employees
         * Update the items into state:employees->details
         */
        setEmployees((prevItems) => {
            let updatedItems = prevItems;
            if (updatedItems[_tempActiveEmployee?.row]?.details !== undefined) {
                /**
                 * Updating details
                 */
                updatedItems[_tempActiveEmployee.row].details = _tempItems;

                /**
                 * Re-calculating total(s)
                 */
                let total_allowances = 0;
                let total_deductions = 0;
                let total_taxes = 0;
                for (let i = 0; i < _tempItems.length; i++) {
                    if (_tempItems[i]?.type?.toUpperCase() === 'T2') total_allowances += parseInt(_tempItems[i].value);
                    if (_tempItems[i]?.type?.toUpperCase() === 'T3') total_deductions += parseInt(_tempItems[i].value);
                    if (_tempItems[i]?.type?.toUpperCase() === 'T4') total_taxes += parseInt(_tempItems[i].value);
                }
                updatedItems[_tempActiveEmployee.row].total_allowances = total_allowances;
                updatedItems[_tempActiveEmployee.row].total_deductions = total_deductions;
                updatedItems[_tempActiveEmployee.row].total_taxes = total_taxes;
            }
            return updatedItems;
        });

        /**
         * If updateDatabase=True
         * Then send this data to service to update only specific this employee
         * For performance issue
         */
        if (updateDatabase === true) {
            Alert.showLoading();
            const response = await rest.put(`/payrolls/employee/${id}`, {
                employee_id: employees[_tempActiveEmployee.row]?.employee_id,
                details: employees[_tempActiveEmployee.row]?.details
            });
            Alert.close();
            if (response?.success === false) Alert.showMessage('warning', 'warning', 'Something wrong when updating items to database');
        }

        /**
         * Close modal and reset _tmp:state
         */
        openModalItem(false);
    }

    const submit = async () => {
        /**
         * Re-structures fields:types in payload before send to service
         */
        let _employees = [];
        for (let i = 0; i < employees.length; i++) {
            let _details = employees[i]?.details;

            for (let j = 0; j < _details.length; j++) {
                _details[j].value = parseInt(_details[j].value);
            }

            // only send employees with specific type
            if (_payrollType === employees[i]?.salary_type || !_payrollType) {
                _employees.push({
                    employee_id: employees[i]?.employee_id,
                    details: _details
                });
            }
        }
        let _payloads = {
            ...payload,
            start_date: payload.start_date,
            end_date: payload.end_date,
            employees: _employees
        }

        /**
         * Validate input
         */
        const errMessages = validator.validate(payload);
        if (errMessages.length > 0) {
            let errMessageStrings = ``;
            for (let i = 0; i < errMessages.length; i++) {
                errMessageStrings += `${errMessages[i]}<br />`;
            }
            return Alert.showMessage(`Invalid input`, `warning`, errMessageStrings);
        }

        /**
         * Submit action
         */

        Alert.showConfirm({
            url: endpoint + (!isCreate ? `/${id}` : ``),
            method: isCreate ? 'POST' : 'PUT',
            data: _payloads,
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    /**
     * Rendering DOM
     */
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <ol className="breadcrumb fs-sm mb-1">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        {!isCreate && <li className="breadcrumb-item">
                            <Link to="/payrolls">List payrolls</Link>
                        </li>}
                        <li className="breadcrumb-item active" aria-current="page">
                            Form {isCreate ? 'generate' : 'update'} payroll
                        </li>
                    </ol>
                </div>
            </div>

            <Row className="g-3">
                <Col xl="12">
                    {/* Modl detail items:per-employee */}
                    <Modal show={showModalItem} onHide={() => openModalItem(false)} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Detail component items {_tempActiveEmployee?.name !== undefined ? `for ${_tempActiveEmployee.name}` : ''}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table table-condensed table-hovered">
                                <thead>
                                    <tr>
                                        <th>Component</th>
                                        <th>Type</th>
                                        <th>Total</th>
                                        <th>Description</th>
                                        <th>Remark</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_tempItems.map((v, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder="e.g Tunjangan lain-lain"
                                                        defaultValue={v?.name}
                                                        onChange={(e) => updatePayloadItem(i, 'name', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <select
                                                        className="form-select"
                                                        defaultValue={v?.type}
                                                        onChange={(e) => updatePayloadItem(i, 'type', e.target.value)}
                                                    >
                                                        {Types.map((t, idx) => {
                                                            return (
                                                                <option
                                                                    key={idx}
                                                                    value={t.value}
                                                                >
                                                                    {t.label}
                                                                </option>
                                                            )
                                                        })}
                                                    </select>
                                                </td>
                                                <td style={{
                                                    width: 150
                                                }}>
                                                    <input
                                                        type="number"
                                                        name="value"
                                                        className="form-control"
                                                        defaultValue={v?.value}
                                                        onChange={(e) => updatePayloadItem(i, 'value', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="description"
                                                        className="form-control"
                                                        placeholder="e.g Tunjangan kebutuhan operasional"
                                                        defaultValue={v?.description}
                                                        onChange={(e) => updatePayloadItem(i, 'description', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="remark"
                                                        className="form-control"
                                                        placeholder="e.g Tunjangan ini bersifat sementara"
                                                        defaultValue={v?.remark}
                                                        onChange={(e) => updatePayloadItem(i, 'remark', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-danger btn-sm" onClick={() => removeItem(i)}>
                                                        <i className="ri-delete-bin-line"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={6}>
                                            <button className="btn btn-circle btn-primary btn-sm" style={{ borderRadius: '50%' }} type="button" onClick={addNewItem}>
                                                <i className="ri-add-line"></i>
                                            </button>
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            {!isCreate && <Button variant="success" onClick={() => saveItems(true)}>
                                Save and update to database
                            </Button>}
                            <Button variant="primary" onClick={() => saveItems(false)}>
                                Save changes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Form payroll */}
                    <Card className="card">
                        <Card.Header className="bg-primary">
                            <Card.Title
                                as="label"
                                className="fs-sm fw-medium mb-1 text-white"
                            >
                                Form {isCreate ? 'generate' : 'update'} payroll
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="bg-white">
                            {!isCreate && <Link className="btn btn-sm btn-warning text-white" onClick={() => navigate('/payrolls')} style={{ marginRight: 5 }}>
                                <i className="ri-arrow-left-line"></i> Back
                            </Link>}
                            {!isCreate && payload?.status?.toUpperCase() === 'S1' && <Link className="btn btn-sm btn-success text-white" onClick={() => changeStatus('confirmed')} style={{ marginRight: 5 }}>
                                <i className="ri-check-line"></i> Set as confirmed
                            </Link>}
                            {!isCreate && payload?.status?.toUpperCase() === 'S1' && <Link className="btn btn-sm btn-danger text-white" onClick={() => changeStatus('rejected')} style={{ marginRight: 5 }}>
                                <i className="ri-close-line"></i> Set as rejected
                            </Link>}
                            <button className="btn btn-sm btn-primary" onClick={submit}>
                                <i className="ri-check-line"></i> Save
                            </button>
                            <br /><br />
                            <Row>
                                {isSuperAdmin && <Col sm="4">
                                    <Components.Select
                                        name="company_id"
                                        label="Company"
                                        defaultValue={payload.company_id}
                                        change={updatePayload}
                                        disabled={isCreate ? false : true}
                                        options={companies}
                                    />
                                </Col>}
                                <Col sm="2">
                                    <Components.Input
                                        type="date"
                                        name="start_date"
                                        label="Start Date"
                                        defaultValue={payload.start_date}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="2">
                                    <Components.Input
                                        type="date"
                                        name="end_date"
                                        label="End Date"
                                        defaultValue={payload.end_date}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="2">
                                    <Components.Select
                                        name="status"
                                        label="Status"
                                        defaultValue={payload.status}
                                        change={updatePayload}
                                        options={Statuses}
                                    />
                                </Col>
                                <Col sm="2">
                                    <Components.Select
                                        name="type"
                                        label="Type"
                                        defaultValue={payload.type}
                                        change={updatePayload}
                                        options={PayrollTypes}
                                    />
                                </Col>
                                <Col sm="12">
                                    <Components.Input
                                        name="remark"
                                        label="Remark"
                                        placeholder="e.g Your note before generating payroll"
                                        defaultValue={payload.remark}
                                        change={updatePayload}
                                    />
                                </Col>
                            </Row>
                            <hr />
                            <legend>List Employees</legend>
                            <table className="table table-condensed">
                                <thead>
                                    <tr>
                                        <th>NIK</th>
                                        <th>Employee</th>
                                        <th>Total Allowances</th>
                                        <th>Total Deductions</th>
                                        <th>Total Taxes</th>
                                        <th>Salary Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employees.map((v, i) => (
                                        (_payrollType === v?.salary_type || !_payrollType) && (
                                            <tr key={i}>
                                                <td>{v?.nik}</td>
                                                <td>{v?.name}</td>
                                                <td>{numeral(v?.total_allowances).format('0,0')}</td>
                                                <td>{numeral(v?.total_deductions).format('0,0')}</td>
                                                <td>{numeral(v?.total_taxes).format('0,0')}</td>
                                                <td>{v?.salary_type_text !== undefined ? v.salary_type_text : '-'}</td>
                                                <td>
                                                    <button type="button" className="btn btn-sm btn-primary" onClick={() => openDetail(i)}>
                                                        Details
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    ))}
                                </tbody>
                            </table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div >
    )
}

export default PayrollForm;