import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import LocalStorage from "../libraries/LocalStorage";

function Authenticated(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const storage = new LocalStorage();
    const roleName = storage.getItem('role_name');
    const currentPath = location?.pathname ? location.pathname.toLowerCase() : '/';

    /**
     * Check for first init
     */
    let isFirstInit = false;
    let allowed = false;
    if(roleName === undefined || roleName === null) isFirstInit = true;

    if(!isFirstInit) {
        /**
         * Define list of which menus that role can't access
         */
        const pathCantAccess = {
            employee: [
                '/users',
                '/companies',
                '/employees',
                '/payrolls',
                '/reports'
            ],
            company_admin: [
                '/users',
                '/companies'
            ]
        }
    
        /**
         * Check path that user try to access by role
         */
        if (pathCantAccess[roleName] === undefined) {
            /**
             * Assumed this is super admin, so allow everything
             */
            allowed = true;
        } else {
            if (!pathCantAccess[roleName].includes(currentPath)) {
                allowed = true;
            }
        }
    }

    /**
     * Condition when user is not authenticated with some reasons
     * Token not valid|expired or user is not exists from your database
     */
    useEffect(() => {
        /**
         * Redirecting to login page
         */
        if(isFirstInit) navigate('/me/change_password');
        if (!isFirstInit && !allowed) navigate('/unauthorized');
    }, []);

    return props.render;
}

export default Authenticated;