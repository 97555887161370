import Swal from "sweetalert2";
import Rest from "./Rest";

function showLoading() {
    Swal.fire({
        title: 'Loading...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
            Swal.showLoading();
        }
    });
}

function close() {
    Swal.close();
}

function showMessage(title, icon, text) {
    const availableIcons = [
        "success",
        "error",
        "warning",
        "info",
        "question"
    ];

    if (!availableIcons.includes(icon)) icon = "warning"; // set default if icon doesn't found
    Swal.fire({
        title,
        icon,
        html: text
    });
}

/**
 * 
 * @param {
 *  url: string
 *  method: string
 *  data: object
 * } preConfirm 
 */
function showConfirm(preConfirm = null) {
    let options = {
        title: "Are you sure?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
        cancelButtonColor: '#d9534f',
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false
    }

    /**
     * Preparing options
     */
    if (preConfirm) {
        options['showLoaderOnConfirm'] = true;
        options['preConfirm'] = async () => {
            try {
                const rest = new Rest();

                const method = preConfirm?.method !== undefined ? preConfirm.method.toUpperCase() : 'POST';
                let response = null;
                if (method === 'GET') {
                    response = await rest.get(preConfirm.url);
                } else if (method === 'DELETE') {
                    response = await rest.del(preConfirm.url);
                } else {
                    response = await rest.post(preConfirm.url, preConfirm.data, method);
                }
                return response;
            } catch (error) {
                return error;
            }
        }
    }

    /**
     * init Alert
     */
    return Swal.fire(options);
}

async function showConfirmAsync() {
    return await Swal.fire({
        title: "Are you sure?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
        cancelButtonColor: '#d9534f',
        showLoaderOnConfirm: true
    });
}

export default {
    showLoading,
    close,
    showMessage,
    showConfirm,
    showConfirmAsync
}