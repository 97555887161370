import { Card, Col, Row } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Rest from "../../libraries/Rest";
import Alert from "../../libraries/Alert";
import * as Components from "../../components/Components";
import Validator from "../../libraries/Validator";

function ChangePassword() {
    const rest = new Rest();
    const navigate = useNavigate();

    const [payload, setPayload] = useState({
        old_password: '',
        new_password: '',
        confirm_password: ''
    });

    /**
     * Init validator of payload
     */
    const validator = new Validator({
        old_password: { required: true },
        new_password: { required: true },
        confirm_password: { required: true }
    });

    /**
     * Action handlers
     */
    const submit = async () => {
        /**
         * Validate input
         */
        const errMessages = validator.validate(payload);
        if (errMessages.length > 0) {
            let errMessageStrings = ``;
            for (let i = 0; i < errMessages.length; i++) {
                errMessageStrings += `${errMessages[i]}<br />`;
            }
            return Alert.showMessage(`Invalid input`, `warning`, errMessageStrings);
        }

        /**
         * Send to service
         */
        Alert.showConfirm({
            url: `/me/change_password`,
            method: 'PUT',
            data: payload,
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    setPayload({
                        ...payload,
                        old_password: '',
                        new_password: '',
                        confirm_password: '',
                    });
                    navigate('/me/change_password');
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.data?.error?.detail);
                }
            }
        });
    }

    /**
     * Render DOM
     */
    return (
        <div>
            <Row className="g-3">
                <Col xl={{ span: 6, offset: 3 }}>
                    <Card className="card">
                        <Card.Header className="bg-primary">
                            <Card.Title
                                as="label"
                                className="fs-sm fw-medium mb-1 text-white"
                            >
                                Form change password
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="bg-white">
                            <div className="mb-2">
                                <label className="form-label">Old password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    value={payload.old_password}
                                    onChange={(e) => setPayload({ ...payload, old_password: e.target.value })}
                                />
                            </div>
                            <div className="mb-2">
                                <label className="form-label">New password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    value={payload.new_password}
                                    onChange={(e) => setPayload({ ...payload, new_password: e.target.value })}
                                />
                            </div>
                            <div className="mb-2">
                                <label className="form-label">Confirm password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    value={payload.confirm_password}
                                    onChange={(e) => setPayload({ ...payload, confirm_password: e.target.value })}
                                />
                            </div>
                            <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={submit}
                                style={{
                                    marginTop: 10
                                }}
                            >
                                <i className="ri-save-line"></i> Save
                            </button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ChangePassword;