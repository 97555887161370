import { Card, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Rest from "../../libraries/Rest";
import Alert from "../../libraries/Alert";
import * as Components from "../../components/Components";
import Validator from "../../libraries/Validator";
import LocalStorage from "../../libraries/LocalStorage";

import { FileUploader } from "react-drag-drop-files";

function OvertimeForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const rest = new Rest();
    const localStorage = new LocalStorage();
    const roleName = localStorage.getItem('role_name'); // static role validation (code: VAL01)

    const isCreate = id?.toLowerCase() === 'add' ? true : false;
    const isSuperAdmin = roleName?.toLowerCase() === 'super_admin' ? true : false;
    const isCompanyAdmin = roleName?.toLowerCase() === 'company_admin' ? true : false;

    /**
     * Init validator of payload
     */
    const validator = new Validator({
        attendance_id: { required: true },
        overtime_date: { required: true },
        time_start: { required: true },
        time_end: { required: true }
    });

    /**
     * Init states
     */
    const rootPath = `/overtimes`;
    const endpoint = `/overtimes`;

    let [payload, setPayload] = useState({
        attendance_id: null,
        no_attendance: null,
        company_id: null,
        employee_id: null,
        overtime_date: null,
        time_start: null,
        time_end: null,
        remark: null
    });
    const [attachments, setAttachments] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [employees, setEmployees] = useState([]);

    /**
     * First init function
     */

    const getCompanies = async () => {
        let res = await rest.get(`/companies?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let options = [];
            for (let i = 0; i < res.length; i++) {
                options.push({
                    value: res[i]['id'] !== undefined ? res[i]['id'] : null,
                    label: res[i]['name'] !== undefined ? res[i]['name'] : null,
                });
            }
            setCompanies(options);
        }
    }

    const getEmployees = async (company_id = null) => {
        let url = `/employees?page=1&limit=100`;
        if (company_id) url += `&companyId=${company_id}`;

        let res = await rest.get(url);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let options = [];
            for (let i = 0; i < res.length; i++) {
                let label = res[i]['nik'] !== undefined ? res[i]['nik'] : null;
                if (label) label = `${res[i]?.first_name ?? ''} ${res[i]?.middle_name ?? ''} ${res[i]?.last_name ?? ''} (${label})`;

                options.push({
                    value: res[i]['id'] !== undefined ? res[i]['id'] : null,
                    label: label,
                });
            }
            setEmployees(options);
        }
    }

    const getLastPresence = async (employee_id = null) => {
        let url = `/attendances/latest`;
        if (employee_id) url += `?employeeId=${employee_id}`;

        const response = await rest.get(url);
        let updateAttributes = {
            attendance_id: null,
            no_attendance: null,
            overtime_date: null,
            employee_id: null
        }

        if (response?.data?.data !== undefined) {
            const data = response.data.data;
            updateAttributes = {
                attendance_id: data?.id,
                no_attendance: data.no_attendance,
                overtime_date: data?.date_in
            }
        }

        /**
         * Update payload
        */
        if (employee_id && employee_id !== '') updateAttributes['employee_id'] = employee_id;
        setPayload({
            ...payload,
            ...updateAttributes
        });
    }

    const getDataById = async () => {
        const response = await rest.get(`${endpoint}/${id}`);
        if (response?.data?.data !== undefined) {
            const data = response.data.data;

            /**
             * Validate if status is not Draft(S1)
             * Then redirect back to list - assumed form only can changed when status still Draft(S1)
             */
            if (data?.status?.toUpperCase() !== 'S1') {
                Alert.showMessage('Warning', 'warning', 'You cannot update this data');
                navigate(rootPath);
            }

            setPayload({
                attendance_id: data?.attendances?.id,
                no_attendance: data?.attendances?.no_attendance,
                company_id: data?.companies?.id,
                employee_id: data?.employees?.id,
                overtime_date: data?.overtime_date,
                time_start: data?.time_start,
                time_end: data?.time_end,
                remark: data?.remark
            });

            /**
             * Append attachments
             */
            if (data?.attachments !== undefined) {
                let _attachments = [];

                /**
                 * Anonymous function for convert UrlImage to Base64
                 */
                async function convertImageToBase64(imageUrl) {
                    const response = await fetch(imageUrl);
                    const blob = await response.blob();
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            resolve(reader.result);
                        };
                        reader.onerror = () => {
                            reject(new Error('Failed to convert Blob to Base64'));
                        };
                        reader.readAsDataURL(blob);
                    });
                }

                for (let i = 0; i < data.attachments.length; i++) {
                    const v = data.attachments[i];
                    if (v.file_url) {
                        const result = await convertImageToBase64(v.file_url);
                        _attachments.push({
                            id: v.id,
                            file_name: v.file_name,
                            file_source: result
                        });
                    }
                }

                setAttachments(_attachments);
            }

            /**
             * Get employee
             */
            await getEmployees(data?.company_id);
        } else {
            Alert.showMessage('Error', 'error', 'Data is not found');
            navigate(rootPath);
        }
    }

    useEffect(() => {
        async function __init() {
            Alert.showLoading();

            if (isSuperAdmin) await getCompanies();
            if (isCompanyAdmin) await getEmployees();
            if (!isSuperAdmin && !isCompanyAdmin && isCreate) await getLastPresence();

            if (!isCreate) await getDataById();
            Alert.close();
        }
        __init();
    }, []);


    /**
     * Action handlers
     */
    const updatePayload = async (field, value) => {
        let update = true;
        if (isSuperAdmin && field === 'company_id') {
            /**
             * Load employee when user logged as super_admin
             * Also field that changed is company_id
             */
            await getEmployees(value);
        } else if ((isSuperAdmin || isCompanyAdmin) && field === 'employee_id') {
            /**
             * Get last presence active from Service by employee_id
             */
            update = false;
            await getLastPresence(value);
        }
        if (update) setPayload({ ...payload, [field]: value });
    }

    const handleUpload = async (files) => {
        /**
         * Anonymous function for reading File using Promise
         */
        function __readFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
                reader.readAsDataURL(file);
            });
        };

        /**
         * Start uploading files
         */
        let _attachments = [];
        for (let i = 0; i < files.length; i++) {
            const result = await __readFile(files[i]);
            _attachments.push({
                id: null,
                file_name: '',
                file_source: result
            });
        }

        /**
         * Use state function to update state immediately
         */
        setAttachments(prev => {
            const updated = [...prev, ..._attachments];
            return updated;
        });
    }

    const changeMultiInput = (index, value) => {
        setAttachments(prev => {
            if (prev[index]?.file_name !== undefined) prev[index].file_name = value;
            return prev;
        });
    }

    const removeFile = (index) => {
        setAttachments(prev => {
            const updated = prev.filter((_, idx) => idx !== index);
            return updated;
        });
    }

    const submit = async () => {
        /**
         * Validate attendance first
         */
        if (!payload.attendance_id) return Alert.showMessage(`Warning`, `warning`, `This employee have no available presence yet`);

        /**
         * Validate input
         */
        const errMessages = validator.validate(payload);
        if (errMessages.length > 0) {
            let errMessageStrings = ``;
            for (let i = 0; i < errMessages.length; i++) {
                errMessageStrings += `${errMessages[i]}<br />`;
            }
            return Alert.showMessage(`Invalid input`, `warning`, errMessageStrings);
        }

        /**
         * Validate attachment file name
         * [In this case, the name is required]
         */
        let errFilenames = ``;
        for (let i = 0; i < attachments.length; i++) {
            const v = attachments[i];
            if (v?.file_name === '' || v?.file_name === undefined || v?.file_name === null) {
                errFilenames += `File name of image number ${i + 1} cannot be empty! <br />`;
            } else {
                const ext = v['file_source'].substring("data:image/".length, v['file_source'].indexOf(";base64"));

                /**
                 * Cleaning file_name
                 * [should update another validation later]
                 */
                let file_name = v.file_name.replace(/ /g, '_');

                attachments[i].file_name = `${file_name}.${ext}`;
            }
        }
        if (errFilenames) return Alert.showMessage(`Invalid input`, `warning`, errFilenames);

        /**
         * Submit action
         */
        let _payload = payload; // duplicate payload for validation update
        if (!isCreate) {
            /**
             * Means action for update
             */
            delete _payload.company_id;
            delete _payload.employee_id;
        }

        /**
         * Remove no_attendance attribute because this attribute only for label
         * Not for actual/transactional data
         */
        delete _payload.no_attendance;

        Alert.showConfirm({
            url: endpoint + (!isCreate ? `/${id}` : ``),
            method: isCreate ? 'POST' : 'PUT',
            data: {
                ..._payload,
                attachments
            },
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    let errMessage = '';
                    if (result?.value?.data?.error?.detail !== undefined) {
                        errMessage = result?.value?.data?.error?.detail;
                    } else {
                        const arrErr = result?.value?.data?.error != undefined ? result.value.data.error : [];
                        for (let i = 0; i < arrErr.length; i++) {
                            errMessage += `${arrErr[i].message} in file name ${arrErr[i].file_name}<br />`;
                        }
                    }

                    Alert.showMessage('Failure', 'error', errMessage);
                }
            }
        });
    }

    /**
     * Rendering DOM
     */
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <ol className="breadcrumb fs-sm mb-1">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/overtimes">List Overtimes</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Form {isCreate ? 'create' : 'edit'}
                        </li>
                    </ol>
                </div>
            </div>

            <Row className="g-3">
                <Col xl="12">
                    <Card className="card">
                        <Card.Header className="bg-primary">
                            <Card.Title
                                as="label"
                                className="fs-sm fw-medium mb-1 text-white"
                            >
                                Form {isCreate ? 'create' : 'edit'} overtime
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="bg-white">
                            <Link to={rootPath} className="btn btn-sm btn-warning text-white">
                                <i className="ri-arrow-left-line"></i> Back
                            </Link>
                            &nbsp;
                            <button className="btn btn-sm btn-primary" onClick={submit}>
                                <i className="ri-check-line"></i> Save
                            </button>
                            <br /><br />
                            <Row className="g-3">
                                {isSuperAdmin && <Col sm="4">
                                    <Components.Select
                                        name="company_id"
                                        label="Company"
                                        defaultValue={payload.company_id}
                                        disabled={!isCreate ? true : false}
                                        change={updatePayload}
                                        options={companies}
                                    />
                                </Col>}
                                {(isSuperAdmin || isCompanyAdmin) && <Col sm="4">
                                    <Components.Select
                                        name="employee_id"
                                        label="Employee"
                                        defaultValue={payload.employee_id}
                                        disabled={!isCreate ? true : false}
                                        change={updatePayload}
                                        options={employees}
                                    />
                                </Col>}
                                <Col sm="4">
                                    <Components.Input
                                        type="text"
                                        name="attendance_no"
                                        label="No. Attendance"
                                        defaultValue={payload.no_attendance}
                                        placeholder="automatically generated by system.."
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row className="g-3">
                                <Col sm="4">
                                    <Components.Input
                                        type="date"
                                        name="overtime_date"
                                        label="Overtime date"
                                        defaultValue={payload.overtime_date}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Input
                                        type="time"
                                        name="time_start"
                                        label="Time (IN)"
                                        defaultValue={payload.time_start}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Input
                                        type="time"
                                        name="time_end"
                                        label="Time (OUT)"
                                        defaultValue={payload.time_end}
                                        change={updatePayload}
                                    />
                                </Col>
                            </Row>
                            <Row className="g-3">
                                <Col sm="12">
                                    <Components.Input
                                        type="text"
                                        name="remark"
                                        label="Remark"
                                        placeholder="e.g: there is some troubles today, I need to fix it ASAP"
                                        defaultValue={payload.remark}
                                        change={updatePayload}
                                    />
                                </Col>
                            </Row>
                            <legend>Attachments</legend>
                            <FileUploader
                                handleChange={handleUpload}
                                name="file"
                                multiple={true}
                                maxSize={1}
                                types={["JPG", "PNG", "JPEG"]}
                            />
                            {attachments.map((item, index) => (
                                <Row key={index} className="g-3" style={{ marginTop: 10 }}>
                                    <Col sm="4">
                                        <img src={item?.file_source} className="img-thumbnail" />
                                    </Col>
                                    <Col sm="8">
                                        <label>File title</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            defaultValue={item?.file_name}
                                            onChange={(e) => changeMultiInput(index, e.target.value)}
                                            placeholder="e.g: Picture-of-issues"
                                            readOnly={item.id ? true : false}
                                        />
                                        <p className="text-danger" onClick={() => removeFile(index)} style={{ cursor: "pointer" }}>
                                            <i className="ri-delete-bin-line"></i> Remove this file
                                        </p>
                                    </Col>
                                </Row>
                            ))}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default OvertimeForm;