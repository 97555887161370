import { Card } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Grid, _ } from "gridjs-react";
import Rest from "./../../../../libraries/Rest";
import Alert from "../../../../libraries/Alert";
import { useEffect, useState } from "react";

function CompanyPositionList() {
    const { companyId } = useParams();
    const rest = new Rest();
    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState('');

    const endpoint = `/companies/positions/${companyId}`;
    const rootPath = `/companies/positions/${companyId}`;

    /**
     * Check existing company before do the rest
     */
    const getCompanyById = async () => {
        Alert.showLoading();
        const response = await rest.get(`/companies/${companyId}`);
        Alert.close();
        if (response?.data?.data === undefined) {
            Alert.showMessage('Error', 'error', 'Company is not found');
            navigate('/companies');
        } else {
            setCompanyName(response.data.data.name);
        }
    }

    useEffect(() => {
        getCompanyById();
    }, []);

    const updateItem = async (id) => {
        navigate(`${rootPath}/${id}`);
    }

    const removeItem = async (id) => {
        Alert.showConfirm({
            url: `${endpoint}/${id}`,
            method: 'DELETE',
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(`${rootPath}`)
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    return (
        <div>
            <Card className="bg-white">
                <Card.Header className="bg-primary">
                    <Card.Title as="label" className="fs-sm fw-medium mb-1 text-white">List positions for - {companyName}</Card.Title>
                </Card.Header>
                <Card.Body className="bg-white">
                    <Link to={`/companies`} className="btn btn-warning btn-sm text-white">
                        <i className="ri-arrow-left-line"></i> Back to company list
                    </Link>
                    &nbsp;
                    <Link to={`${rootPath}/add`} className="btn btn-primary btn-sm">
                        <i className="ri-add-line"></i> Create Data
                    </Link>
                    <br /><br />
                    <Grid
                        className={{ table: 'table table-bordered mb-0' }}
                        resizable={true}
                        language={{
                            search: {
                                placeholder: 'Find something..'
                            }
                        }}
                        columns={[
                            'Department',
                            'Code',
                            'Name',
                            'Description',
                            'Parent Name',
                            'Action',
                        ]}
                        server={{
                            url: `${rest.MAIN_HOST}${endpoint}`,
                            headers: {
                                'authorization': `Bearer ${rest.ACCESS_TOKEN}`
                            },
                            then: data => data?.data?.data?.map(r => [
                                r.department_name,
                                r.code,
                                r.name,
                                r.description,
                                r.parent_name ? r.parent_name : '-',
                                _(
                                    <div style={{ textAlign: 'center' }}>
                                        <button type="button" onClick={() => updateItem(r.id)} className="btn btn-info btn-sm text-white" title="Update data"><i className="ri-pencil-line"></i></button>
                                        &nbsp;
                                        <button type="button" onClick={() => removeItem(r.id)} className="btn btn-danger btn-sm" title="Remove data"><i className="ri-delete-bin-line"></i></button>
                                    </div>
                                )
                            ]),
                            total: data => data?.data?.total?.data,
                            handle: (res) => {
                                if (res.status === 401) return rest.interceptor();
                                return res.json();
                            }
                        }}
                        search={{
                            server: {
                                url: (prev, keyword) => `${prev}?keywords=${keyword}`
                            }
                        }}
                        pagination={{
                            limit: 10,
                            server: {
                                url: (prev, page, limit) => {
                                    const operator = prev.includes('?') ? '&' : '?';
                                    if (parseInt(page) <= 0) page = 1;
                                    return `${prev}${operator}limit=${limit}&page=${page}`
                                }
                            }
                        }}
                    />
                </Card.Body>
            </Card>
        </div>
    )

}

export default CompanyPositionList;