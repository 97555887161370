import { Link } from "react-router-dom";

/**
 * Load dashboard components
 */
import AdminDashboard from "./dashboards/AdminDashboard";
import CompanyDashboard from "./dashboards/CompanyDashboard";
import EmployeeDashboard from "./dashboards/EmployeeDashboard";
import LocalStorage from "../libraries/LocalStorage";

function Dashboard() {

    const localStorage = new LocalStorage();
    const roleName = localStorage.getItem('role_name');

    const renderContent = () => {
        if (roleName === 'super_admin') return <AdminDashboard />
        if (roleName === 'company_admin') return <CompanyDashboard />
        if (roleName === 'employee') return <EmployeeDashboard />
        return <></>
    }

    /**
     * Render view
     */
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <ol className="breadcrumb fs-sm mb-1">
                        <li className="breadcrumb-item active" aria-current="page">
                            Dashboard for {roleName?.replace(/_/g, ' ')}
                        </li>
                    </ol>
                </div>
            </div>

            {renderContent()}
        </div >
    )
}

export default Dashboard;