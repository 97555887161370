import { Card, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import Rest from "../../../../libraries/Rest";
import Alert from "../../../../libraries/Alert";
import * as Components from "../../../../components/Components";
import Validator from "../../../../libraries/Validator";

function EmployeeSalaryComponentForm() {
    const { employeeId, id } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const rest = new Rest();
    const [salaryComponents, setSalaryComponents] = useState([]);

    const isCreate = id?.toLowerCase() === 'add' ? true : false;

    /**
     * Init validator of payload
     */
    const validator = new Validator({
        salary_component_id: { required: true },
        value_amount: { required: true }
    });

    /**
     * Init states
     */
    const rootPath = `/employees/salary_components/${employeeId}`;
    const endpoint = `/employees/salary_components/${employeeId}`;

    let [payload, setPayload] = useState({
        salary_component_id: null,
        value_amount: null,
        type: null,
        remark: null
    });

    /**
     * First init function
     */
    const getSalaryComponents = async () => {
        const companyId = searchParams.get("companyId");
        if (companyId) {
            const response = await rest.get(`/companies/salary_components/${companyId}?page=1&limit=100`);
            if (response?.data?.data?.data !== undefined) {
                const data = response.data.data.data;
                let options = [];

                for (let i = 0; i < data.length; i++) {
                    options.push({
                        label: data[i]?.name,
                        value: data[i]?.id,
                    })
                }
                setSalaryComponents(options);
            }
        }
    }

    const getDataById = async () => {
        const response = await rest.get(`${endpoint}/${id}`);
        if (response?.data?.data !== undefined) {
            const data = response.data.data;
            setPayload({
                salary_component_id: data?.salary_component_id,
                value_amount: data?.value_amount,
                type: data?.type,
                remark: data?.remark
            });
        } else {
            Alert.showMessage('Error', 'error', 'Data is not found');
            navigate(rootPath);
        }
    }

    useEffect(() => {
        async function __init() {
            Alert.showLoading();
            await getSalaryComponents();
            if (!isCreate) await getDataById();
            Alert.close();
        }
        __init();
    }, []);


    /**
     * Action handlers
     */
    const updatePayload = (field, value) => {
        setPayload({ ...payload, [field]: value });
    }

    const submit = async () => {
        /**
         * Validate input
         */
        const errMessages = validator.validate(payload);
        if (errMessages.length > 0) {
            let errMessageStrings = ``;
            for (let i = 0; i < errMessages.length; i++) {
                errMessageStrings += `${errMessages[i]}<br />`;
            }
            return Alert.showMessage(`Invalid input`, `warning`, errMessageStrings);
        }

        /**
         * Submit action
         */
        Alert.showConfirm({
            url: endpoint + (!isCreate ? `/${id}` : ``),
            method: isCreate ? 'POST' : 'PUT',
            data: payload,
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    /**
     * Rendering DOM
     */
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <ol className="breadcrumb fs-sm mb-1">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/employees">List employees</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={rootPath}>List salary components</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Form {isCreate ? 'create' : 'edit'}
                        </li>
                    </ol>
                </div>
            </div>

            <Row className="g-3">
                <Col xl="12">
                    <Card className="card">
                        <Card.Header className="bg-primary">
                            <Card.Title
                                as="label"
                                className="fs-sm fw-medium mb-1 text-white"
                            >
                                Form {isCreate ? 'create' : 'edit'} salary component
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="bg-white">
                            <Link to={rootPath} className="btn btn-sm btn-warning text-white">
                                <i className="ri-arrow-left-line"></i> Back
                            </Link>
                            &nbsp;
                            <button className="btn btn-sm btn-primary" onClick={submit}>
                                <i className="ri-check-line"></i> Save
                            </button>
                            <br /><br />
                            <Row className="g-3">
                                <Col sm="6">
                                    <Components.Select
                                        name="salary_component_id"
                                        label="Salary Component"
                                        defaultValue={payload.salary_component_id}
                                        change={updatePayload}
                                        options={salaryComponents}
                                    />
                                </Col>
                                <Col sm="3">
                                    <Components.Input
                                        type="number"
                                        name="value_amount"
                                        label="Total"
                                        placeholder="eg. 1000000"
                                        defaultValue={payload.value_amount}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="3">
                                    <Components.Select
                                        name="type"
                                        label="Type"
                                        defaultValue={payload.type}
                                        change={updatePayload}
                                        options={[
                                            {
                                                label: 'Monthly',
                                                value: 'T1'
                                            }, {
                                                label: 'Daily',
                                                value: 'T2'
                                            }
                                        ]}
                                    />
                                </Col>
                                <Col sm="12">
                                    <Components.Input
                                        type="text"
                                        name="remark"
                                        label="Remark"
                                        placeholder="eg. Tunjangan makan"
                                        defaultValue={payload.remark}
                                        change={updatePayload}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default EmployeeSalaryComponentForm;