import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Card, Col, Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Rest from "../../libraries/Rest";
import Alert from "../../libraries/Alert";

function WorkflowSubmission() {
    const rest = new Rest();

    /**
     * State for DOMs validations
     */
    const [showContent, setShowContent] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [emailActive, setEmailActive] = useState(null);
    const [loadingList, setLoadingList] = useState(false);
    const [loading, setLoading] = useState(false);

    /**
     * State for modal preview
     */
    const [show, setShow] = useState(false);
    const closePreviewModal = () => setShow(false);
    const showPreviewModal = () => setShow(true);

    /**
     * State for submissions
     */
    const [submissions, setSubmissions] = useState([]);
    const [detailSubmission, setDetailSubmission] = useState({});
    const [imgPreview, setImgPreview] = useState(null);

    useEffect(() => {

        async function __init() {
            /**
             * Get submissions
             */
            setLoadingList(true);
            const url = `/workflows/your_submission`;
            const response = await rest.get(url);

            let dataSubmissions = [
                {
                    date: "Overall",
                    emails: []
                }
            ];
            if (response?.data?.data !== undefined) {
                for (let i = 0; i < response.data.data.length; i++) {
                    const v = response.data.data[i];
                    let note = null;

                    if (v.type?.toLowerCase() === 'leaves') {
                        if (v?.payloads?.details?.reason !== undefined) note = v.payloads.details.reason;
                    } else if (v.type?.toLowerCase() === 'overtimes') {
                        if (v?.payloads?.details?.remark !== undefined) note = v.payloads.details.remark;
                    }

                    /**
                     * Validate background sender
                     */
                    let bgSender = 'text-primary';
                    if (v?.status_text?.toLowerCase() === 'rejected') bgSender = 'text-danger';
                    if (v?.status_text?.toLowerCase() === 'approved') bgSender = 'text-success';

                    /**
                     * Push data submissions to state preparation
                     */
                    dataSubmissions[0].emails.push({
                        id: v.id,
                        unread: true,
                        initial: {
                            letter: v.type?.toLowerCase() === 'leaves' ? 'L' : 'O',
                            bg: v.type?.toLowerCase() === 'leaves' ? 'teal' : 'primary',
                        },
                        sender: v.status_text,
                        bgSender: bgSender,
                        time: v.created_at,
                        subject: `Submission: ${v.type}`,
                        summary: note ? note.substring(0, 100) + '..' : ''
                    })
                }
            }

            /**
             * Send it to state
             */
            setLoadingList(false);
            setSubmissions(dataSubmissions);
        }
        __init();
    }, []);

    const getDetail = async (id) => {
        /**
         * Validate DOMs state
         */
        setLoading(true);
        setShowContent(false);
        setShowSidebar(true);

        /**
         * Getting data detail
         */
        let url = `/workflows/detail_submission/${id}`;
        const response = await rest.get(url);

        if (response?.data?.data !== undefined) {
            setShowContent(true);
            setShowSidebar(false);

            const v = response.data.data;

            /**
             * Validate background sender
             */
            let bg_status = 'text-primary';
            if (v?.status_text?.toLowerCase() === 'rejected') bg_status = 'text-danger';
            if (v?.status_text?.toLowerCase() === 'approved') bg_status = 'text-success';

            /**
             * Send response detail to state
             */
            const _detail = {
                initial: {
                    letter: v?.types?.toLowerCase() === 'leaves' ? 'L' : 'O',
                    bg: v?.types?.toLowerCase() === 'leaves' ? 'teal' : 'primary'
                },
                title: `Submissions: ${v?.type}`,
                status_text: v?.status_text ? v.status_text : 'Pending',
                bg_status,
                current_position: v?.current_position ? v?.current_position?.replace(/_/g, ' ') : 'PIC Branch',
                subject: v?.no_worklist,
                created_at: v?.created_at,
                reason: v?.payloads?.details?.reason !== undefined ? v?.payloads?.details?.reason : null,
                remark: v?.payloads?.details?.remark !== undefined ? v?.payloads?.details?.remark : null,
                payloads: v?.payloads?.details !== undefined ? v.payloads.details : {},
                histories: v?.histories !== undefined ? v.histories : [],
                attachments: v?.attachments !== undefined ? v.attachments : []
            }
            setDetailSubmission(_detail);
        } else {
            Alert.showMessage('Warning', 'warning', 'Data is not found');
        }
        setLoading(false);
    }

    const preview = (file_url) => {
        showPreviewModal();
        setImgPreview(file_url);
    }

    return (
        <React.Fragment>
            <div>
                <PerfectScrollbar className="email-list">
                    <div className="email-list-header pe-3">
                        <Link href="" className="email-menu" onClick={() => setShowSidebar(!showSidebar)}>
                            <i className="ri-arrow-left-line"></i>
                        </Link>
                        <h5 className="mb-0">Your submissions</h5>
                    </div>

                    {loadingList && <p style={{ textAlign: "center" }}>Loading..</p>}
                    {!loadingList && submissions[0]?.emails?.length <= 0 && <p style={{ textAlign: "center" }}>No data</p>}

                    {submissions.map((group, index) => (
                        <React.Fragment key={index}>
                            {group.emails.map((email, ind) => (
                                <div
                                    className={"email-item" + (email.unread ? " unread" : "") + ((emailActive === (index.toString() + ind.toString())) ? " selected" : "")}
                                    key={index.toString() + ind.toString()}
                                    onClick={async (e) => {
                                        setEmailActive(index.toString() + ind.toString());
                                        await getDetail(email.id);
                                    }}
                                >
                                    <div className="avatar">
                                        {email.initial && <span className={"avatar-initial bg-" + email.initial.bg}>{email.initial.letter}</span>}
                                    </div>
                                    <div className="email-item-body">
                                        <div className="d-flex align-items-center mb-1">
                                            <span
                                                style={{ textDecoration: 'underline' }}
                                                className={"email-item-sender " + email.bgSender}
                                            >
                                                {email.sender}
                                            </span>
                                            <span className="email-item-date">{email.time}</span>
                                        </div>
                                        <h6 className="email-item-subject">{email.subject}</h6>
                                        <p className="email-item-summary">{email.summary}</p>
                                    </div>
                                </div>
                            ))}
                        </React.Fragment>
                    ))}
                </PerfectScrollbar>

                <PerfectScrollbar className={"email-content" + (showContent ? " show" : "")}>
                    <div className="email-content-empty">
                        {loading ? "Loading.." : "No email has been selected"}
                    </div>

                    {!loading && Object.keys(detailSubmission).length > 0 && <div className="email-content-body p-4">
                        <div className="email-msg-item">
                            <div className="d-flex align-items-center fs-sm mb-4">
                                <div className="avatar me-3">
                                    <span className={"avatar-initial bg-" + detailSubmission?.initial?.bg}>{detailSubmission?.initial?.letter}</span>
                                </div>
                                <div className="me-auto">
                                    <p className="mb-0">
                                        <strong>{detailSubmission?.title}</strong>
                                    </p>
                                    <p className="mb-0">
                                        <span className="text-secondary">Current Position: </span>
                                        <strong style={{ textTransform: 'capitalize' }}>{detailSubmission?.current_position}</strong>
                                    </p>
                                    <p className="mb-0">
                                        <span className="text-secondary">Submission Status: </span>
                                        <strong style={{ textDecoration: 'underline' }} className={detailSubmission?.bg_status}>{detailSubmission?.status_text}</strong>
                                    </p>
                                </div>
                                <span className="fs-xs text-secondary">{detailSubmission?.created_at}</span>
                            </div>

                            <div>
                                <h4 className="text-dark mb-4">{detailSubmission?.subject}</h4>

                                {/* Show reason if exists */}
                                {detailSubmission?.reason && <p>
                                    <strong>Reason:</strong><br />
                                    {detailSubmission.reason}
                                </p>}

                                {/* Show remark */}
                                <p>
                                    <strong>Remark:</strong><br />
                                    {detailSubmission?.remark}
                                </p>

                                <Row className="mt-3">
                                    <Col sm="5">
                                        {/* Show each data inside payloads */}
                                        <p>
                                            <strong>Details:</strong><br />
                                        </p>
                                        <table className="table table-condensed">
                                            <tbody>
                                                {Object.keys(detailSubmission?.payloads).map((v, i) => {
                                                    const label = v.replace(/_/g, ' ');
                                                    if ([
                                                        'remark', 'reason',
                                                        'id', 'created_at',
                                                        'attendance_id'
                                                    ].includes(v)) return null; // don't show these fields

                                                    return (
                                                        <tr key={i}>
                                                            <td style={{ textTransform: 'capitalize' }}>{label}</td>
                                                            <td>: {detailSubmission?.payloads[v]}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col sm="7">
                                        {/* Submissions histories */}
                                        <p>
                                            <strong>Your submission journeys:</strong><br />
                                        </p>
                                        {detailSubmission.histories.length <= 0 && <center className="text-grey" style={{ fontStyle: 'italic' }}>-- No history --</center>}

                                        {detailSubmission.histories.map((v, i) => {
                                            return (
                                                <div key={i}>
                                                    <p className="mb-0">
                                                        <strong>
                                                            <i className="ri-time-line"></i> {v.created_at}
                                                        </strong>
                                                    </p>
                                                    <p style={{ textTransform: 'capitalize' }} className="mb-0">
                                                        Current Step: <strong>{v.current_step?.replace(/_/g, ' ')}</strong>
                                                    </p>
                                                    <p style={{ textTransform: 'capitalize' }} className="mb-0">
                                                        Next Step: {v.next_step ? <strong>{v.next_step?.replace(/_/g, ' ')}</strong> : '-'}
                                                    </p>
                                                    <p style={{ textTransform: 'capitalize' }}>
                                                        Status: <strong style={{ textDecoration: 'underline' }} className={v?.status_text?.toLowerCase() === 'rejected' ? 'text-danger' : 'text-success'}>{v?.status_text}</strong>
                                                    </p>

                                                    <p>
                                                        <strong>Remark:</strong><br />
                                                        {v?.remark}
                                                    </p>
                                                    <hr className="my-4" />
                                                </div>
                                            )
                                        })}
                                    </Col>
                                </Row>

                                <hr className="my-4" />

                                <label className="section-label mb-2">{detailSubmission?.attachments?.length} Attachment(s)</label>
                                {detailSubmission?.attachments !== undefined && <Row className="g-3 row-cols-auto">
                                    {detailSubmission.attachments.map((v, i) => {
                                        return (
                                            <Col key={i} sm="6">
                                                <Card className="card-attachment">
                                                    <Card.Body>
                                                        <div className="card-icon primary">
                                                            <i className="ri-image-line"></i>
                                                        </div>
                                                        <div className="card-content">
                                                            <h6>
                                                                <Link to="#" onClick={() => preview(v?.file_url)}>
                                                                    {v?.file_name}
                                                                </Link>
                                                            </h6>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                    })}
                                </Row>}

                                {/* Modal preview image */}
                                <Modal show={show} onHide={closePreviewModal} size="md">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Preview Image</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {imgPreview && <img src={imgPreview} className="img-thumbnail" />}
                                    </Modal.Body>
                                </Modal>

                            </div>
                        </div>
                    </div>}
                </PerfectScrollbar>
            </div>
        </React.Fragment>
    )

}

export default WorkflowSubmission;