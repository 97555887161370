import { Card, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Rest from "../../../../libraries/Rest";
import Alert from "../../../../libraries/Alert";
import * as Components from "../../../../components/Components";
import Validator from "../../../../libraries/Validator";

function CompanyBranchForm() {
    const { companyId, id } = useParams();
    const navigate = useNavigate();
    const rest = new Rest();

    const isCreate = id?.toLowerCase() === 'add' ? true : false;

    /**
     * Init validator of payload
     */
    const validator = new Validator({
        pic_id: { required: true },
        name: { required: true },
        address: { required: true }
    });

    /**
     * Init states
     */
    const rootPath = `/companies/branches/${companyId}`;
    const endpoint = `/companies/branches/${companyId}`;

    let [payload, setPayload] = useState({
        pic_id: null,
        name: null,
        address: null,
        phone: null,
        long: null,
        lat: null,
        radius: null,
        is_active: false,
    });
    let [employees, setEmployees] = useState([]);

    /**
     * First init function
     */
    const getEmployees = async () => {
        const response = await rest.get(`/employees?page=1&limit=1000&orderBy=first_name:asc&companyId=${companyId}`);
        if (response?.data?.data?.data !== undefined) {
            const data = response.data.data.data;

            /**
             * Generate options from employee response
             */
            let options = [];
            for (let i = 0; i < data.length; i++) {
                options.push({
                    label: `${data[i].first_name} ${data[i].middle_name} (${data[i]?.nik})`,
                    value: data[i].id
                });
            }
            setEmployees(options);
        }
    }

    const getDataById = async () => {
        const response = await rest.get(`${endpoint}/${id}`);
        if (response?.data?.data !== undefined) {
            const data = response.data.data;
            setPayload({
                pic_id: data?.pic_id,
                name: data?.name,
                address: data?.address,
                phone: data?.phone,
                long: data?.long,
                lat: data?.lat,
                radius: data?.radius,
                is_active: data?.is_active === true ? 'true' : 'false',
            });
        } else {
            Alert.showMessage('Error', 'error', 'Data is not found');
            navigate(rootPath);
        }
    }

    useEffect(() => {
        async function __init() {
            Alert.showLoading();
            await getEmployees();
            if (!isCreate) await getDataById();
            Alert.close();
        }
        __init();
    }, []);


    /**
     * Action handlers
     */
    const updatePayload = (field, value) => {
        setPayload({ ...payload, [field]: value });
    }

    const submit = async () => {
        /**
         * Validate input
         */
        const errMessages = validator.validate(payload);
        if (errMessages.length > 0) {
            let errMessageStrings = ``;
            for (let i = 0; i < errMessages.length; i++) {
                errMessageStrings += `${errMessages[i]}<br />`;
            }
            return Alert.showMessage(`Invalid input`, `warning`, errMessageStrings);
        }

        /**
         * Update necessary payload
         */
        payload.is_active = payload.is_active === 'true' ? true : false;

        /**
         * Submit action
         */
        Alert.showConfirm({
            url: endpoint + (!isCreate ? `/${id}` : ``),
            method: isCreate ? 'POST' : 'PUT',
            data: payload,
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    /**
     * Rendering DOM
     */
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <ol className="breadcrumb fs-sm mb-1">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/companies">List companies</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={rootPath}>List branches</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Form {isCreate ? 'create' : 'edit'}
                        </li>
                    </ol>
                </div>
            </div>

            <Row className="g-3">
                <Col xl="12">
                    <Card className="card">
                        <Card.Header className="bg-primary">
                            <Card.Title
                                as="label"
                                className="fs-sm fw-medium mb-1 text-white"
                            >
                                Form {isCreate ? 'create' : 'edit'} branch
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="bg-white">
                            <Link to={rootPath} className="btn btn-sm btn-warning text-white">
                                <i className="ri-arrow-left-line"></i> Back
                            </Link>
                            &nbsp;
                            <button className="btn btn-sm btn-primary" onClick={submit}>
                                <i className="ri-check-line"></i> Save
                            </button>
                            <br /><br />
                            <Row className="g-3">
                                <Col sm="4">
                                    <Components.Input
                                        type="text"
                                        name="name"
                                        label="Branch name*"
                                        placeholder="e.g: Cabang pusat"
                                        defaultValue={payload.name}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Input
                                        type="text"
                                        name="phone"
                                        label="Branch phone"
                                        placeholder="e.g: 0812xxx"
                                        defaultValue={payload.phone}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Select
                                        name="pic_id"
                                        label="PIC Branch*"
                                        defaultValue={payload.pic_id}
                                        change={updatePayload}
                                        options={employees}
                                    />
                                </Col>
                                <Col sm="10">
                                    <Components.Input
                                        type="text"
                                        name="address"
                                        label="Branch address*"
                                        placeholder="Branch address"
                                        defaultValue={payload.address}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="2">
                                    <Components.Select
                                        name="is_active"
                                        label="Status Active"
                                        defaultValue={payload.is_active}
                                        change={updatePayload}
                                        options={[
                                            {
                                                value: 'false',
                                                label: 'No'
                                            }, {
                                                value: 'true',
                                                label: 'Yes'
                                            }
                                        ]}
                                        noInitOption={true}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Input
                                        type="text"
                                        name="long"
                                        label="Longitude"
                                        placeholder="e.g: 987766123"
                                        defaultValue={payload.long}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Input
                                        type="text"
                                        name="lat"
                                        label="Latitude"
                                        placeholder="e.g: -98788311"
                                        defaultValue={payload.lat}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Input
                                        type="number"
                                        name="radius"
                                        label="Radius (Meter)"
                                        placeholder="e.g: 200"
                                        defaultValue={payload.radius}
                                        change={updatePayload}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default CompanyBranchForm;