import { Card, Col, Row } from "react-bootstrap";
import { useState } from "react";

import Alert from "../../libraries/Alert";
import Rest from "../../libraries/Rest";

function Window() {
    const rest = new Rest();

    const [payload, setPayload] = useState({
        user_input: '',
        results: []
    });

    const submit = async () => {
        Alert.showLoading();
        let body = {
            user_input: payload.user_input
        };
        const response = await rest.post(`/search_engine`, body);
        Alert.close();

        if (response?.data?.data?.results !== undefined) {
            const data = response.data.data.results;

            let r = [];
            for (let i = 0; i < data.length; i++) {
                let code = null;

                if (data[i]?.no_attendance !== undefined) code = data[i].no_attendance;
                if (data[i]?.no_leave !== undefined) code = data[i].no_leave;
                if (data[i]?.no_overtime !== undefined) code = data[i].no_overtime;

                r.push({
                    nik: data[i]?.employee_id,
                    created_at: data[i]?.created_at,
                    status: data[i]?.status,
                    code,
                });
            }
            setPayload({ ...payload, results: r });

            /**
             * Populating data
             */
        } else {
            Alert.showMessage('Sorry', 'warning', 'Engine cannot what your looking for:(');
        }
    }

    return (
        <div>
            <Row className="g-3">
                <Col xl="12">
                    <Card className="card">
                        <Card.Header className="bg-primary">
                            <Card.Title
                                as="label"
                                className="fs-sm fw-medium mb-1 text-white"
                            >
                                (beta) Search Engine
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="bg-white">
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="What are you looking for?"
                                    onChange={(e) => setPayload({ ...payload, user_input: e.target.value })}
                                /><br />
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={submit}
                                >
                                    <i className="ri-search-line"></i> Search
                                </button>
                            </div>

                            <hr />
                            {payload.results && payload.results.length > 0 && <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>ID Employee</th>
                                        <th>Code Trx</th>
                                        <th>Status</th>
                                        <th>Created</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {payload.results.map((v, i) => {
                                        return (
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{v?.nik}</td>
                                                <td>{v?.code}</td>
                                                <td>{v?.status}</td>
                                                <td>{v?.created_at}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>}

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Window;