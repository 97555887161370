import { Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Grid, _ } from "gridjs-react";
import Rest from "./../../libraries/Rest";
import { useEffect, useState } from "react";
import LocalStorage from "./../../libraries/LocalStorage";

import * as Components from "./../../components/Components";

function AttendanceList() {
    const rest = new Rest();
    const localStorage = new LocalStorage();
    const roleName = localStorage.getItem('role_name');
    const allowedRole = 'super_admin'; // static role validation (code: VL01)

    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]);
    const [companyId, setCompanyId] = useState();

    const endpoint = `/attendances`;
    const rootPath = `/attendances`;

    const getCompanies = async () => {
        let res = await rest.get(`/companies?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let options = [];
            for (let i = 0; i < res.length; i++) {
                options.push({
                    value: res[i]['id'] !== undefined ? res[i]['id'] : null,
                    label: res[i]['name'] !== undefined ? res[i]['name'] : null,
                });
            }
            setCompanies(options);
        }
    }

    const updatePayload = async (_, value) => {
        setCompanyId(value);
    }

    useEffect(() => {
        if (roleName?.toLowerCase() === allowedRole) getCompanies(); // static role validation (code: VL01)
    }, []);

    const detailItem = async (id) => {
        navigate(`${rootPath}/${id}`);
    }

    return (
        <div>
            <Card className="bg-white">
                <Card.Header className="bg-primary">
                    <Card.Title as="label" className="fs-sm fw-medium mb-1 text-white">
                        List Attendances
                    </Card.Title>
                </Card.Header>
                <Card.Body className="bg-white">
                    {roleName?.toLowerCase() === allowedRole && <Row>
                        <Col sm="3">
                            <Components.Select
                                label="Company"
                                name="companyId"
                                defaultValue={companyId}
                                change={updatePayload}
                                options={companies}
                            />
                        </Col>
                    </Row>}
                    <Grid
                        className={{ table: 'table table-bordered mb-0' }}
                        resizable={true}
                        language={{
                            search: {
                                placeholder: 'Find something..'
                            }
                        }}
                        columns={[
                            'No. Attendance',
                            'ID Employee',
                            'Name',
                            'Date-Time (IN)',
                            'Date-Time (OUT)',
                            'Detail',
                        ]}
                        server={{
                            url: `${rest.MAIN_HOST}${endpoint}${companyId !== '' && companyId !== null && companyId !== undefined ? '?companyId=' + companyId : ''}`,
                            headers: {
                                'authorization': `Bearer ${rest.ACCESS_TOKEN}`
                            },
                            then: data => data?.data?.data?.map(r => [
                                r.no_attendance,
                                r?.employees?.nik,
                                `${r?.employees?.first_name} ${r?.employees?.middle_name} ${r?.employees?.last_name}`,
                                `${r.date_in ?? ''} ${(r.time_in ? r.time_in : '-none-')}`,
                                `${r.date_out ?? ''} ${(r.time_out ? r.time_out : '-none-')}`,
                                _(
                                    <div style={{ textAlign: 'center' }}>
                                        <button type="button" onClick={() => detailItem(r.id)} className="btn btn-info btn-sm text-white" title="Detail data"><i className="ri-eye-line"></i></button>
                                    </div>
                                )
                            ]),
                            total: data => data?.data?.total?.data,
                            handle: (res) => {
                                if (res.status === 401) return rest.interceptor();
                                return res.json();
                            }
                        }}
                        search={{
                            server: {
                                url: (prev, keyword) => `${prev}?keywords=${keyword}`
                            }
                        }}
                        pagination={{
                            limit: 10,
                            server: {
                                url: (prev, page, limit) => {
                                    const operator = prev.includes('?') ? '&' : '?';
                                    if (parseInt(page) <= 0) page = 1;
                                    return `${prev}${operator}limit=${limit}&page=${page}`
                                }
                            }
                        }}
                    />
                </Card.Body>
            </Card>
        </div>
    )

}

export default AttendanceList;