import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';

/**
 * Import templates
 */
import "./assets/css/remixicon.css";
import "./scss/style.scss";

/**
 * Import middlewares
 */
import * as Middleware from './middlewares/Core';
/**
 * Import layouts
 */
import Layout from './layouts/base/Layout';
/**
 * Import error handlers
 */
import NotFound from './handlers/NotFound';
import Unauthorized from './handlers/Unauthorized';

/**
 * Import all pages right here
 */
import Login from './pages/auths/Login';
import Dashboard from './pages/Dashboard';
import UserList from './pages/masters/users/List';
import UserForm from './pages/masters/users/Form';

/**
 * Import company attributes pages
 */
import CompanyBranchList from './pages/masters/companies/branches/List';
import CompanyBranchForm from './pages/masters/companies/branches/Form';
import CompanyDepartmentList from './pages/masters/companies/departments/List';
import CompanyDepartmentForm from './pages/masters/companies/departments/Form';
import CompanyPositionList from './pages/masters/companies/positions/List';
import CompanyPositionForm from './pages/masters/companies/positions/Form';
import CompanyEmployeeTypeList from './pages/masters/companies/employee_types/List';
import CompanyEmployeeTypeForm from './pages/masters/companies/employee_types/Form';
import CompanyBankList from './pages/masters/companies/banks/List';
import CompanyBankForm from './pages/masters/companies/banks/Form';
import CompanySalaryComponentList from './pages/masters/companies/salary_components/List';
import CompanySalaryComponentForm from './pages/masters/companies/salary_components/Form';
import CompanyEventList from './pages/masters/companies/events/List';
import CompanyEventForm from './pages/masters/companies/events/Form';
import CompanyShiftList from './pages/masters/companies/shifts/List';
import CompanyShiftForm from './pages/masters/companies/shifts/Form';
import CompanySettingForm from './pages/masters/companies/settings/Form';
import CompanyList from './pages/masters/companies/List';
import CompanyForm from './pages/masters/companies/Form';

/**
 * Import employee pages
 */
import EmployeeList from './pages/masters/employees/List';
import EmployeeForm from './pages/masters/employees/Form';
import EmployeeShiftList from './pages/masters/employees/shifts/List';
import EmployeeShiftForm from './pages/masters/employees/shifts/Form';
import EmployeeSalaryComponentList from './pages/masters/employees/salary_components/List';
import EmployeeSalaryComponentForm from './pages/masters/employees/salary_components/Form';

/**
 * Import attendance pages
 */
import AttendanceList from './pages/attendances/List';
import AttendanceDetail from './pages/attendances/Detail';

/**
 * Import leave pages
 */
import LeaveList from './pages/leaves/List';
import LeaveForm from './pages/leaves/Form';
import LeaveDetail from './pages/leaves/Detail';

/**
 * Import overtime pages
 */
import OvertimeList from './pages/overtimes/List';
import OvertimeForm from './pages/overtimes/Form';
import OvertimeDetail from './pages/overtimes/Detail';

/**
 * Import workflow pages
 */
import WorkflowSubmission from './pages/workflows/Submission';
import WorkflowApproval from './pages/workflows/Approval';

/**
 * Import payroll pages
 */
import PayrollList from './pages/payrolls/List';
import PayrollForm from './pages/payrolls/Form';
import PayrollDetail from './pages/payrolls/Detail';

/**
 * Import inbox pages
 */
import InboxList from './pages/inbox/List';

/**
 * Import report pages
 */
import ReportAttendances from './pages/reports/Attendances';
import ReportEmployeeAttendances from './pages/reports/EmployeeAttendances';
import ReportLeaves from './pages/reports/Leaves';
import ReportOvertimes from './pages/reports/Overtimes';
import ReportEmployees from './pages/reports/Employees';

/**
 * Import profile pages
 */
import Me from './pages/profiles/Me';
import ChangePassword from './pages/profiles/ChangePassword';

/**
 * Import complaints pages
 */
import ComplaintList from './pages/complaints/List';
import ComplaintDetail from './pages/complaints/Detail';

/**
 * Import nlp engines
 */
import NlpEngine from './pages/nlps/Window';

/**
 * Initiate auth routes
 */
const authRoutes = [
    {
        path: '/login',
        element: <Login />,
    }
];

/**
 * Intitate dashboard routes
 */
const dashboardRoutes = [
    {
        element: <Layout />,
        children: [
            {
                path: '/',
                element: <Middleware.Authenticated render={<Dashboard />} />
            },
            {
                path: '/dashboard',
                element: <Middleware.Authenticated render={<Dashboard />} />
            }
        ]
    }
];

/**
 * Intitate master routes
 */
const masterRoutes = [
    {
        element: <Layout />,
        children: [
            {
                path: '/users',
                element: <Middleware.Authenticated render={<UserList />} />
            }, {
                path: '/users/:id?',
                element: <Middleware.Authenticated render={<UserForm />} />
            }, {
                path: '/companies/branches/:companyId',
                element: <Middleware.Authenticated render={<CompanyBranchList />} />
            }, {
                path: '/companies/branches/:companyId/:id?',
                element: <Middleware.Authenticated render={<CompanyBranchForm />} />
            }, {
                path: '/companies/departments/:companyId',
                element: <Middleware.Authenticated render={<CompanyDepartmentList />} />
            }, {
                path: '/companies/departments/:companyId/:id?',
                element: <Middleware.Authenticated render={<CompanyDepartmentForm />} />
            }, {
                path: '/companies/positions/:companyId',
                element: <Middleware.Authenticated render={<CompanyPositionList />} />
            }, {
                path: '/companies/positions/:companyId/:id?',
                element: <Middleware.Authenticated render={<CompanyPositionForm />} />
            }, {
                path: '/companies/employee_types/:companyId',
                element: <Middleware.Authenticated render={<CompanyEmployeeTypeList />} />
            }, {
                path: '/companies/employee_types/:companyId/:id?',
                element: <Middleware.Authenticated render={<CompanyEmployeeTypeForm />} />
            }, {
                path: '/companies/banks/:companyId',
                element: <Middleware.Authenticated render={<CompanyBankList />} />
            }, {
                path: '/companies/banks/:companyId/:id?',
                element: <Middleware.Authenticated render={<CompanyBankForm />} />
            }, {
                path: '/companies/events/:companyId',
                element: <Middleware.Authenticated render={<CompanyEventList />} />
            }, {
                path: '/companies/events/:companyId/:id?',
                element: <Middleware.Authenticated render={<CompanyEventForm />} />
            }, {
                path: '/companies/settings/:companyId',
                element: <Middleware.Authenticated render={<CompanySettingForm />} />
            }, {
                path: '/companies/salary_components/:companyId',
                element: <Middleware.Authenticated render={<CompanySalaryComponentList />} />
            }, {
                path: '/companies/salary_components/:companyId/:id?',
                element: <Middleware.Authenticated render={<CompanySalaryComponentForm />} />
            }, {
                path: '/companies/shifts/:companyId',
                element: <Middleware.Authenticated render={<CompanyShiftList />} />
            }, {
                path: '/companies/shifts/:companyId/:id?',
                element: <Middleware.Authenticated render={<CompanyShiftForm />} />
            }, {
                path: '/companies',
                element: <Middleware.Authenticated render={<CompanyList />} />
            }, {
                path: '/companies/:id?',
                element: <Middleware.Authenticated render={<CompanyForm />} />
            }, {
                path: '/employees',
                element: <Middleware.Authenticated render={<EmployeeList />} />
            }, {
                path: '/employees/:id?',
                element: <Middleware.Authenticated render={<EmployeeForm />} />
            }, {
                path: '/employees/salary_components/:employeeId',
                element: <Middleware.Authenticated render={<EmployeeSalaryComponentList />} />
            }, {
                path: '/employees/salary_components/:employeeId/:id?',
                element: <Middleware.Authenticated render={<EmployeeSalaryComponentForm />} />
            }, {
                path: '/employee_shifts',
                element: <Middleware.Authenticated render={<EmployeeShiftList />} />
            }, {
                path: '/employee_shifts/:id?/:employeeId?',
                element: <Middleware.Authenticated render={<EmployeeShiftForm />} />
            }
        ]
    }
];

/**
 * Initiate attendance routes
 */
const attendanceRoutes = [
    {
        element: <Layout />,
        children: [
            {
                path: '/attendances',
                element: <Middleware.Authenticated render={<AttendanceList />} />
            },
            {
                path: '/attendances/:id',
                element: <Middleware.Authenticated render={<AttendanceDetail />} />
            }
        ]
    }
];

/**
 * Initiate leave routes
 */
const leaveRoutes = [
    {
        element: <Layout />,
        children: [
            {
                path: '/leaves',
                element: <Middleware.Authenticated render={<LeaveList />} />
            },
            {
                path: '/leaves/:id',
                element: <Middleware.Authenticated render={<LeaveForm />} />
            },
            {
                path: '/leaves/detail/:id',
                element: <Middleware.Authenticated render={<LeaveDetail />} />
            }
        ]
    }
];

/**
 * Initiate overtime routes
 */
const overtiemRoutes = [
    {
        element: <Layout />,
        children: [
            {
                path: '/overtimes',
                element: <Middleware.Authenticated render={<OvertimeList />} />
            },
            {
                path: '/overtimes/:id',
                element: <Middleware.Authenticated render={<OvertimeForm />} />
            },
            {
                path: '/overtimes/detail/:id',
                element: <Middleware.Authenticated render={<OvertimeDetail />} />
            }
        ]
    }
];

/**
 * Initiate workflow routes
 */
const workflowRoutes = [
    {
        element: <Layout />,
        children: [
            {
                path: '/submissions',
                element: <Middleware.Authenticated render={<WorkflowSubmission />} />
            },
            {
                path: '/approvals',
                element: <Middleware.Authenticated render={<WorkflowApproval />} />
            }
        ]
    }
];

/**
 * Initiate payroll routes
 */
const payrollRoutes = [
    {
        element: <Layout />,
        children: [
            {
                path: '/payrolls',
                element: <Middleware.Authenticated render={<PayrollList />} />
            }, {
                path: '/payrolls/:id',
                element: <Middleware.Authenticated render={<PayrollForm />} />
            }, {
                path: '/payrolls/detail/:id',
                element: <Middleware.Authenticated render={<PayrollDetail />} />
            }
        ]
    }
]

/**
 * Initiate inbox routes
 */
const inboxRoutes = [
    {
        element: <Layout />,
        children: [
            {
                path: '/inbox',
                element: <Middleware.Authenticated render={<InboxList />} />
            }
        ]
    }
];

/**
 * Initiate report routes
 */
const reportRoutes = [
    {
        element: <Layout />,
        children: [
            {
                path: '/reports/attendances',
                element: <Middleware.Authenticated render={<ReportAttendances />} />
            }, {
                path: '/reports/employee_attendances',
                element: <Middleware.Authenticated render={<ReportEmployeeAttendances />} />
            }, {
                path: '/reports/leaves',
                element: <Middleware.Authenticated render={<ReportLeaves />} />
            }, {
                path: '/reports/overtimes',
                element: <Middleware.Authenticated render={<ReportOvertimes />} />
            }, {
                path: '/reports/employees',
                element: <Middleware.Authenticated render={<ReportEmployees />} />
            }
        ]
    }
];

/**
 * Initiate profile routes
 */
const profileRoutes = [
    {
        element: <Layout />,
        children: [
            {
                path: '/me',
                element: <Middleware.Authenticated render={<Me />} />
            }, {
                path: '/me/change_password',
                element: <Middleware.Authenticated render={<ChangePassword />} />
            }
        ]
    }
];

/**
 * Initiate complaint routes
 */
const complaintRoutes = [
    {
        element: <Layout />,
        children: [
            {
                path: '/complaints',
                element: <Middleware.Authenticated render={<ComplaintList />} />
            }, {
                path: '/complaints/:id',
                element: <Middleware.Authenticated render={<ComplaintDetail />} />
            }
        ]
    }
];

/**
 * Initiate nlps routes
 */
const nlpRoutes = [
    {
        element: <Layout />,
        children: [
            {
                path: '/nlps',
                element: <Middleware.Authenticated render={<NlpEngine />} />
            }
        ]
    }
];

/**
 * Load all defined routes into main router
 * note: make sure route you made have same properties
 */
const routers = createBrowserRouter([
    ...authRoutes,
    ...dashboardRoutes,
    ...masterRoutes,
    ...attendanceRoutes,
    ...leaveRoutes,
    ...overtiemRoutes,
    ...workflowRoutes,
    ...payrollRoutes,
    ...inboxRoutes,
    ...reportRoutes,
    ...profileRoutes,
    ...complaintRoutes,
    ...nlpRoutes,
    /**
     * Page error handler
     */
    {
        path: '/unauthorized',
        element: <Unauthorized />
    },
    {
        path: '*',
        element: <NotFound />
    }
]);

function App() {
    return <RouterProvider router={routers} />
}

export default App;
