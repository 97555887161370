import { Link } from "react-router-dom";
import security from "./../assets/svg/security.svg";

function Unauthorized() {
    return (
        <div className="page-error">
            <div className="content">
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-lg-5 d-flex flex-column align-items-center">
                            <h1 className="error-number">401</h1>
                            <h2 className="error-title">Unauthorized</h2>
                            <p className="error-text">Oopps. The page you were try to open is not allowed to access, please contact admin if you need to access this page.</p>
                            <Link to="/" className="btn btn-primary btn-error">Back to Dashboard</Link>
                        </div>
                        <div className="col-8 col-lg-6 mb-5 mb-lg-0">
                            <object type="image/svg+xml" data={security} className="w-100"></object>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Unauthorized;