import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import Rest from "./../../libraries/Rest";
import Alert from "./../../libraries/Alert";

function Me() {
    const rest = new Rest();

    /**
     * Init state
     */
    let [profile, setProfile] = useState({
        name: '-',
        role: '-'
    });
    let [attributes, setAttributes] = useState({
        company: '-',
        employee_type: '-',
        department: '-',
        position: '-'
    });

    /**
     * First init
     */
    const getProfile = async () => {
        const response = await rest.get(`/me`);
        if (response?.data?.data) {
            const data = response.data.data;

            /**
             * Update attributes state
             */
            let _attributes = {};
            if (data?.user_companies?.length > 0) {
                _attributes['company'] = data.user_companies[0]?.companies?.name;
            }

            /**
             * Update profile
             */
            let _profile = {}
            if (data?.user_roles?.length > 0) {
                _profile['role'] = data.user_roles[0]?.roles?.name;
            }
            if (['super_admin', 'company_admin'].includes(_profile?.role?.toLowerCase())) {
                _attributes['employee_type'] = 'Super Admin';
                _profile['name'] = `(${data?.user_code}) ${data?.short_name}`;
            } else if (data?.employee) {
                /**
                 * Assumed this is data employee
                 */
                _profile['name'] = `(${data?.employee?.nik}) - `;
                _profile['name'] += `${data?.employee?.first_name} ${data?.employee?.middle_name ?? ''} ${data?.employee?.last_name ?? ''}`;

                /**
                 * Pops attributes
                 */
                if (data?.employee?.employee_job_histories?.length > 0) {
                    _attributes['employee_type'] = data.employee.employee_job_histories[0]?.employee_types?.name;
                    _attributes['department'] = data.employee.employee_job_histories[0]?.departments?.name;
                    _attributes['position'] = data.employee.employee_job_histories[0]?.positions?.name;
                }
            }

            /**
             * Updating states
             */
            setAttributes({ ...attributes, ..._attributes });
            setProfile({ ...profile, ..._profile });
        }
    }

    useEffect(() => {
        async function __init() {
            Alert.showLoading();
            await getProfile();
            Alert.close();
        }
        __init();
    }, []);

    /**
     * Render DOM
     */
    return (
        <Row className="g-3">
            <Col xl="12">
                <div className="media-profile mb-5">
                    <div className="media-img mb-3 mb-sm-0">
                        <img src="https://img.freepik.com/free-psd/3d-illustration-bald-person-with-glasses_23-2149436184.jpg?t=st=1721695578~exp=1721699178~hmac=d005c68e327e6792426b65a4115d33a9bcafe51b449e8b01f505284269229969&w=1060" className="img-fluid" alt="default image icon" />
                    </div>
                    <div className="media-body">
                        <h5 className="media-name" style={{ textTransform: 'capitalize' }}>
                            {profile.name}
                        </h5>
                        <p className="d-flex gap-2 mb-4" style={{ textTransform: 'capitalize' }}>
                            {profile.role?.replace(/_/g, ' ')}
                        </p>
                        <Row className='row-cols-sm-auto g-4 g-md-5 g-xl-4 g-xxl-5'>
                            <Col>
                                <div className="profile-item">
                                    <i className="ri-home-4-line"></i>
                                    <div className="profile-item-body">
                                        <p>{attributes.company}</p>
                                        <span>Company</span>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="profile-item">
                                    <i className="ri-spy-line"></i>
                                    <div className="profile-item-body">
                                        <p>{attributes.employee_type}</p>
                                        <span>Employee Type</span>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="profile-item">
                                    <i className="ri-building-line"></i>
                                    <div className="profile-item-body">
                                        <p>{attributes.department}</p>
                                        <span>Department</span>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="profile-item">
                                    <i className="ri-user-follow-line"></i>
                                    <div className="profile-item-body">
                                        <p>{attributes.position}</p>
                                        <span>Position</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default Me;