import { Card, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Rest from "../../../../libraries/Rest";
import Alert from "../../../../libraries/Alert";
import * as Components from "../../../../components/Components";
import Validator from "../../../../libraries/Validator";
import LocalStorage from "../../../../libraries/LocalStorage";

function EmployeeShiftForm() {
    const { id, employeeId } = useParams();
    const navigate = useNavigate();
    const rest = new Rest();
    const storage = new LocalStorage();

    const roleName = storage.getItem('role_name')?.toLowerCase();
    const companyName = storage.getItem('company_name')?.toLowerCase();
    const isCreate = id?.toLowerCase() === 'add' ? true : false;

    /**
     * Init validator of payload
     */
    const validator = new Validator({
        employee_id: { required: true },
        company_shift_id: { required: true },
        start_date: { required: true },
        end_date: { required: true }
    });

    /**
     * Init states
     */
    const rootPath = `/employee_shifts`;
    let endpoint = `/employees/shifts`;

    let [payload, setPayload] = useState({
        employee_id: null,
        company_shift_id: null,
        start_date: null,
        end_date: null,
        remark: null,
        is_active: 'false',
        is_overtime: 'false'
    });
    let [companies, setCompanies] = useState([]);
    let [companySelected, setCompanySelected] = useState();
    let [employees, setEmployees] = useState([]);
    let [shiftCodes, setShiftCodes] = useState([]);

    /**
     * First init function
     */
    const getCompanies = async () => {
        let res = await rest.get(`/companies?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let options = [];
            for (let i = 0; i < res.length; i++) {
                let push = true;

                if (roleName !== 'super_admin') {
                    push = companyName === res[i].name?.toLowerCase() ? true : false;
                }

                if (push) {
                    options.push({
                        value: res[i].id,
                        label: res[i].name
                    });

                    if (roleName !== 'super_admin') {
                        await getShifts(res[i].id);
                        await getEmployees(res[i].id);
                    }
                }
            }
            setCompanies(options);
        }
    }

    const getShifts = async (companyId) => {
        let res = await rest.get(`/companies/shifts/${companyId}?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let options = [];
            for (let i = 0; i < res.length; i++) {
                options.push({
                    value: res[i].id,
                    label: `${res[i].code} - ${res[i].name}`
                });
            }
            setShiftCodes(options);
        }
    }

    const getEmployees = async (companyId) => {
        let res = await rest.get(`/employees?page=1&limit=100&companyId=${companyId}`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let options = [];
            for (let i = 0; i < res.length; i++) {
                options.push({
                    value: res[i].id,
                    label: `${res[i].nik} - ${res[i].first_name} ${res[i].middle_name ?? ''} ${res[i].last_name ?? ''}`
                });
            }
            setEmployees(options);
        }
    }

    const getDataById = async () => {
        const response = await rest.get(`${endpoint}/${employeeId}/${id}`);
        if (response?.data?.data !== undefined) {
            const data = response.data.data;
            setPayload({
                ...payload,
                employee_id: data?.employees?.id,
                company_shift_id: data?.company_shifts?.id,
                start_date: data?.start_date,
                end_date: data?.end_date,
                remark: data?.remark,
                is_active: data?.is_active ? 'true' : 'false',
                is_overtime: data?.is_overtime ? 'true' : 'false',
            });

            /**
             * Call necessary data
            */
            if (data?.company_shifts?.company_id !== undefined) {
                setCompanySelected(data?.company_shifts?.company_id);
                getShifts(data?.company_shifts?.company_id);
                getEmployees(data?.company_shifts?.company_id);
            }
        } else {
            Alert.showMessage('Error', 'error', 'Data is not found');
            navigate(rootPath);
        }
    }

    useEffect(() => {
        async function __init() {
            Alert.showLoading();
            await getCompanies();
            if (!isCreate) await getDataById();
            Alert.close();
        }
        __init();
    }, []);


    /**
     * Action handlers
     */
    const updatePayload = async (field, value) => {
        if (field === 'companyId') {
            setCompanySelected(value);

            await getShifts(value);
            await getEmployees(value);
        } else {
            setPayload({ ...payload, [field]: value });
        }
    }

    const submit = async () => {
        /**
         * Validate input
         */
        const errMessages = validator.validate(payload);
        if (errMessages.length > 0) {
            let errMessageStrings = ``;
            for (let i = 0; i < errMessages.length; i++) {
                errMessageStrings += `${errMessages[i]}<br />`;
            }
            return Alert.showMessage(`Invalid input`, `warning`, errMessageStrings);
        }

        /**
         * Update few datas
         */
        payload.is_active = payload.is_active === 'true' ? true : false;
        payload.is_overtime = payload.is_overtime === 'true' ? true : false;

        /**
         * Submit action
         */
        endpoint = `${endpoint}/${payload.employee_id}`;
        delete payload.employee_id;

        Alert.showConfirm({
            url: endpoint + (!isCreate ? `/${id}` : ``),
            method: isCreate ? 'POST' : 'PUT',
            data: payload,
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    /**
     * Rendering DOM
     */
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <ol className="breadcrumb fs-sm mb-1">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/employee_shifts">List employee shifts</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Form {isCreate ? 'create' : 'edit'}
                        </li>
                    </ol>
                </div>
            </div>

            <Row className="g-3">
                <Col xl="12">
                    <Card className="card">
                        <Card.Header className="bg-primary">
                            <Card.Title
                                as="label"
                                className="fs-sm fw-medium mb-1 text-white"
                            >
                                Form {isCreate ? 'create' : 'edit'} employee shift
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="bg-white">
                            <Link to={rootPath} className="btn btn-sm btn-warning text-white">
                                <i className="ri-arrow-left-line"></i> Back
                            </Link>
                            &nbsp;
                            <button className="btn btn-sm btn-primary" onClick={submit}>
                                <i className="ri-check-line"></i> Save
                            </button>
                            <br /><br />
                            <Row className="g-3">
                                {roleName === 'super_admin' && <Col sm="4">
                                    <Components.Select
                                        label="Company"
                                        name="companyId"
                                        defaultValue={companySelected}
                                        change={updatePayload}
                                        options={companies}
                                    />
                                </Col>}
                                <Col sm={roleName === 'super_admin' ? '4' : '6'}>
                                    <Components.Select
                                        label="Employee"
                                        name="employee_id"
                                        defaultValue={payload.employee_id}
                                        change={updatePayload}
                                        options={employees}
                                    />
                                </Col>
                                <Col sm={roleName === 'super_admin' ? '4' : '6'}>
                                    <Components.Select
                                        label="Shift Code"
                                        name="company_shift_id"
                                        defaultValue={payload.company_shift_id}
                                        change={updatePayload}
                                        options={shiftCodes}
                                    />
                                </Col>
                                <Col sm="3">
                                    <Components.Input
                                        type="date"
                                        name="start_date"
                                        label="Start Date"
                                        defaultValue={payload.start_date}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="3">
                                    <Components.Input
                                        type="date"
                                        name="end_date"
                                        label="End Date"
                                        defaultValue={payload.end_date}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="3">
                                    <Components.Select
                                        label="Status Active"
                                        name="is_active"
                                        defaultValue={payload.is_active}
                                        change={updatePayload}
                                        options={[
                                            {
                                                value: 'false',
                                                label: 'Not Active'
                                            }, {
                                                value: 'true',
                                                label: 'Active'
                                            }
                                        ]}
                                    />
                                </Col>
                                <Col sm="3">
                                    <Components.Select
                                        label="Type"
                                        name="is_overtime"
                                        defaultValue={payload.is_overtime}
                                        change={updatePayload}
                                        options={[
                                            {
                                                value: 'false',
                                                label: 'Regular'
                                            }, {
                                                value: 'true',
                                                label: 'Overtime'
                                            }
                                        ]}
                                    />
                                </Col>
                                <Col sm="12">
                                    <Components.Input
                                        type="text"
                                        name="remark"
                                        label="Remark"
                                        placeholder="Remark"
                                        defaultValue={payload.remark}
                                        change={updatePayload}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default EmployeeShiftForm;