import axios from "axios";
import LocalStorage from "./LocalStorage";

class Rest {

    MAIN_HOST = process.env.REACT_APP_API_HOST_NAME;
    STORAGE = window.localStorage.getItem('APP_HRIS_STORAGE_V1');
    ACCESS_TOKEN = null;

    constructor() {
        if (this.STORAGE) {
            this.STORAGE = JSON.parse(this.STORAGE);
            this.ACCESS_TOKEN = this.STORAGE?.access_token !== undefined ? this.STORAGE.access_token : null;
        }
    }

    interceptor(status = 401, response = null, endpoint = null) {
        if (
            response
            && status === 400
            && response?.first_init === true
            && !endpoint.includes('/inboxes') // do not redirect page if endpoint is inbox (cause /inboxes called from background)
        ) {
            /**
             * Handle if user logged in the first time
             * Force redirect to change password
             */
            window.location.href = '/me/change_password';
        } else if (status === 401) {
            /**
             * Handle response status 401
             */
            const storage = new LocalStorage();
            storage.removeItem();

            window.location.href = '/login';
        } else {
            return null;
        }

    }

    async get(endpoint, customMethod = 'GET') {
        try {
            let options = {
                method: customMethod,
                url: this.MAIN_HOST + endpoint
            }
            if (this.ACCESS_TOKEN !== null || this.ACCESS_TOKEN !== undefined) {
                options['headers'] = {
                    'authorization': `Bearer ${this.ACCESS_TOKEN}`
                }
            }
            const response = await axios(options);

            /**
             * Validate response data
             * throw clean data with valid attributes
             */
            let callback = {
                success: false,
                statusCode: response.status,
                message: response.statusText,
                data: null
            }

            /**
             * Intercept when user got 401 error code
             */
            if ([401].includes(response.status)) return this.interceptor();

            /**
             * Flag as true when statusCode beginning with 200
             */
            if ([200, 201, 202, 203, 204, 205].includes(response.status)) {
                callback.success = true;
                callback.data = response.data;
            }

            /**
             * send callback
             */
            return callback;
        } catch (error) {
            this.interceptor(error?.response?.status, error?.response?.data, endpoint);
            return {
                success: false,
                statusCode: error?.response?.status,
                message: error?.response?.data?.message,
                data: null
            }
        }
    }

    async post(endpoint, data, customMethod = 'POST') {
        try {
            let options = {
                method: customMethod,
                url: this.MAIN_HOST + endpoint,
                data: data
            }
            if (this.ACCESS_TOKEN !== null || this.ACCESS_TOKEN !== undefined) {
                options['headers'] = {
                    'authorization': `Bearer ${this.ACCESS_TOKEN}`
                }
            }
            const response = await axios(options);

            /**
             * Validate response data
             * throw clean data with valid attributes
             */
            let callback = {
                success: false,
                statusCode: response.status,
                message: response.statusText,
                data: null
            }

            if ([401].includes(response.status)) return this.interceptor();

            /**
             * Flag as true when statusCode beginning with 200
             */
            if ([200, 201, 202, 203, 204, 205].includes(response.status)) {
                callback.success = true;
                callback.data = response.data;
            }

            /**
             * send callback
             */
            return callback;
        } catch (error) {
            this.interceptor(error?.response?.status, error?.response?.data, endpoint);
            return {
                success: false,
                statusCode: error?.response?.status,
                message: error?.response?.data?.message,
                data: error?.response?.data,
            }
        }
    }

    async put(endpoint, data) {
        return await this.post(endpoint, data, 'PUT');
    }

    async del(endpoint) {
        return await this.get(endpoint, 'DELETE');
    }
}

export default Rest;