import { Card, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Rest from "../../../libraries/Rest";
import Alert from "../../../libraries/Alert";
import * as Components from "./../../../components/Components";
import Validator from "../../../libraries/Validator";
import LocalStorage from "../../../libraries/LocalStorage";

function UserForm() {
    let { id } = useParams();
    const navigate = useNavigate();
    const rest = new Rest();
    const localStorage = new LocalStorage();
    const roleName = localStorage.getItem('role_name');

    /**
     * Init validator of payload
     */
    const validator = new Validator({
        role_id: { required: true },
        email: { required: true },
        user_code: { required: true },
        short_name: { required: true },
        password: {
            required: {
                create: true,
                update: false
            }
        },
    });

    /**
     * Init states
     */
    const isCreate = id?.toLowerCase() === 'add' ? true : false;
    const rootPath = `/users`;
    const endpoint = `/users`;

    let [payload, setPayload] = useState({
        role_id: null,
        company_id: null,
        user_code: null,
        email: null,
        phone: null,
        short_name: null,
        is_active: false,
        password: null
    });
    let [roles, setRoles] = useState([]);
    let [companies, setCompanies] = useState([]);
    let [choosedRole, setChoosedRole] = useState();

    /**
     * Global functions
     * 
     * @param value is value in option
     * @param label is label in option
     */
    const generateOptions = (data, value, label) => {
        let options = [];
        for (let i = 0; i < data.length; i++) {
            options.push({
                value: data[i][value] !== undefined ? data[i][value] : null,
                label: data[i][label] !== undefined ? data[i][label] : null
            });
        }
        return options;
    }

    /**
     * Preparing important datas
     */
    const getRoles = async () => {
        const response = await rest.get(`/roles?page=1&limit=1000&name:asc&noEmployee=true`);
        if (response?.data?.data?.data !== undefined) {
            let data = [];

            for (let i = 0; i < response.data.data.data.length; i++) {
                const v = response.data.data.data[i];
                let push = true;
                if (
                    roleName === 'company_admin'
                    && v?.name === 'super_admin'
                ) push = false;
                if (push) data.push(v);
            }
            setRoles(generateOptions(data, 'id', 'name'));
        }
    }

    const getCompanies = async () => {
        const response = await rest.get(`/companies?page=1&limit=1000&name:asc`);
        if (response?.data?.data?.data !== undefined) setCompanies(generateOptions(response.data.data.data, 'id', 'name'));
    }

    const getDataById = async (userId) => {
        const response = await rest.get(`${endpoint}/${userId}`);
        if (response?.data?.data !== undefined) {
            const data = response.data.data;

            let roleId = null;
            if (data?.user_roles?.length > 0) roleId = data.user_roles[0]?.role_id;

            let companyId = null;
            if (data?.user_companies?.length > 0) companyId = data.user_companies[0]?.company_id;

            setPayload({
                role_id: roleId,
                company_id: companyId,
                user_code: data?.user_code,
                email: data?.email,
                phone: data?.phone,
                short_name: data?.short_name,
                is_active: data?.is_active ? 'true' : 'false',
                password: null
            });
        } else {
            Alert.showMessage('Error', 'error', 'Data is not found');
            navigate(rootPath);
        }
    }

    /**
     * First init before rendering
     */
    useEffect(() => {
        async function __init() {
            Alert.showLoading();

            await getRoles();
            await getCompanies();
            if (!isCreate) await getDataById(id);

            Alert.close();
        }
        __init();
    }, []);

    /**
     * Action handlers
     */
    const updatePayload = (field, value) => {
        setPayload({ ...payload, [field]: value });

        /**
         * Check if role changed to show company input
         */
        if (field === 'role_id') {
            const x = roles.find(x => x?.value === value);
            if (x !== undefined) setChoosedRole(x?.label?.toLowerCase());
        }
    }

    const submit = async () => {
        /**
         * Validate user input
         */
        const errMessages = validator.validate(payload, (isCreate ? `create` : `update`));
        if (errMessages.length > 0) {
            let errMessageStrings = ``;
            for (let i = 0; i < errMessages.length; i++) {
                errMessageStrings += `${errMessages[i]}<br />`;
            }
            return Alert.showMessage(`Invalid input`, `warning`, errMessageStrings);
        }

        /**
         * Validate company_id when role is company_admin
         */
        if (choosedRole === 'company_admin') {
            if (!payload.company_id) return Alert.showMessage(`Invalid input`, `warning`, `You didnt choose any company yet`);
        }

        /**
         * Submit action
         */
        payload.is_active = payload.is_active === 'true' ? true : false; // update few data before send

        Alert.showConfirm({
            url: endpoint + (!isCreate ? `/${id}` : ``),
            method: (!isCreate ? 'PUT' : 'POST'),
            data: payload,
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    /**
     * Rendering DOM
     */
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <ol className="breadcrumb fs-sm mb-1">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={rootPath}>List users</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Form {isCreate ? 'create' : 'edit'}
                        </li>
                    </ol>
                </div>
            </div>

            <Row className="g-3">
                <Col xl="12">
                    <Card className="card">
                        <Card.Header className="bg-primary">
                            <Card.Title
                                as="label"
                                className="fs-sm fw-medium mb-1 text-white"
                            >
                                Form {isCreate ? 'create' : 'edit'} users
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="bg-white">
                            <Link to={rootPath} className="btn btn-sm btn-warning text-white">
                                <i className="ri-arrow-left-line"></i> Back
                            </Link>
                            &nbsp;
                            <button className="btn btn-sm btn-primary" onClick={submit}>
                                <i className="ri-check-line"></i> Save
                            </button>
                            <br /><br />
                            <Row className="g-3">
                                <Col sm="4">
                                    <Components.Input
                                        type="text"
                                        name="user_code"
                                        label="User Code"
                                        placeholder="User Code"
                                        defaultValue={payload.user_code}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Input
                                        type="text"
                                        name="email"
                                        label="Email"
                                        placeholder="Email"
                                        defaultValue={payload.email}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Input
                                        type="password"
                                        name="password"
                                        label="Password"
                                        placeholder="Password"
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Input
                                        type="text"
                                        name="phone"
                                        label="Phone"
                                        placeholder="Phone"
                                        defaultValue={payload.phone}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="8">
                                    <Components.Input
                                        type="text"
                                        name="short_name"
                                        label="Short name"
                                        placeholder="Short name"
                                        defaultValue={payload.short_name}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Select
                                        name="is_active"
                                        label="Status"
                                        defaultValue={payload.is_active}
                                        options={[
                                            {
                                                value: 'false',
                                                label: 'Not Active'
                                            }, {
                                                value: 'true',
                                                label: 'Active'
                                            }
                                        ]}
                                        change={updatePayload}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Components.Select
                                        name="role_id"
                                        label="Role"
                                        options={roles}
                                        defaultValue={payload.role_id}
                                        change={updatePayload}
                                    />
                                </Col>
                                {choosedRole === 'company_admin' && roleName === 'super_admin' && <Col sm="4">
                                    <Components.Select
                                        name="company_id"
                                        label="Company"
                                        options={companies}
                                        defaultValue={payload.company_id}
                                        change={updatePayload}
                                    />
                                </Col>}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default UserForm;