class LocalStorage {

    __NAME__ = 'APP_HRIS_STORAGE_V1';

    getItem(field = null, customName = null) {
        let data = window.localStorage.getItem(customName ? customName : this.__NAME__);
        if (data) {
            data = JSON.parse(data);
            if (field) return data[field] !== undefined ? data[field] : null;
            return data;
        }
        return null;
    }

    setItem(data, customName = null) {
        return window.localStorage.setItem(customName ? customName : this.__NAME__, JSON.stringify(data));
    }

    removeItem(customName = null) {
        return window.localStorage.removeItem(customName ? customName : this.__NAME__);
    }

}

export default LocalStorage;