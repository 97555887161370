import React from 'react';
import { Card, Row, Col, Modal, Button } from "react-bootstrap";
import { Grid, _ } from "gridjs-react";
import { useEffect, useState } from "react";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    pdf,
    Image,
} from '@react-pdf/renderer';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import Rest from "./../../libraries/Rest";
import Alert from "./../../libraries/Alert";
import LocalStorage from "./../../libraries/LocalStorage";
import * as Components from "./../../components/Components";

function ReportEmployeeAttendances() {
    const rest = new Rest();
    const localStorage = new LocalStorage();
    const roleName = localStorage.getItem('role_name')?.toLowerCase();
    const companyName = localStorage.getItem('company_name');
    const allowedRole = 'super_admin'; // static role validation (code: VL01)

    const [companies, setCompanies] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [employeeSelected, setEmployeeSelected] = useState([]);
    const [payload, setPayload] = useState({
        companyId: null,
        departmentId: null,
        startDate: null,
        endDate: null,
        status: null
    });
    const [parameters, setParameters] = useState('');
    const [employeeParameters, setEmployeeParameters] = useState('');
    const [showPrint, setShowPrint] = useState(false);

    /**
     * State for open modal
     */
    const [attachments, setAttachments] = useState([]);
    const [showAttachment, setShowAttachment] = useState(false);
    const openAttachment = (type) => setShowAttachment(type);

    const [showEmployee, setShowEmployee] = useState(false);
    const openEmployee = (type) => setShowEmployee(type);

    /**
     * First init
     */
    const getCompanies = async () => {
        let res = await rest.get(`/companies?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let options = [];
            let companyDefault = null;
            for (let i = 0; i < res.length; i++) {
                options.push({
                    value: res[i]['id'] !== undefined ? res[i]['id'] : null,
                    label: res[i]['name'] !== undefined ? res[i]['name'] : null,
                });

                /**
                 * Get company default ONLY if user logged in is not super_admin
                 */
                if (
                    roleName?.toLowerCase() !== allowedRole
                    && res[i]['name']?.toLowerCase() === companyName?.toLowerCase()
                ) {
                    companyDefault = res[i]['id'];
                }
            }
            setCompanies(options);

            /**
             * Call departments
             */
            if (companyDefault) {
                setPayload({ ...payload, companyId: companyDefault });
                await getDepartments(companyDefault);
            }
        }
    }

    const getDepartments = async (_companyId) => {
        let res = await rest.get(`/companies/departments/${_companyId}?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let options = [];
            for (let i = 0; i < res.length; i++) {
                options.push({
                    value: res[i]['id'] !== undefined ? res[i]['id'] : null,
                    label: res[i]['name'] !== undefined ? res[i]['name'] : null,
                });
            }
            setDepartments(options);
        }
    }

    const updatePayload = async (field, value) => {
        setPayload({ ...payload, [field]: value });

        /**
         * Get employees when user changed company
         * And role user is `super_admin`
         */
        if (field === 'companyId') {
            setEmployees([]);
            setEmployeeSelected([]);
            await getDepartments(value);
        }

        /**
         * Set parameters
         */
        if (field === 'employeeId') return; // skip process if payload is `employeeId`

        let _parameters = parameters;
        if (_parameters.includes(field)) {
            const _parr = _parameters.split('&');
            _parameters = ''; // reset;
            const _skip = value && value !== '' ? false : true;

            for (let i = 0; i < _parr.length; i++) {
                let _varr = _parr[i].split('=');
                if (_varr.length >= 1 && _varr[0] === field) {
                    if (!_skip) {
                        _varr[1] = value;
                    } else {
                        continue; // remove this field
                    }
                }

                /**
                 * Join the parameters
                 */
                if (
                    _varr[0] && _varr[0] !== undefined
                    &&
                    _varr[1] && _varr[1] !== undefined
                ) _parameters += `&${_varr[0]}=${_varr[1]}`;
            }
        } else {
            _parameters += `&${field}=${value}`;
        }
        setParameters(_parameters);
    }

    useEffect(() => {
        getCompanies();
    }, []);

    const openAttachments = (_attachments = []) => {
        setAttachments(_attachments);
        openAttachment(true);
    }

    const searchEmployee = async () => {
        if (!payload.companyId) Alert.showMessage('Warning', 'warning', 'You didnt choose any company yet');

        /**
         * Get employees by company and department (if exists)
         */
        Alert.showLoading();
        let url = `/employees?page=1&limit=10000`;
        if (payload.companyId) url += `&companyId=${payload.companyId}`;
        let res = await rest.get(url);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let emp = [];
            for (let i = 0; i < res.length; i++) {
                let checked = false;
                const x = employeeSelected.find(x => x === res[i]?.id);
                if (x !== undefined) checked = true;

                const e = {
                    id: res[i]?.id,
                    nik: res[i]?.nik,
                    name: `${res[i]?.first_name} ${res[i]?.middle_name ?? ''} ${res[i]?.last_name ?? ''}`,
                    checked
                }
                emp.push(e);
            }
            setEmployees(emp);
            openEmployee(true);
        }
        Alert.close();
    }

    const handleCheckbox = (e) => {
        /**
         * Append or remove from selected employee
         */
        const x = employeeSelected.findIndex(x => x === e.target.value);
        if (!e.target.checked) {
            employeeSelected.splice(x, 1);
        } else {
            employeeSelected.push(e.target.value);
        }
        setEmployeeSelected(employeeSelected);
    }

    const reRenderTable = () => {
        /**
         * Translate to query params and join into global parameters
         */
        let paramsEmp = '';
        for (let i = 0; i < employeeSelected.length; i++) {
            paramsEmp += `&employeeIds=${employeeSelected[i]}`;
        }
        setEmployeeParameters(paramsEmp);
        openEmployee(false);
    }

    /**
     * Exported into PDF file
     */
    const styles = StyleSheet.create({
        page: {
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            backgroundColor: '#E4E4E4',
        },
        image: {
            width: 100,
            height: 100,
            objectFit: 'contain'
        },
        center: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        mainTitle: {
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 'bold',
            textTransform: 'uppercase'
        },
        row: {
            flexDirection: 'row',
            marginTop: 20
        },
        column: {
            width: '50%',
            padding: 5,
        },
        normalFont: {
            fontSize: 12,
            textAlign: 'left'
        },
        table: {
            display: 'table',
            width: '100%',
            borderStyle: 'solid',
            borderColor: '#000',
            borderWidth: 1,
            borderCollapse: 'collapse',
        },
        tableRow: {
            display: 'table-row',
            flexDirection: 'row',
        },
        tableCell: {
            display: 'table-cell',
            padding: 5,
            borderStyle: 'solid',
            borderColor: '#000',
            borderWidth: 1,
            fontSize: 11,
        },
        tableHeader: {
            fontWeight: 'bold',
        },
        tableHeaderCell: {
            backgroundColor: '#f0f0f0',
        },
        pageBreak: {
            pageBreakBefore: 'always',
        },
    });

    const PdfDocument = (props) => {
        const { data, employee } = props;
        let companyName = '';
        // let employeeName = `${employee?.first_name ?? ''} ${employee?.middle_name ?? ''} ${employee?.last_name ?? ''} (${employee?.nik ?? ''})`;

        /**
         * Preparing data to load
         */
        if (employee?.users?.user_companies !== undefined) {
            if (employee.users.user_companies.length >= 0) {
                companyName = employee.users.user_companies[0]?.companies?.name;
            }
        }

        /**
         * Load table DOM
         */
        return (
            React.createElement(
                Document,
                {
                    title: 'Attendance Report'
                },
                React.createElement(
                    Page,
                    {
                        style: styles.page,
                        size: 'A4',
                        orientation: "landscape"
                    },
                    React.createElement(View, { style: styles.center },
                        React.createElement(Text, { style: styles.mainTitle }, `Attendance Report\n${companyName}`),
                    ),
                    // React.createElement(View, { style: styles.row },
                    //     React.createElement(View, { style: styles.column },
                    //         React.createElement(Text, { style: styles.normalFont }, `Employee Name: ${employeeName}`)
                    //     ),
                    //     React.createElement(Col, { style: styles.column },
                    //         React.createElement(Text, { style: { ...styles.normalFont, textAlign: 'right' } }, ``)
                    //     ),
                    // ),
                    React.createElement(View, { style: styles.table },
                        React.createElement(View, { style: [styles.tableRow, styles.tableHeader] },
                            React.createElement(View, { style: [styles.tableCell, styles.tableHeaderCell, { width: '5%' }] },
                                React.createElement(Text, { style: { ...styles.normalFont, textAlign: 'center' } }, `#`)
                            ),
                            React.createElement(View, { style: [styles.tableCell, styles.tableHeaderCell, { width: '20%' }] },
                                React.createElement(Text, { style: { ...styles.normalFont, textAlign: 'center' } }, `Name`)
                            ),
                            React.createElement(View, { style: [styles.tableCell, styles.tableHeaderCell, { width: '20%' }] },
                                React.createElement(Text, { style: { ...styles.normalFont, textAlign: 'center' } }, `Date In (time)`)
                            ),
                            React.createElement(View, { style: [styles.tableCell, styles.tableHeaderCell, { width: '15%' }] },
                                React.createElement(Text, { style: { ...styles.normalFont, textAlign: 'center' } }, `Attach. In`)
                            ),
                            React.createElement(View, { style: [styles.tableCell, styles.tableHeaderCell, { width: '20%' }] },
                                React.createElement(Text, { style: { ...styles.normalFont, textAlign: 'center' } }, `Date Out (time)`)
                            ),
                            React.createElement(View, { style: [styles.tableCell, styles.tableHeaderCell, { width: '15%' }] },
                                React.createElement(Text, { style: { ...styles.normalFont, textAlign: 'center' } }, `Attach. Out`)
                            ),
                            React.createElement(View, { style: [styles.tableCell, styles.tableHeaderCell, { width: '10%' }] },
                                React.createElement(Text, { style: { ...styles.normalFont, textAlign: 'center' } }, `Status`)
                            ),
                            React.createElement(View, { style: [styles.tableCell, styles.tableHeaderCell, { width: '15%' }] },
                                React.createElement(Text, { style: { ...styles.normalFont, textAlign: 'center' } }, `Site/Client`)
                            ),
                        )
                    ),
                    data.map((item, index) => {
                        const dateIn = `${item?.date_in} ${item?.time_in ?? ''}`;
                        const dateOut = `${item?.date_out ?? '-none-'} ${item?.time_out ?? ''}`;
                        const employeeName = `${item?.employees?.first_name ?? ''} ${item?.employees?.middle_name ?? ''} ${item?.employees?.last_name ?? ''}`

                        /**
                         * Check attachments
                         */
                        let attachIn = null;
                        let attachOut = null;

                        for (let i = 0; i < item?.attachments?.length; i++) {
                            if (i === 0) {
                                // Means attachment IN
                                attachIn = item.attachments[i]?.file_url;
                            } else if (i === 1) {
                                // Means attachment OUT
                                attachOut = item.attachments[i]?.file_url;
                            }
                        }

                        /**
                         * Load row
                         */
                        return (
                            React.createElement(View, { key: index, style: styles.tableRow },
                                React.createElement(View, { style: [styles.tableCell, { width: '5%' }] },
                                    React.createElement(Text, { style: styles.normalFont }, `${index + 1}`)
                                ),
                                React.createElement(View, { style: [styles.tableCell, { width: '20%' }] },
                                    React.createElement(Text, { style: styles.normalFont }, `${employeeName}`)
                                ),
                                React.createElement(View, { style: [styles.tableCell, { width: '20%' }] },
                                    React.createElement(Text, { style: styles.normalFont }, `${dateIn}`)
                                ),
                                React.createElement(View, { style: [styles.tableCell, { width: '15%' }] },
                                    attachIn ?
                                        React.createElement(Image, { src: `${attachIn}`, style: styles.image })
                                        :
                                        React.createElement(Text, { style: { ...styles.normalFont, textAlign: 'center' } }, `no-image`)
                                ),
                                React.createElement(View, { style: [styles.tableCell, { width: '20%' }] },
                                    React.createElement(Text, { style: styles.normalFont }, `${dateOut}`)
                                ),
                                React.createElement(View, { style: [styles.tableCell, { width: '15%' }] },
                                    attachOut ?
                                        React.createElement(Image, { src: `${attachOut}`, style: styles.image })
                                        :
                                        React.createElement(Text, { style: { ...styles.normalFont, textAlign: 'center' } }, `no-image`)
                                ),
                                React.createElement(View, { style: [styles.tableCell, { width: '10%' }] },
                                    React.createElement(Text, { style: styles.normalFont }, `${item?.status_text} ${item?.status?.toUpperCase() !== 'S2' ? '(' + item?.time_diff + 'Mins)' : ''}`)
                                ),
                                React.createElement(View, { style: [styles.tableCell, { width: '15%' }] },
                                    React.createElement(Text, { style: styles.normalFont }, `${item?.company_branches?.name}`)
                                ),
                            )
                        )
                    })
                )
            )
        );
    }

    const ExcelDocument = async (props) => {
        const { data, employee } = props;
        let companyName = '';
        // let employeeName = `${employee?.first_name ?? ''} ${employee?.middle_name ?? ''} ${employee?.last_name ?? ''} (${employee?.nik ?? ''})`;

        // Get company name
        if (employee?.users?.user_companies !== undefined) {
            if (employee.users.user_companies.length >= 0) {
                companyName = employee.users.user_companies[0]?.companies?.name;
            }
        }

        // Create a new workbook and a sheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(`Report Attendance`);
        const attachInCol = 3;
        const attachOutCol = 5;

        // Sizing column
        worksheet.getColumn(2).width = 25;
        worksheet.getColumn(3).width = 30;
        worksheet.getColumn(4).width = 25;
        worksheet.getColumn(5).width = 30;
        worksheet.getColumn(6).width = 20;
        worksheet.getColumn(7).width = 50;

        // Create title
        worksheet.addRow(['Report Attendance']);
        worksheet.mergeCells('A1:G1');
        worksheet.getCell('A1').alignment = { horizontal: 'center' };
        worksheet.getCell('A1').font = { bold: true };
        worksheet.addRow([`${companyName}`]);
        // worksheet.mergeCells('A2:G2');
        // worksheet.getCell('A2').alignment = { horizontal: 'center' };
        // worksheet.getCell('A2').font = { bold: true };
        // worksheet.addRow([`Employee Name: ${employeeName}`]);

        // Create header
        worksheet.addRow(['#', 'Name', 'Date In (time)', 'Attach. In', 'Date Out (time)', 'Attach. Out', 'Status', 'Site/Client']);
        for (let i = 0; i < data.length; i++) {
            const item = data[i];

            // Preparing data
            const dateIn = `${item?.date_in} ${item?.time_in ?? ''}`;
            const dateOut = `${item?.date_out ?? '-none-'} ${item?.time_out ?? ''}`;
            const employeeName = `${item?.employees?.first_name ?? ''} ${item?.employees?.middle_name ?? ''} ${item?.employees?.last_name ?? ''}`

            // Check attachments
            let attachIn = null;
            let attachOut = null;
            for (let i = 0; i < item?.attachments?.length; i++) {
                if (i === 0) {
                    // Means attachment IN
                    attachIn = item.attachments[i]?.file_url;
                } else if (i === 1) {
                    // Means attachment OUT
                    attachOut = item.attachments[i]?.file_url;
                }
            }

            // Append row
            const worksheetRow = worksheet.addRow([
                i + 1,
                employeeName,
                dateIn,
                '-',
                dateOut,
                '-',
                `${item?.status_text} ${item?.status?.toUpperCase() !== 'S2' ? '(' + item?.time_diff + 'Mins)' : ''}`,
                item?.company_branches?.name
            ]);

            // Load image attachment IN
            if (attachIn) {
                const response = await fetch(attachIn);
                const imageBlob = await response.blob();
                const imageId = workbook.addImage({
                    base64: await imageBlobToBase64(imageBlob),
                    extension: 'png',
                });
                worksheet.addImage(imageId, {
                    tl: { col: attachInCol - 1, row: worksheetRow.number - 1 },
                    ext: { width: 30, height: 30 },
                });
            }

            // Load image attachment OUT
            if (attachOut) {
                const response = await fetch(attachOut);
                const imageBlob = await response.blob();
                const imageId = workbook.addImage({
                    base64: await imageBlobToBase64(imageBlob),
                    extension: 'png',
                });
                worksheet.addImage(imageId, {
                    tl: { col: attachOutCol - 1, row: worksheetRow.number - 1 },
                    ext: { width: 30, height: 30 },
                });
            }
        }

        // Generate the Excel file and trigger the download
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'report-attendances.xlsx');
    }

    const imageBlobToBase64 = (blob) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.readAsDataURL(blob);
        });
    }

    const exportReport = async (type = 'pdf') => {
        try {
            /**
             * Get data attendance in full version
             * Without paging like in the table below
             */
            Alert.showLoading();
            const datas = await rest.get(`/reports/att_attendances?withAttachments=true&page=1&limit=1000${parameters}${employeeParameters}`);
            Alert.close();
            let data = datas?.data?.data;

            if (type === 'pdf') {
                data = data?.data;
                const employee = datas?.data?.data?.employee;

                // Load PDF Document
                const blob = await pdf(React.createElement(PdfDocument, { data, employee })).toBlob();
                const url = URL.createObjectURL(blob);
                window.open(url);
            } else if (type === 'excel') {
                const employee = datas?.data?.employee;

                // Load Excel Document
                await ExcelDocument(data, employee);
            }
        } catch (error) {
            Alert.showMessage('Warning', 'warning', 'Something wrong when exporting file.');
        }
    }

    return (
        <div>
            <Card className="bg-white">
                <Card.Header className="bg-primary">
                    <Card.Title as="label" className="fs-sm fw-medium mb-1 text-white">
                        Report of employee attendances
                    </Card.Title>
                    {showPrint && <button type="button" className="btn btn-sm btn-danger" style={{ float: 'right' }} onClick={() => exportReport('pdf')}>
                        <i className="ri-file-pdf-line"></i> Export to PDF
                    </button>}
                    {showPrint && <button type="button" className="btn btn-sm btn-success" style={{ float: 'right', marginRight: 10 }} onClick={() => exportReport('excel')}>
                        <i className="ri-file-excel-line"></i> Export to Excel
                    </button>}
                </Card.Header>
                <Card.Body className="bg-white">
                    <Row className="mt-1">
                        {/* Modal attachments */}
                        <Modal show={showAttachment} onHide={() => openAttachment(false)} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Detail Attachments</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    {attachments.map((v, i) => {
                                        return (
                                            <Col sm="6" key={i}>
                                                <img
                                                    src={v?.file_url}
                                                    className="img-thumbnail"
                                                />
                                                <p style={{ fontWeight: "bold", marginTop: 20 }}>
                                                    {v?.file_name}
                                                </p>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Modal.Body>
                        </Modal>

                        {/* Modal employees */}
                        <Modal show={showEmployee} onHide={() => openEmployee(false)} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Select specific employees you want to find</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="alert alert-info">
                                    <strong>Information:</strong> If you do not select an employee, the application will search for all employees.
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Employee ID</th>
                                                <th>Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employees.map((v, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                value={v?.id}
                                                                defaultChecked={v?.checked}
                                                                onChange={handleCheckbox}
                                                            />
                                                        </td>
                                                        <td>{v?.nik}</td>
                                                        <td>{v?.name}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={reRenderTable}>
                                    <i className="ri-filter-line"></i> Submit
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {roleName?.toLowerCase() === allowedRole &&
                            <Col sm="4">
                                <Components.Select
                                    label="Company"
                                    name="companyId"
                                    defaultValue={payload.companyId}
                                    change={updatePayload}
                                    options={companies}
                                />
                            </Col>
                        }
                        <Col sm="4">
                            <Components.Select
                                label="Department"
                                name="departmentId"
                                defaultValue={payload.departmentId}
                                change={updatePayload}
                                options={departments}
                            />
                        </Col>
                        <Col sm="4">
                            <Button className="btn btn-primary" style={{ marginTop: 27 }} onClick={searchEmployee}>
                                <i className="ri-filter-line"></i>
                                Employee selected: {employeeSelected.length <= 0 ? 'all' : `${employeeSelected.length} data(s)`}
                            </Button>
                        </Col>
                        <Col sm="4">
                            <Components.Input
                                type="date"
                                label="Start Date"
                                name="startDate"
                                defaultValue={payload.startDate}
                                change={updatePayload}
                            />
                        </Col>
                        <Col sm="4">
                            <Components.Input
                                type="date"
                                label="End Date"
                                name="endDate"
                                defaultValue={payload.endDate}
                                change={updatePayload}
                            />
                        </Col>
                        <Col sm="4">
                            <Components.Select
                                label="Status"
                                name="status"
                                defaultValue={payload.status}
                                change={updatePayload}
                                options={[
                                    {
                                        label: 'On Time',
                                        value: 'S2'
                                    }, {
                                        label: 'Early',
                                        value: 'S3'
                                    }, {
                                        label: 'Late',
                                        value: 'S4'
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                    <Grid
                        className={{ table: 'table table-bordered mb-0' }}
                        resizable={true}
                        language={{
                            search: {
                                placeholder: 'Find something..'
                            }
                        }}
                        columns={[
                            'No. Attendance',
                            'Employee Name',
                            'Company (Branch)',
                            'Start Date (Time)',
                            'End Date (Time)',
                            'Status',
                            'Attachments',
                        ]}
                        server={{
                            url: `${rest.MAIN_HOST}/reports/att_attendances?withAttachments=true${parameters}${employeeParameters}`,
                            headers: {
                                'authorization': `Bearer ${rest.ACCESS_TOKEN}`
                            },
                            then: data => {
                                setShowPrint(data?.data?.data?.length > 0);
                                return data?.data?.data?.map(r => [
                                    r?.no_attendance,
                                    `${r?.employees?.first_name ?? ''} ${r?.employees?.middle_name ?? ''} ${r?.employees?.last_name ?? ''}`,
                                    `${r?.companies?.name} ${r?.company_branches?.name ? '(' + r.company_branches.name + ')' : ''}`,
                                    `${r?.date_in} ${r?.time_in ? r?.time_in : '-none-'}`,
                                    `${r?.date_out ?? ''} ${r?.time_out ? r?.time_out : '-none-'}`,
                                    `${r?.status_text} ${r?.status?.toUpperCase() !== 'S2' ? '(' + r?.time_diff + 'Mins)' : ''}`,
                                    _(
                                        <div style={{ textAlign: 'center' }}>
                                            <button type="button" className="btn btn-primary btn-sm" onClick={() => openAttachments(r?.attachments)}>
                                                <i className="ri-attachment-line"></i>
                                            </button>
                                        </div>
                                    )
                                ]
                                )
                            },
                            total: data => data?.data?.total?.data,
                            handle: (res) => {
                                if (res.status === 401) return rest.interceptor();
                                return res.json();
                            }
                        }}
                        search={{
                            server: {
                                url: (prev, keyword) => `${prev}?keywords=${keyword}`
                            }
                        }}
                        pagination={{
                            limit: 10,
                            server: {
                                url: (prev, page, limit) => {
                                    const operator = prev.includes('?') ? '&' : '?';
                                    if (parseInt(page) <= 0) page = 1;
                                    return `${prev}${operator}limit=${limit}&page=${page}`
                                }
                            }
                        }}
                    />
                </Card.Body>
            </Card>
        </div>
    )

}

export default ReportEmployeeAttendances;