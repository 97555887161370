import { Card, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Grid, _ } from "gridjs-react";
import numeral from "numeral";

import Rest from "./../../../../libraries/Rest";
import Alert from "../../../../libraries/Alert";
import { useEffect, useState } from "react";

function EmployeeSalaryComponentList() {
    const rest = new Rest();
    const navigate = useNavigate();
    const { employeeId } = useParams();
    const [companyId, setCompanyId] = useState(null);

    const endpoint = `/employees/salary_components/${employeeId}`;
    const rootPath = `/employees/salary_components/${employeeId}`;

    const getDetailEmployee = async () => {
        Alert.showLoading();
        const response = await rest.get(`/employees/${employeeId}`);
        Alert.close();

        if (response?.data?.data?.user?.user_companies === undefined || response.data.data.user.user_companies.length <= 0) {
            Alert.showMessage('Warning', 'warning', 'Employee is not found');
            navigate(`/employees`);
        } else {
            const _companyId = response.data.data.user.user_companies[0]?.company_id;
            if (_companyId) {
                setCompanyId(_companyId);
            } else {
                Alert.showMessage('Warning', 'warning', 'Employee has no company yet');
                navigate(`/employees`);
            }
        }
    }

    useEffect(() => {
        async function __init() {
            await getDetailEmployee();
        }
        __init();
    }, []);

    const openPage = async (id) => {
        if (!companyId) return Alert.showMessage('Warning', 'warning', 'Employee has no company yet');
        navigate(`${rootPath}/${id}?companyId=${companyId}`);
    }

    const removeItem = async (id) => {
        Alert.showConfirm({
            url: `${rootPath}/${id}`,
            method: 'DELETE',
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    return (
        <div>
            <Card className="bg-white">
                <Card.Header className="bg-primary">
                    <Card.Title as="label" className="fs-sm fw-medium mb-1 text-white">
                        Salary Components
                    </Card.Title>
                </Card.Header>
                <Card.Body className="bg-white">
                    <Link to="/employees" className="btn btn-sm btn-warning text-white">
                        <i className="ri-arrow-left-line"></i> Back
                    </Link>
                    &nbsp;
                    <button type="button" onClick={() => openPage('add')} className="btn btn-primary btn-sm">
                        <i className="ri-add-line"></i> Create Data
                    </button>
                    <br /><br />
                    <Grid
                        className={{ table: 'table table-bordered mb-0' }}
                        resizable={true}
                        language={{
                            search: {
                                placeholder: 'Find something..'
                            }
                        }}
                        columns={[
                            'Component',
                            'Total',
                            'Unit',
                            'Type',
                            'Remark',
                            'Action',
                        ]}
                        server={{
                            url: `${rest.MAIN_HOST}${endpoint}`,
                            headers: {
                                'authorization': `Bearer ${rest.ACCESS_TOKEN}`
                            },
                            then: data => data?.data?.data?.map(r => [
                                r?.salary_components?.name,
                                numeral(r?.value_amount).format('0,0'),
                                r?.salary_components?.unit_text,
                                r?.type_text,
                                r?.remark,
                                _(
                                    <div style={{ textAlign: 'center' }}>
                                        <button type="button" onClick={() => openPage(r.id)} className="btn btn-info text-white btn-sm" title="Update data"><i className="ri-pencil-line"></i></button>
                                        &nbsp;
                                        <button type="button" onClick={() => removeItem(r.id)} className="btn btn-danger btn-sm" title="Remove data"><i className="ri-delete-bin-line"></i></button>
                                    </div>
                                )
                            ]),
                            total: data => data?.data?.total?.data,
                            handle: (res) => {
                                if (res.status === 401) return rest.interceptor();
                                return res.json();
                            }
                        }}
                        search={{
                            server: {
                                url: (prev, keyword) => `${prev}?keywords=${keyword}`
                            }
                        }}
                        pagination={{
                            limit: 10,
                            server: {
                                url: (prev, page, limit) => {
                                    const operator = prev.includes('?') ? '&' : '?';
                                    if (parseInt(page) <= 0) page = 1;
                                    return `${prev}${operator}limit=${limit}&page=${page}`
                                }
                            }
                        }}
                    />
                </Card.Body>
            </Card>
        </div >
    )

}

export default EmployeeSalaryComponentList;