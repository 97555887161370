const dashboardMenu = [
    {
        "label": "Dashboard",
        "link": "/dashboard",
        "icon": "ri-pie-chart-2-line"
    }
];

const masterMenu = [
    {
        "label": "Users",
        "link": "/users",
        "icon": "ri-user-line",
    },
    {
        "label": "Companies",
        "link": "/companies",
        "icon": "ri-building-line",
    },
    {
        "label": "Employees",
        "icon": "ri-group-line",
        "submenu": [
            {
                "label": "Master",
                "link": "/employees",
            },
            {
                "label": "Shift Calendar",
                "link": "/employee_shifts"
            }
        ]
    },
];

const transactionMenu = [
    {
        "label": "Attendances",
        "link": "/attendances",
        "icon": "ri-login-circle-line",
    },
    {
        "label": "Leaves",
        "link": "/leaves",
        "icon": "ri-file-text-line",
    },
    {
        "label": "Overtimes",
        "link": "/overtimes",
        "icon": "ri-file-list-line",
    },
];

const workflowMenu = [
    {
        "label": "Submissions",
        "link": "/submissions",
        "icon": "ri-draft-line",
    },
    {
        "label": "Approvals",
        "link": "/approvals",
        "icon": "ri-edit-2-line",
    },
];

const payrollMenu = [
    {
        "label": "Generate",
        "link": "/payrolls/add",
        "icon": "ri-settings-5-line",
    },
    {
        "label": "List",
        "link": "/payrolls",
        "icon": "ri-file-list-3-line",
    },
];

const reportMenu = [
    {
        "label": "Attendances",
        "link": "/reports/attendances",
        "icon": "ri-file-chart-line",
    },
    {
        "label": "Att. by Employee",
        "link": "/reports/employee_attendances",
        "icon": "ri-file-list-2-line",
    },
    {
        "label": "Leaves",
        "link": "/reports/leaves",
        "icon": "ri-file-edit-line",
    },
    {
        "label": "Overtimes",
        "link": "/reports/overtimes",
        "icon": "ri-file-chart-2-line",
    },
    {
        "label": "Employees",
        "link": "/reports/employees",
        "icon": "ri-file-user-line",
    },
];

const complaintMenu = [
    {
        "label": "List",
        "link": "/complaints",
        "icon": "ri-article-line",
    }
];

const nlpMenu = [
    {
        "label": "SearchEngine",
        "link": "/nlps",
        "icon": "ri-file-search-line",
    }
];

export {
    dashboardMenu,
    masterMenu,
    transactionMenu,
    payrollMenu,
    workflowMenu,
    reportMenu,
    complaintMenu,
    nlpMenu
};