import { Card, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Grid, _ } from "gridjs-react";
import Rest from "./../../../libraries/Rest";
import Alert from "../../../libraries/Alert";

function CompanyList() {
    const rest = new Rest();
    const navigate = useNavigate();
    const endpoint = `/companies`;
    const rootPath = `/companies`;

    const updateItem = async (id) => {
        navigate(`${rootPath}/${id}`);
    }

    const removeItem = async (id) => {
        Alert.showConfirm({
            url: `${endpoint}/${id}`,
            method: 'DELETE',
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    const goToPage = (id, type) => {
        const mapping = {
            departments: '/companies/departments',
            positions: '/companies/positions',
            salary_components: '/companies/salary_components',
            employee_types: '/companies/employee_types',
            shifts: '/companies/shifts',
            events: '/companies/events',
            banks: '/companies/banks',
            branches: '/companies/branches',
            settings: '/companies/settings',
        }
        if (mapping[type] !== undefined) navigate(`${mapping[type]}/${id}`);
    }

    return (
        <div>
            <Card className="bg-white">
                <Card.Header className="bg-primary">
                    <Card.Title as="label" className="fs-sm fw-medium mb-1 text-white">Company List</Card.Title>
                </Card.Header>
                <Card.Body className="bg-white">
                    <Link to={`${rootPath}/add`} className="btn btn-primary btn-sm">
                        <i className="ri-add-line"></i> Create Data
                    </Link>
                    <br /><br />
                    <Grid
                        className={{ table: 'table table-bordered mb-0' }}
                        resizable={true}
                        language={{
                            search: {
                                placeholder: 'Find something..'
                            }
                        }}
                        columns={[
                            'Name',
                            'Email',
                            'Phone',
                            'Address',
                            'Action',
                        ]}
                        server={{
                            url: `${rest.MAIN_HOST}${endpoint}`,
                            headers: {
                                'authorization': `Bearer ${rest.ACCESS_TOKEN}`
                            },
                            then: data => data?.data?.data?.map(r => [
                                r.name,
                                r.email,
                                r.phone,
                                r.address,
                                _(
                                    <div style={{ textAlign: 'center' }}>
                                        <button type="button" onClick={() => updateItem(r.id)} className="btn btn-info text-white btn-sm" title="Update data"><i className="ri-pencil-line"></i></button>
                                        &nbsp;
                                        <Dropdown className="btn-group">
                                            <Dropdown.Toggle variant="success" className="btn-sm text-white">
                                                <i className="ri-menu-5-line"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => goToPage(r.id, 'branches')}>Branches</Dropdown.Item>
                                                <Dropdown.Item onClick={() => goToPage(r.id, 'departments')}>Departments</Dropdown.Item>
                                                <Dropdown.Item onClick={() => goToPage(r.id, 'positions')}>Positions</Dropdown.Item>
                                                <Dropdown.Item onClick={() => goToPage(r.id, 'salary_components')}>Salary Components</Dropdown.Item>
                                                <Dropdown.Item onClick={() => goToPage(r.id, 'employee_types')}>Employee Types</Dropdown.Item>
                                                <Dropdown.Item onClick={() => goToPage(r.id, 'shifts')}>Shifts</Dropdown.Item>
                                                <Dropdown.Item onClick={() => goToPage(r.id, 'events')}>Events</Dropdown.Item>
                                                <Dropdown.Item onClick={() => goToPage(r.id, 'banks')}>Banks</Dropdown.Item>
                                                <Dropdown.Item onClick={() => goToPage(r.id, 'settings')}>Settings</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        &nbsp;
                                        <button type="button" onClick={() => removeItem(r.id)} className="btn btn-danger btn-sm" title="Remove data"><i className="ri-delete-bin-line"></i></button>
                                    </div>
                                )
                            ]),
                            total: data => data?.data?.total?.data,
                            handle: (res) => {
                                if (res.status === 401) return rest.interceptor();
                                return res.json();
                            }
                        }}
                        search={{
                            server: {
                                url: (prev, keyword) => `${prev}?keywords=${keyword}`
                            }
                        }}
                        pagination={{
                            limit: 10,
                            server: {
                                url: (prev, page, limit) => {
                                    const operator = prev.includes('?') ? '&' : '?';
                                    if (parseInt(page) <= 0) page = 1;
                                    return `${prev}${operator}limit=${limit}&page=${page}`
                                }
                            }
                        }}
                    />
                </Card.Body>
            </Card>
        </div>
    )

}

export default CompanyList;