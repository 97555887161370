import { Card, Row, Col, Modal, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Grid, _ } from "gridjs-react";
import Rest from "./../../../../libraries/Rest";
import Alert from "../../../../libraries/Alert";
import { useEffect, useState } from "react";
import * as Components from "./../../../../components/Components";
import LocalStorage from "../../../../libraries/LocalStorage";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function EmployeeShiftList() {
    const rest = new Rest();
    const navigate = useNavigate();
    const storage = new LocalStorage();
    const roleName = storage.getItem('role_name')?.toLowerCase();
    const isSuperAdmin = roleName === 'super_admin' ? true : false;

    const endpoint = `/employee_shifts`;
    const rootPath = `/employee_shifts`;

    /**
     * State for open modal
     */
    const [show, setShow] = useState(false);
    const closeImportModal = () => setShow(false);
    const showImportModal = () => setShow(true);

    /**
     * State for input data
     */
    const [shiftCodes, setShiftCodes] = useState([]);
    const [companyInModal, setCompanyInModal] = useState();
    const [isOvertimeInModal, setIsOvertimeInModal] = useState('false');
    const [companies, setCompanies] = useState([]);
    const [payloads, setPayloads] = useState({
        companyId: null,
        isOvertime: null
    });
    const [dataImported, setDataImported] = useState([]);

    const updateItem = async (id, employee_id) => {
        navigate(`${rootPath}/${id}/${employee_id}`);
    }

    const getCompanies = async () => {
        let res = await rest.get(`/companies?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;
            const companyName = storage.getItem('company_name');

            let options = [];
            for (let i = 0; i < res.length; i++) {
                let push = true;

                if (roleName?.toLowerCase() !== 'super_admin') {
                    push = companyName?.toLowerCase() === res[i].name?.toLowerCase() ? true : false;
                }

                if (push) {
                    options.push({
                        value: res[i].id,
                        label: res[i].name
                    });

                    /**
                     * Set default company for first row
                     */
                    if (
                        i === 0 && roleName?.toLowerCase() === 'super_admin'
                        || roleName?.toLowerCase() !== 'super_admin'
                    ) {
                        setPayloads({ ...payloads, companyId: res[i].id });
                        setCompanyInModal(res[i].id);
                        if (roleName?.toLowerCase() !== 'super_admin') {
                            await getShifts(res[i].id);
                        }
                    }
                }
            }
            setCompanies(options);
        }
    }

    const getShifts = async (companyId) => {
        let res = await rest.get(`/companies/shifts/${companyId}?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let options = [];
            for (let i = 0; i < res.length; i++) {
                options.push({
                    value: res[i].id,
                    label: `${res[i].code} - ${res[i].name}`
                });
            }
            setShiftCodes(options);
        }
    }

    const updatePayload = async (field, value) => {
        if (field === 'companyInModal') {
            setCompanyInModal(value);
            /**
             * Call company shifts
             */
            await getShifts(value)
        } else if (field === 'isOvertimeInModal') {
            setIsOvertimeInModal(value);
        } else {
            setPayloads({ ...payloads, [field]: value });
        }
    }

    useEffect(() => {
        Alert.showLoading();
        getCompanies();
        Alert.close();
    }, []);

    const removeItem = async (id, employee_id) => {
        Alert.showConfirm({
            url: `/employees/shifts/${employee_id}/${id}`,
            method: 'DELETE',
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    Alert.showMessage('Failure', 'error', result?.value?.message);
                }
            }
        });
    }

    /**
     * Action handlers
     */
    const downloadTemplate = () => {
        /**
         * Defined attributes and datas
         */
        var data = [
            {
                nik: "12345",
                shit_code: "CODE001",
                start_date: "2024-06-01",
                end_date: "2024-06-30",
                remark: "-"
            }
        ];
        let header = ["NIK", "Shift Code", "Start Date", "End Date", "Remark"];

        /**
         * XLSX configurations
         */
        const ws = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [header]);
        XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        /**
         * Generate file
         */
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });
        const finalData = new Blob([excelBuffer], { type: 'application/octet-stream' });

        /**
         * Download file automatically
         */
        saveAs(finalData, "Import-shift-example.xlsx");
    }

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(sheet);

            /**
             * Formatting payload
             */
            let payloads = [];
            for (let i = 0; i < sheetData.length; i++) {
                const value = sheetData[i];

                let obj = {}
                for (let j = 0; j < Object.keys(value).length; j++) {
                    const key = Object.keys(value)[j]?.replace(/ /g, '_')?.toLowerCase();
                    obj[key] = value[Object.keys(value)[j]];
                }
                payloads.push(obj);
            }
            setDataImported(payloads);
        };

        reader.readAsArrayBuffer(file);
    };

    const doImport = async () => {
        if (dataImported.length <= 0) return Alert.showMessage('Warning', 'warning', 'You didnt upload any file yet');
        if (companyInModal === '' && isSuperAdmin || companyInModal === undefined && isSuperAdmin) return Alert.showMessage('Warning', 'warning', 'You didnt choose company yet');

        /**
         * Add is_overtime to payload
         */
        for (let i = 0; i < dataImported.length; i++) {
            dataImported[i].is_overtime = isOvertimeInModal === 'true' ? true : false;
        }

        /**
         * Submit action
         */
        Alert.showConfirm({
            url: `/employee_shifts/${companyInModal}`,
            method: 'POST',
            data: dataImported,
        }).then((result) => {
            if (result.isConfirmed) {
                if (result?.value?.success) {
                    Alert.showMessage('Success', 'success', result?.value?.data?.message);
                    navigate(rootPath);
                } else {
                    const errLists = result?.value?.data?.error;
                    let errMessages = '';
                    if (errLists !== undefined && errLists.length > 0) {
                        for (let i = 0; i < errLists.length; i++) {
                            errMessages += `${errLists[i]?.message} in NIK: ${errLists[i]?.nik}<br />`;
                        }
                    }
                    Alert.showMessage('Failure', 'error', errMessages);
                }
            }
        });
    }

    return (
        <div>
            <Card className="bg-white">
                <Card.Header className="bg-primary">
                    <Card.Title as="label" className="fs-sm fw-medium mb-1 text-white">Shift Calendar</Card.Title>
                </Card.Header>
                <Card.Body className="bg-white">
                    <Link to={`${rootPath}/add`} className="btn btn-primary btn-sm">
                        <i className="ri-add-line"></i> Create Data
                    </Link>
                    &nbsp;
                    <Button className="btn btn-success btn-sm" onClick={showImportModal}>
                        <i className="ri-upload-line"></i> Import Data
                    </Button>
                    <Modal show={show} onHide={closeImportModal} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Import Data</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <legend>
                                Code informations
                            </legend>
                            <Row>
                                {roleName?.toLowerCase() === 'super_admin' && <Col sm="6">
                                    <Components.Select
                                        label="Company"
                                        name="companyInModal"
                                        defaultValue={companyInModal}
                                        change={updatePayload}
                                        options={companies}
                                    />
                                </Col>}
                                <Col sm="6">
                                    <Components.Select
                                        label="Shift code by company (you dont need to choose)"
                                        name="shift_code"
                                        options={shiftCodes}
                                        noInitOption={true}
                                    />
                                </Col>
                                <Col sm="3">
                                    <Components.Select
                                        label="Type"
                                        name="isOvertimeInModal"
                                        change={updatePayload}
                                        defaultValue={isOvertimeInModal}
                                        options={[
                                            {
                                                label: 'Regular',
                                                value: 'false'
                                            }, {
                                                label: 'Overtime',
                                                value: 'true'
                                            }
                                        ]}
                                        noInitOption={true}
                                    />
                                </Col>
                            </Row>
                            <hr />
                            <h4>Upload your import file in here (.xls, .xlsx):</h4>
                            <Row>
                                <Col sm="4">
                                    <input type="file" name="file" className="form-control" onChange={handleFileUpload} />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={downloadTemplate}>
                                Download Template
                            </Button>
                            <Button variant="primary" onClick={doImport}>
                                Upload Data
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <br /><br />
                    <Row>
                        {roleName?.toLowerCase() === 'super_admin' && <Col sm="4">
                            <Components.Select
                                label="Companies"
                                name="companyId"
                                defaultValue={payloads.companyId}
                                change={updatePayload}
                                options={companies}
                                noInitOption={true}
                            />
                        </Col>}
                        <Col sm="3">
                            <Components.Select
                                label="Type"
                                name="isOvertime"
                                defaultValue={payloads.isOvertime}
                                change={updatePayload}
                                options={[
                                    {
                                        label: 'Regular',
                                        value: 'false'
                                    }, {
                                        label: 'Overtime',
                                        value: 'true'
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                    <Grid
                        className={{ table: 'table table-bordered mb-0' }}
                        resizable={true}
                        language={{
                            search: {
                                placeholder: 'Find something..'
                            }
                        }}
                        columns={[
                            'ID Employee',
                            'Name',
                            'Start-End',
                            'Shift',
                            'Type',
                            'Action',
                        ]}
                        server={{
                            url: `${rest.MAIN_HOST}${endpoint}/${payloads.companyId}${payloads.isOvertime !== null && payloads.isOvertime !== '' ? '?isOvertime=' + payloads.isOvertime : ''}`,
                            headers: {
                                'authorization': `Bearer ${rest.ACCESS_TOKEN}`
                            },
                            then: data => data?.data?.data?.map(r => [
                                r?.employees?.nik,
                                `${r?.employees?.first_name} ${r?.employees?.middle_name ?? ''} ${r?.employees?.last_name ?? ''}`,
                                `${r.start_date} - ${r.end_date}`,
                                `${r?.company_shifts?.name} (${r?.company_shifts?.time_in} - ${r?.company_shifts?.time_out})`,
                                r?.is_overtime === true ? 'Overtime' : 'Regular',
                                _(
                                    <div style={{ textAlign: 'center' }}>
                                        <button type="button" onClick={() => updateItem(r.id, r?.employees?.id)} className="btn btn-info text-white btn-sm" title="Update data"><i className="ri-pencil-line"></i></button>
                                        &nbsp;
                                        <button type="button" onClick={() => removeItem(r.id, r?.employees?.id)} className="btn btn-danger btn-sm" title="Remove data"><i className="ri-delete-bin-line"></i></button>
                                    </div>
                                )
                            ]),
                            total: data => data?.data?.total?.data,
                            handle: (res) => {
                                if (res.status === 401) return rest.interceptor();
                                return res.json();
                            }
                        }}
                        search={{
                            server: {
                                url: (prev, keyword) => `${prev}?keywords=${keyword}`
                            }
                        }}
                        pagination={{
                            limit: 10,
                            server: {
                                url: (prev, page, limit) => {
                                    const operator = prev.includes('?') ? '&' : '?';
                                    if (parseInt(page) <= 0) page = 1;
                                    return `${prev}${operator}limit=${limit}&page=${page}`
                                }
                            }
                        }}
                    />
                </Card.Body>
            </Card>
        </div >
    )

}

export default EmployeeShiftList;