import { Card, Row, Col } from "react-bootstrap";
import { Grid, _ } from "gridjs-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Rest from "./../../libraries/Rest";
import LocalStorage from "./../../libraries/LocalStorage";

import * as Components from "./../../components/Components";

function ComplaintList() {
    const rest = new Rest();
    const navigate = useNavigate();

    const localStorage = new LocalStorage();
    const roleName = localStorage.getItem('role_name');
    const allowedRole = 'super_admin'; // static role validation (code: VL01)
    var endpoint = `/complaints?default=true`;

    const [companies, setCompanies] = useState([]);
    const [payload, setPayload] = useState({
        companyId: null,
        startDate: null,
        endDate: null,
        status: null
    });
    const [parameters, setParameters] = useState('');

    /**
     * First init
     */
    const getCompanies = async () => {
        let res = await rest.get(`/companies?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let options = [];
            for (let i = 0; i < res.length; i++) {
                options.push({
                    value: res[i]['id'] !== undefined ? res[i]['id'] : null,
                    label: res[i]['name'] !== undefined ? res[i]['name'] : null,
                });
            }
            setCompanies(options);
        }
    }

    const updatePayload = async (field, value) => {
        setPayload({ ...payload, [field]: value });

        /**
         * Set parameters
         */
        let _parameters = parameters;
        if (_parameters.includes(field)) {
            const _parr = _parameters.split('&');
            _parameters = ''; // reset;
            const _skip = value && value !== '' ? false : true;

            for (let i = 0; i < _parr.length; i++) {
                let _varr = _parr[i].split('=');
                if (_varr.length >= 1 && _varr[0] === field) {
                    if (!_skip) {
                        _varr[1] = value;
                    } else {
                        continue; // remove this field
                    }
                }

                /**
                 * Join the parameters
                 */
                if (
                    _varr[0] && _varr[0] !== undefined
                    &&
                    _varr[1] && _varr[1] !== undefined
                ) _parameters += `&${_varr[0]}=${_varr[1]}`;
            }
        } else {
            _parameters += `&${field}=${value}`;
        }
        setParameters(_parameters);
    }

    useEffect(() => {
        if (roleName?.toLowerCase() === allowedRole) getCompanies(); // static role validation (code: VL01)
    }, []);

    const openDetail = (id) => {
        navigate(`/complaints/${id}`);
    }

    return (
        <div>
            <Card className="bg-white">
                <Card.Header className="bg-primary">
                    <Card.Title as="label" className="fs-sm fw-medium mb-1 text-white">
                        List complaints
                    </Card.Title>
                </Card.Header>
                <Card.Body className="bg-white">
                    <Row className="mt-1">
                        {roleName?.toLowerCase() === allowedRole &&
                            <Col sm="3">
                                <Components.Select
                                    label="Company"
                                    name="companyId"
                                    defaultValue={payload.companyId}
                                    change={updatePayload}
                                    options={companies}
                                />
                            </Col>
                        }
                        <Col sm="2">
                            <Components.Input
                                type="date"
                                label="Start Date"
                                name="startDate"
                                defaultValue={payload.startDate}
                                change={updatePayload}
                            />
                        </Col>
                        <Col sm="2">
                            <Components.Input
                                type="date"
                                label="End Date"
                                name="endDate"
                                defaultValue={payload.endDate}
                                change={updatePayload}
                            />
                        </Col>
                        <Col sm="2">
                            <Components.Select
                                label="Status"
                                name="status"
                                defaultValue={payload.status}
                                change={updatePayload}
                                options={[
                                    {
                                        label: 'Requested',
                                        value: 'S1'
                                    }, {
                                        label: 'Completed',
                                        value: 'S2'
                                    }, {
                                        label: 'Rejected',
                                        value: 'S3'
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                    <Grid
                        className={{ table: 'table table-bordered mb-0' }}
                        resizable={true}
                        language={{
                            search: {
                                placeholder: 'Find something..'
                            }
                        }}
                        columns={[
                            'Employee (ID)',
                            'Company',
                            'Title',
                            'Status',
                            'Created',
                            'Detail',
                        ]}
                        server={{
                            url: `${rest.MAIN_HOST}${endpoint}${parameters}`,
                            headers: {
                                'authorization': `Bearer ${rest.ACCESS_TOKEN}`
                            },
                            then: data => data?.data?.data?.map(r => [
                                `${r?.employees?.first_name} ${r?.employees?.middle_name ?? ''} ${r?.employees?.last_name ?? ''} ${r?.employees?.nik ? '(' + r.employees.nik + ')' : ''}`,
                                `${r?.companies?.name}`,
                                r?.title,
                                r?.status_text,
                                r?.created_at,
                                _(
                                    <div style={{ textAlign: 'center' }}>
                                        <button type="button" className="btn btn-success btn-sm" onClick={() => openDetail(r?.id)}>
                                            <i className="ri-eye-line"></i>
                                        </button>
                                    </div>
                                )
                            ]),
                            total: data => data?.data?.total?.data,
                            handle: (res) => {
                                if (res.status === 401) return rest.interceptor();
                                return res.json();
                            }
                        }}
                        search={{
                            server: {
                                url: (prev, keyword) => `${prev}?keywords=${keyword}`
                            }
                        }}
                        pagination={{
                            limit: 10,
                            server: {
                                url: (prev, page, limit) => {
                                    const operator = prev.includes('?') ? '&' : '?';
                                    if (parseInt(page) <= 0) page = 1;
                                    return `${prev}${operator}limit=${limit}&page=${page}`
                                }
                            }
                        }}
                    />
                </Card.Body>
            </Card>
        </div>
    )

}

export default ComplaintList;