import { Card, Row, Col, Modal, Button } from "react-bootstrap";
import { Grid, _ } from "gridjs-react";
import { useEffect, useState } from "react";
import Rest from "./../../libraries/Rest";
import Alert from "./../../libraries/Alert";
import LocalStorage from "./../../libraries/LocalStorage";

import * as Components from "./../../components/Components";

function ReportAttendances() {
    const rest = new Rest();
    const localStorage = new LocalStorage();
    const roleName = localStorage.getItem('role_name');
    const companyName = localStorage.getItem('company_name');
    const allowedRole = 'super_admin'; // static role validation (code: VL01)
    var endpoint = `/reports/attendances?withAttachments=true`;

    const [companies, setCompanies] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [employeeSelected, setEmployeeSelected] = useState([]);
    const [payload, setPayload] = useState({
        companyId: null,
        departmentId: null,
        startDate: null,
        endDate: null,
        status: null
    });
    const [parameters, setParameters] = useState('');
    const [employeeParameters, setEmployeeParameters] = useState('');

    /**
     * State for open modal
     */
    const [attachments, setAttachments] = useState([]);
    const [showAttachment, setShowAttachment] = useState(false);
    const openAttachment = (type) => setShowAttachment(type);

    const [showEmployee, setShowEmployee] = useState(false);
    const openEmployee = (type) => setShowEmployee(type);

    /**
     * First init
     */
    const getCompanies = async () => {
        let res = await rest.get(`/companies?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let options = [];
            let companyDefault = null;
            for (let i = 0; i < res.length; i++) {
                options.push({
                    value: res[i]['id'] !== undefined ? res[i]['id'] : null,
                    label: res[i]['name'] !== undefined ? res[i]['name'] : null,
                });

                /**
                 * Get company default ONLY if user logged in is not super_admin
                 */
                if (
                    roleName?.toLowerCase() !== allowedRole
                    && res[i]['name']?.toLowerCase() === companyName?.toLowerCase()
                ) {
                    companyDefault = res[i]['id'];
                }
            }
            setCompanies(options);

            /**
             * Call departments
             */
            if (companyDefault) await getDepartments(companyDefault);
        }
    }

    const getDepartments = async (_companyId) => {
        let res = await rest.get(`/companies/departments/${_companyId}?page=1&limit=100`);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let options = [];
            for (let i = 0; i < res.length; i++) {
                options.push({
                    value: res[i]['id'] !== undefined ? res[i]['id'] : null,
                    label: res[i]['name'] !== undefined ? res[i]['name'] : null,
                });
            }
            setDepartments(options);
        }
    }

    const updatePayload = async (field, value) => {
        setPayload({ ...payload, [field]: value });

        /**
         * Call department when companyId changed
         */
        if (field === 'companyId') {
            setEmployees([]);
            setEmployeeSelected([]);
            await getDepartments(value);
        }

        /**
         * Set parameters
         */
        let _parameters = parameters;
        if (_parameters.includes(field)) {
            const _parr = _parameters.split('&');
            _parameters = ''; // reset;
            const _skip = value && value !== '' ? false : true;

            for (let i = 0; i < _parr.length; i++) {
                let _varr = _parr[i].split('=');
                if (_varr.length >= 1 && _varr[0] === field) {
                    if (!_skip) {
                        _varr[1] = value;
                    } else {
                        continue; // remove this field
                    }
                }

                /**
                 * Join the parameters
                 */
                if (
                    _varr[0] && _varr[0] !== undefined
                    &&
                    _varr[1] && _varr[1] !== undefined
                ) _parameters += `&${_varr[0]}=${_varr[1]}`;
            }
        } else {
            _parameters += `&${field}=${value}`;
        }
        setParameters(_parameters);
    }

    useEffect(() => {
        getCompanies();
    }, []);

    const openAttachments = (_attachments = []) => {
        setAttachments(_attachments);
        openAttachment(true);
    }

    const searchEmployee = async () => {
        /**
         * Get employees by company and department (if exists)
         */
        Alert.showLoading();
        let url = `/employees?page=1&limit=10000`;
        if (payload.companyId) url += `&companyId=${payload.companyId}`;
        let res = await rest.get(url);
        if (res?.data?.data?.data !== undefined) {
            res = res.data.data.data;

            let emp = [];
            for (let i = 0; i < res.length; i++) {
                let checked = false;
                const x = employeeSelected.find(x => x === res[i]?.id);
                if (x !== undefined) checked = true;

                const e = {
                    id: res[i]?.id,
                    nik: res[i]?.nik,
                    name: `${res[i]?.first_name} ${res[i]?.middle_name ?? ''} ${res[i]?.last_name ?? ''}`,
                    checked
                }
                emp.push(e);
            }
            setEmployees(emp);
            openEmployee(true);
        }
        Alert.close();
    }

    const handleCheckbox = (e) => {
        /**
         * Append or remove from selected employee
         */
        const x = employeeSelected.findIndex(x => x === e.target.value);
        if (!e.target.checked) {
            employeeSelected.splice(x, 1);
        } else {
            employeeSelected.push(e.target.value);
        }
        setEmployeeSelected(employeeSelected);
    }

    const reRenderTable = () => {
        /**
         * Translate to query params and join into global parameters
         */
        let paramsEmp = '';
        for (let i = 0; i < employeeSelected.length; i++) {
            paramsEmp += `&employeeIds=${employeeSelected[i]}`;
        }
        setEmployeeParameters(paramsEmp);
        openEmployee(false);
    }

    return (
        <div>
            <Card className="bg-white">
                <Card.Header className="bg-primary">
                    <Card.Title as="label" className="fs-sm fw-medium mb-1 text-white">
                        Report of attendances
                    </Card.Title>
                </Card.Header>
                <Card.Body className="bg-white">
                    <Row className="mt-1">
                        {/* Modal attachments */}
                        <Modal show={showAttachment} onHide={() => openAttachment(false)} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Detail Attachments</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    {attachments.map((v, i) => {
                                        return (
                                            <Col sm="6" key={i}>
                                                <img
                                                    src={v?.file_url}
                                                    className="img-thumbnail"
                                                />
                                                <p style={{ fontWeight: "bold", marginTop: 20 }}>
                                                    {v?.file_name}
                                                </p>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Modal.Body>
                        </Modal>

                        {/* Modal employees */}
                        <Modal show={showEmployee} onHide={() => openEmployee(false)} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Select specific employees you want to find</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="alert alert-info">
                                    <strong>Information:</strong> If you do not select an employee, the application will search for all employees.
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Employee ID</th>
                                                <th>Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employees.map((v, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                value={v?.id}
                                                                defaultChecked={v?.checked}
                                                                onChange={handleCheckbox}
                                                            />
                                                        </td>
                                                        <td>{v?.nik}</td>
                                                        <td>{v?.name}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={reRenderTable}>
                                    <i className="ri-filter-line"></i> Submit
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {roleName?.toLowerCase() === allowedRole &&
                            <Col sm="4">
                                <Components.Select
                                    label="Company"
                                    name="companyId"
                                    defaultValue={payload.companyId}
                                    change={updatePayload}
                                    options={companies}
                                />
                            </Col>
                        }
                        <Col sm="4">
                            <Components.Select
                                label="Department"
                                name="departmentId"
                                defaultValue={payload.departmentId}
                                change={updatePayload}
                                options={departments}
                            />
                        </Col>
                        <Col sm="4">
                            <Button className="btn btn-primary" style={{ marginTop: 27 }} onClick={searchEmployee}>
                                <i className="ri-filter-line"></i>
                                Employee selected: {employeeSelected.length <= 0 ? 'all' : `${employeeSelected.length} data(s)`}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mt-1 mb-2">
                        <Col sm="4">
                            <Components.Input
                                type="date"
                                label="Start Date"
                                name="startDate"
                                defaultValue={payload.startDate}
                                change={updatePayload}
                            />
                        </Col>
                        <Col sm="4">
                            <Components.Input
                                type="date"
                                label="End Date"
                                name="endDate"
                                defaultValue={payload.endDate}
                                change={updatePayload}
                            />
                        </Col>
                        <Col sm="4">
                            <Components.Select
                                label="Status"
                                name="status"
                                defaultValue={payload.status}
                                change={updatePayload}
                                options={[
                                    {
                                        label: 'On Time',
                                        value: 'S2'
                                    }, {
                                        label: 'Early',
                                        value: 'S3'
                                    }, {
                                        label: 'Late',
                                        value: 'S4'
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                    <hr />
                    <Grid
                        className={{ table: 'table table-bordered mb-0' }}
                        resizable={true}
                        language={{
                            search: {
                                placeholder: 'Find something..'
                            }
                        }}
                        columns={[
                            'No. Attendance',
                            'Employee (NIK)',
                            'Company (Branch)',
                            'Start Date (Time)',
                            'End Date (Time)',
                            'Status',
                            'Attachments',
                        ]}
                        server={{
                            url: `${rest.MAIN_HOST}${endpoint}${parameters}${employeeParameters}`,
                            headers: {
                                'authorization': `Bearer ${rest.ACCESS_TOKEN}`
                            },
                            then: data => data?.data?.data?.map(r => [
                                r?.no_attendance,
                                `${r?.employees?.first_name} ${r?.employees?.middle_name ?? ''} ${r?.employees?.last_name ?? ''} ${r?.employees?.nik ? '(' + r.employees.nik + ')' : ''}`,
                                `${r?.companies?.name} ${r?.company_branches?.name ? '(' + r.company_branches.name + ')' : ''}`,
                                `${r?.date_in} ${r?.time_in ? r?.time_in : '-none-'}`,
                                `${r?.date_out ?? ''} ${r?.time_out ? r?.time_out : '-none-'}`,
                                `${r?.status_text} ${r?.status?.toUpperCase() !== 'S2' ? '(' + r?.time_diff + 'Mins)' : ''}`,
                                _(
                                    <div style={{ textAlign: 'center' }}>
                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => openAttachments(r?.attachments)}>
                                            <i className="ri-attachment-line"></i>
                                        </button>
                                    </div>
                                )
                            ]),
                            total: data => data?.data?.total?.data,
                            handle: (res) => {
                                if (res.status === 401) return rest.interceptor();
                                return res.json();
                            }
                        }}
                        search={{
                            server: {
                                url: (prev, keyword) => `${prev}?keywords=${keyword}`
                            }
                        }}
                        pagination={{
                            limit: 10,
                            server: {
                                url: (prev, page, limit) => {
                                    const operator = prev.includes('?') ? '&' : '?';
                                    if (parseInt(page) <= 0) page = 1;
                                    return `${prev}${operator}limit=${limit}&page=${page}`
                                }
                            }
                        }}
                    />
                </Card.Body>
            </Card>
        </div>
    )

}

export default ReportAttendances;