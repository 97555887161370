import { Card, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Alert from "../../libraries/Alert";
import Rest from "../../libraries/Rest";
import * as Components from "../../components/Components";

function AttendanceDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const rest = new Rest();

    const rootPath = `/attendances`;
    const endpoint = `/attendances/detail`;

    const [payload, setPayload] = useState({});
    const [attachmentIn, setAttachmentIn] = useState({});
    const [attachmentOut, setAttachmentOut] = useState({});

    /**
     * Init first data
     */
    const getDataById = async () => {
        const response = await rest.get(`${endpoint}/${id}`);
        if (response?.data?.data !== undefined) {
            const data = response.data.data;

            /**
             * Send data to state so the DOM can access those
             */
            if (data?.attachments !== undefined) {
                for (let i = 0; i < data.attachments.length; i++) {
                    if (data.attachments[i]?.type?.toUpperCase() === 'IN') {
                        setAttachmentIn(data.attachments[i]);
                    } else if (data.attachments[i]?.type?.toUpperCase() === 'OUT') {
                        setAttachmentOut(data.attachments[i]);
                    }
                }
            }

            setPayload(data);
        } else {
            Alert.showMessage('Not found', 'error', 'Data is not found');
            navigate(rootPath);
        }
    }

    useEffect(() => {
        getDataById();
    }, []);

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <ol className="breadcrumb fs-sm mb-1">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={rootPath}>List attendances</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Detail attendance
                        </li>
                    </ol>
                </div>
            </div>

            <Row className="g-3">
                <Col xl="12">
                    <Card className="card">
                        <Card.Header className="bg-primary">
                            <Card.Title
                                as="label"
                                className="fs-sm fw-medium mb-1 text-white"
                            >
                                Detail attendance
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className="bg-white">
                            <Link to={rootPath} className="btn btn-sm btn-warning text-white">
                                <i className="ri-arrow-left-line"></i> Back
                            </Link>
                            <br /><br />

                            <Row>
                                <Col sm="6">
                                    <legend>Detail Presence</legend>
                                    <hr />
                                    <table className="table table-condensed">
                                        <tbody>
                                            <tr>
                                                <td>No. Attendance</td>
                                                <td>: {payload?.no_attendance}</td>
                                            </tr>
                                            <tr>
                                                <td>Date-Time (IN)</td>
                                                <td>: {payload?.date_in} {payload?.time_in ? payload?.time_in : '--none--'}</td>
                                            </tr>
                                            <tr>
                                                <td>Date-Time (OUT)</td>
                                                <td>: {payload?.date_out} {payload?.time_out ? payload?.time_out : '--none--'}</td>
                                            </tr>
                                            <tr>
                                                <td>Remark</td>
                                                <td>: {payload?.remark}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} align="center" style={{ fontWeight: 'bold' }}>
                                                    User presence location
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Branch name</td>
                                                <td>: {payload?.company_branches?.name}</td>
                                            </tr>
                                            <tr>
                                                <td>Branch address</td>
                                                <td>: {payload?.company_branches?.address}</td>
                                            </tr>
                                            <tr>
                                                <td>Branch coordinate</td>
                                                <td>
                                                    long: {payload?.company_branches?.long ? payload?.company_branches?.long : '-'}<br />
                                                    lat: {payload?.company_branches?.lat ? payload?.company_branches?.lat : '-'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} align="center" style={{ fontWeight: 'bold' }}>
                                                    User presence coordinate
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Presence coordinate (IN)</td>
                                                <td>
                                                    long: {payload?.details?.in?.long ? payload?.details?.in?.long : '-'}<br />
                                                    lat: {payload?.details?.in?.lat ? payload?.details?.in?.lat : '-'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Presence coordinate (OUT)</td>
                                                <td>
                                                    long: {payload?.details?.out?.long ? payload?.details?.out?.long : '-'}<br />
                                                    lat: {payload?.details?.out?.lat ? payload?.details?.out?.lat : '-'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                                <Col sm="6">
                                    <legend>Detail Employee</legend>
                                    <hr />
                                    <table className="table table-condensed">
                                        <tbody>
                                            <tr>
                                                <td>ID Employee</td>
                                                <td>: {payload?.employees?.nik}</td>
                                            </tr>
                                            <tr>
                                                <td>First Name</td>
                                                <td>: {payload?.employees?.first_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Middle Name</td>
                                                <td>: {payload?.employees?.middle_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Last Name</td>
                                                <td>: {payload?.employees?.last_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Shift Name</td>
                                                <td>: {payload?.shifts?.company_shifts?.name}</td>
                                            </tr>
                                            <tr>
                                                <td>Shift Time</td>
                                                <td>: {payload?.shifts?.company_shifts?.time_in} - {payload?.shifts?.company_shifts?.time_out}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12">
                                    <legend>Attachments</legend>
                                    <hr />
                                    <Row>
                                        <Col sm="6">
                                            <p style={{ textAlign: 'center' }}>Presence picture (IN)</p>
                                            {Object.keys(attachmentIn).length > 0 && <div style={{ textAlign: "center" }}>
                                                <img
                                                    src={attachmentIn?.file_url}
                                                    alt={attachmentIn?.file_name}
                                                    className="img-thumbnail"
                                                />
                                            </div>}
                                            {Object.keys(attachmentIn).length <= 0 && <p style={{ textAlign: "center", fontWeight: "bold" }}>-- No attachment --</p>}
                                        </Col>
                                        <Col sm="6">
                                            <p style={{ textAlign: 'center' }}>Presence picture (OUT)</p>
                                            {Object.keys(attachmentOut).length > 0 && <div style={{ textAlign: "center" }}>
                                                <img
                                                    src={attachmentOut?.file_url}
                                                    alt={attachmentOut?.file_name}
                                                    className="img-thumbnail"
                                                />
                                            </div>}
                                            {Object.keys(attachmentOut).length <= 0 && <p style={{ textAlign: "center", fontWeight: "bold" }}>-- No attachment --</p>}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row >
        </div >
    )
}

export default AttendanceDetail;